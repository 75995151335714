import MarkdownPreview from 'markdown-to-jsx';
import styled from 'styled-components';

import { Button } from '../Button/Button';

type MarkdownProps = {
  children: string | null | undefined;
};

const MarkdownStyling = styled.div`
  display: inline-block;
  width: 100%;

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.1;
  }

  h3 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  span,
  p {
    margin: 0 0 16px 0;
  }

  h2 {
    padding-bottom: 8px;
    border-bottom: solid 1px ${(p) => p.theme.deprecated.white}11;
  }

  ul {
    list-style-type: disc;
    padding: 0 16px;
  }

  code {
    display: inline-block;
    font-family: inherit;
    border: 0;
    border-radius: ${(p) => p.theme.borderRadius.s};
    background-color: ${(p) => p.theme.deprecated.black}44;
    padding: 4px 6px;
    margin: 2px;
  }

  hr {
    border: solid 1px ${(p) => p.theme.border.secondary}11;
  }
`;

export const Markdown = ({ children }: MarkdownProps) => {
  return (
    <MarkdownStyling>
      <MarkdownPreview
        options={
          {
            overrides: {
              ButtonLink: {
                component: Button,
                props: {
                  as: 'a',
                  target: '_blank',
                  style: {
                    display: 'inline-block',
                  },
                },
              },
              video: {
                props: {
                  autoplay: 'true',
                  loop: 'true',
                  muted: 'true',
                  playsinline: 'true',
                  style: {
                    width: '100%',
                  },
                },
              },
              img: {
                props: {
                  style: {
                    width: '100%',
                  },
                },
              },
            },
          } as any // fix for TS error "Expression produces a union type that is too complex to represent."
        }
      >
        {children ?? ''}
      </MarkdownPreview>
    </MarkdownStyling>
  );
};
