import { OperationContext } from 'urql';

import { graphql } from '../gql';
import { AutoPromptInput } from '../gql/graphql';
import { urqlClient } from '../lib/graphql';

const AutoPrompt = graphql(/* GraphQL */ `
  mutation AutoPrompt($input: AutoPromptInput!) {
    autoPrompt(input: $input) {
      text
    }
  }
`);

export const autoPrompt = async (
  input: AutoPromptInput,
  opts?: Partial<OperationContext>
) => {
  const res = await urqlClient
    .mutation(
      AutoPrompt,
      {
        input: {
          ...input,
        },
      },
      opts
    )
    .toPromise();

  if (res.error || !res.data?.autoPrompt?.text) {
    throw res.error || new Error('Unknown error');
  }

  return res.data.autoPrompt.text;
};
