import { useCallback } from 'react';

import { useLastValue } from './useLastValue';

// Return a function that always calls the last seen value of the callback
// this is useful as a replacement to useCallback
export function useStableCallback<T extends (...args: any[]) => any>(
  callback: (...args: Parameters<T>) => ReturnType<T>
): (...args: Parameters<T>) => ReturnType<T> {
  const lastValue = useLastValue(callback);

  return useCallback(
    (...args: Parameters<T>) => {
      return lastValue.current(...args);
    },
    [lastValue]
  );
}
