import { FieldArgs, UpdateResolver, Cache } from '@urql/exchange-graphcache';

import {
  DeleteFoldersInput,
  UpdateWorkbenchesAndFoldersMutation,
} from '../gql/graphql';
import { folderById } from '../queries/folder';
import { draftFolder } from '../queries/organizationDraftFolder';
import { teamById } from '../queries/teams';

type MutationCacheEffectors = {
  [key: string]: UpdateResolver;
};

// the folder could previously be in multiple places in the cache
// (org currentUserDraftFolder, team rootFolder, folder childFolders)
// we need to remove it from all of them before updating it
// into its new parent.
const purgeFolder = (folderId: string, cache: Cache) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'organization')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: draftFolder,
          variables: {
            id: field.arguments?.id as string,
          },
        },
        (data) => {
          if (!data?.organization?.currentUserDraftFolder) {
            return data;
          }

          data.organization.currentUserDraftFolder.childFolders.nodes =
            data.organization.currentUserDraftFolder.childFolders.nodes.filter(
              (node) => node.id !== folderId
            );

          return data;
        }
      );
    });

  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'team')
    .forEach((field) => {
      const fieldArgs = field.arguments as FieldArgs;

      cache.updateQuery(
        {
          query: teamById,
          variables: {
            id: fieldArgs?.id as string,
          },
        },
        (data) => {
          if (!data?.team?.rootFolder) {
            return data;
          }

          data.team.rootFolder.childFolders.nodes =
            data.team.rootFolder.childFolders.nodes.filter(
              (node) => node.id !== folderId
            );

          return data;
        }
      );
    });

  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'folder')
    .forEach((field) => {
      const fieldArgs = field.arguments as FieldArgs;

      cache.updateQuery(
        {
          query: folderById,
          variables: {
            id: fieldArgs?.id as string,
          },
        },
        (data) => {
          if (!data?.folder) {
            return data;
          }

          data.folder.childFolders.nodes =
            data.folder.childFolders.nodes.filter(
              (node) => node.id !== folderId
            );

          return data;
        }
      );
    });
};

export const folderMutationCacheEffectors: MutationCacheEffectors = {
  deleteFolders(res, args, cache, _info) {
    const folderIds = (args.input as DeleteFoldersInput).ids;
    folderIds.forEach((folderId) => {
      purgeFolder(folderId, cache);
    });
  },
  updateFolders(res: UpdateWorkbenchesAndFoldersMutation, args, cache, _info) {
    const updatedFolders = res.updateFolders?.updatedFolders;
    if (!updatedFolders) {
      return;
    }

    updatedFolders?.forEach((folder) => {
      purgeFolder(folder.id, cache);

      // folder was moved to be the child of another folder
      if (folder.breadcrumbs.nodes.length > 1) {
        cache.updateQuery(
          {
            query: folderById,
            variables: {
              id: folder.breadcrumbs.nodes[folder.breadcrumbs.nodes.length - 1]
                .id,
            },
          },
          (data) => {
            if (!data?.folder) {
              return data;
            }

            if (
              !data.folder.childFolders.nodes.find((f) => f.id === folder.id)
            ) {
              data.folder.childFolders.nodes.push(folder);
            }

            return data;
          }
        );
      } else if (folder.breadcrumbs.nodes[0].rootFolderOfTeamId) {
        cache.updateQuery(
          {
            query: teamById,
            variables: {
              id: folder.breadcrumbs.nodes[0].rootFolderOfTeamId,
            },
          },
          (data) => {
            if (!data?.team?.rootFolder) {
              return data;
            }

            if (
              !data.team.rootFolder.childFolders.nodes.find(
                (f) => f.id === folder.id
              )
            ) {
              data?.team?.rootFolder?.childFolders.nodes.push(folder);
            }

            return data;
          }
        );
      } else if (folder.breadcrumbs.nodes[0].rootFolderOfDraftOrganizationId) {
        cache.updateQuery(
          {
            query: draftFolder,
            variables: {
              id: folder.breadcrumbs.nodes[0].rootFolderOfDraftOrganizationId,
            },
          },
          (data) => {
            if (!data?.organization?.currentUserDraftFolder) {
              return data;
            }

            if (
              !data.organization.currentUserDraftFolder.childFolders.nodes.find(
                (f) => f.id === folder.id
              )
            ) {
              data?.organization?.currentUserDraftFolder?.childFolders.nodes.push(
                folder
              );
            }

            return data;
          }
        );
      }
    });
  },
};
