import { useMutation } from 'urql';

import { graphql } from '../../gql';

const DeleteUserOrganizationMutation = graphql(/* GraphQL */ `
  mutation DeleteUserOrganization($input: DeleteUserOrganizationInput!) {
    deleteUserOrganization(input: $input) {
      organization {
        ...OrganizationMembersData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useDeleteUserOrganization = () =>
  useMutation(DeleteUserOrganizationMutation);
