import { FC, HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import {
  Button,
  ErrorIcon,
  InfoIcon,
  Text,
  WarningIcon,
} from '@vizcom/shared-ui-components';

import { InlineFlex } from '../helpers';

export type BannerType = keyof DefaultTheme['toast'];
export interface BaseCta {
  text?: string;
  action?: () => void;
  url?: string;
  target?: HTMLAttributeAnchorTarget;
  Button?: FC<any>;
}
interface ActionCta extends BaseCta {
  text: string;
  action: () => void;
  url?: undefined;
  target?: undefined;
  Button?: undefined;
}
interface LinkCta extends BaseCta {
  text: string;
  url: string;
  target?: HTMLAttributeAnchorTarget;
  action?: undefined;
  Button?: undefined;
}

export type CTA = ActionCta | LinkCta;

type BannerProps = {
  type: BannerType;
  message: string;
  secondaryMessage?: string;
  cta?: CTA;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  size?: 'S' | 'M';
};

export const Banner = (props: BannerProps) => {
  const { toast } = useTheme();
  const { background, ctaBackground, ctaColor } = toast[props.type];
  const { size = 'M' } = props;
  const icon = props.icon ? (
    props.icon
  ) : props.type === 'danger' ? (
    <ErrorIcon />
  ) : props.type === 'warning' ? (
    <WarningIcon />
  ) : (
    <InfoIcon />
  );

  return (
    <BannerContainer
      $size={size}
      style={{ backgroundColor: background, ...props.style }}
    >
      <InlineFlex style={{ width: 'fit-content' }}>
        {icon}
        <Text type="sh2">
          {props.message}
          {props.secondaryMessage && <Text> {props.secondaryMessage}</Text>}
        </Text>
      </InlineFlex>

      {props.cta?.action && (
        <CtaButton
          size={size}
          onClick={props.cta.action}
          style={{
            backgroundColor: ctaBackground,
            color: ctaColor,
          }}
        >
          {props.cta.text}
        </CtaButton>
      )}

      {props.cta?.url && (
        <Link to={props.cta.url} target={props.cta.target || '_self'}>
          <CtaButton
            size={size}
            style={{
              backgroundColor: ctaBackground,
              color: ctaColor,
            }}
          >
            {props.cta.text}
          </CtaButton>
        </Link>
      )}
    </BannerContainer>
  );
};

const BannerContainer = styled.div<{ $size?: 'S' | 'M' }>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-height: ${({ $size }) => ($size === 'S' ? '35px' : '50px')};
  border-radius: ${({ theme, $size }) =>
    $size === 'S' ? theme.borderRadius.m : theme.borderRadius.l};
  padding: ${({ theme, $size }) =>
    $size === 'S'
      ? `${theme.spacing.s} ${theme.spacing.m}`
      : `calc(${theme.spacing.m} / 2) ${theme.spacing.m}`};
  width: 100%;
  color: ${({ theme }) => theme.text.body};
`;

const CtaButton = styled(Button)`
  :hover {
    opacity: 0.8;
  }
`;
