import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type {
  Active,
  CollisionDetection,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { SortableContainer } from './SortableContainer';
import { DragHandle, SortableItem } from './SortableItem';
import { SortableOverlay } from './SortableOverlay';

export interface BaseItem {
  id: UniqueIdentifier;
  orderKey: string;
  isGroup: boolean;
  collapsed?: boolean;
  parentId?: UniqueIdentifier | null;
}

interface Props<T extends BaseItem> {
  activeLayerIds: UniqueIdentifier[];
  items: T[];
  disableSorting: boolean;
  disableDragging: boolean;
  maxDepth: number;
  onChange(
    newOrder: string[],
    nextItem: T | undefined,
    previousItem: T | undefined,
    newParentId?: UniqueIdentifier | null
  ): void;
  setOrder: Dispatch<SetStateAction<string[]>>;
  renderItem(
    item: T,
    depth: number,
    isLast: boolean,
    nextLayerIsGroup: boolean
  ): ReactNode;
  renderContainer(
    item: T,
    depth: number,
    index: number,
    childCount: number,
    innerContent: ReactNode,
    isOver: boolean
  ): ReactNode;
  setActiveLayer: (id: string) => void;
}

export function SortableList<T extends BaseItem>({
  activeLayerIds,
  items,
  disableSorting,
  disableDragging,
  maxDepth,
  onChange,
  renderItem,
  renderContainer,
  setActiveLayer,
}: Props<T>) {
  const [data, setData] = useState([...items]);
  const [active, setActive] = useState<Active | null>(null);
  const [selected, setSelected] = useState<UniqueIdentifier[]>([]);
  const [overGroup, setOverGroup] = useState<UniqueIdentifier | null>(null);
  const activeItem = useMemo(
    () => data.find((item) => item.id === active?.id),
    [active, data]
  );

  // SortableList maintains its own state of the data array, but we need to
  // update the data array when the items prop changes. This effect ensures
  // that the placeholder is always in the correct position.
  // if the order of the items changes while dragging
  useEffect(() => {
    const placeholderIndex = data.findIndex(
      (item) => item.id === 'placeholder'
    );
    const placeholder = data[placeholderIndex];
    setData(() => {
      if (placeholderIndex !== -1) {
        items.splice(placeholderIndex, 0, placeholder);
      }
      return [...items];
    });
  }, [items]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 8,
      },
    })
  );

  const filterItems = (items: T[]) => {
    return items.filter((item) => {
      if (item.id === active?.id) {
        return true;
      }

      return !selected?.includes(item.id);
    });
  };

  function getItems(parentId?: UniqueIdentifier) {
    return filterItems(data).filter((item) => {
      if (!parentId) {
        return !item.parentId;
      }

      return item.parentId === parentId;
    });
  }

  function getTotalNestedChildrenCount(id: UniqueIdentifier): number {
    const children = getItems(id);

    return (
      children.length +
      children.reduce((acc, child) => {
        return acc + getTotalNestedChildrenCount(child.id);
      }, 0)
    );
  }

  function findParent(id: UniqueIdentifier) {
    const item = data.find((item) => item.id === id);
    return !item ? undefined : item.parentId;
  }

  function isGroup(id: UniqueIdentifier) {
    const item = data.find((item) => item.id === id);

    return !item ? false : item.isGroup;
  }

  function isDescendant(childId: UniqueIdentifier, parentId: UniqueIdentifier) {
    const parent = findParent(childId);

    if (!parent) {
      return false;
    }

    if (parent === parentId) {
      return true;
    }

    return isDescendant(parent, parentId);
  }

  function getDepth(id: UniqueIdentifier) {
    let depth = 0;
    let parentId = findParent(id);

    while (parentId) {
      depth++;
      parentId = findParent(parentId);
    }

    return depth;
  }

  function removePlaceholder() {
    setData((prev) => prev.filter((item) => item.id !== 'placeholder'));
  }

  const customCollisionDetection: CollisionDetection = (args) => {
    // Bail out if keyboard activated
    if (!args.pointerCoordinates) {
      return rectIntersection(args);
    }

    const { x, y } = args.pointerCoordinates;
    const { width } = args.collisionRect;

    // Adjust the collision rectangle based on pointer coordinates
    const updatedArgs = {
      ...args,
      collisionRect: {
        width,
        height: 20,
        bottom: y + 10,
        left: x - width / 2,
        right: x + width / 2,
        top: y - 10,
      },
    };

    const collisions = rectIntersection(updatedArgs);

    if (collisions.length > 0) {
      const childCollisions = [];
      const groupCollisions = [];

      for (const collision of collisions) {
        const droppable = args.droppableContainers.find(
          (container) => container.id === collision.id
        );

        if (
          droppable &&
          droppable.data &&
          droppable.data.current?.['container']
        ) {
          groupCollisions.push(collision);
        } else {
          childCollisions.push(collision);
        }
      }

      // Prioritize child collisions over group collisions
      if (childCollisions.length > 0) {
        return childCollisions;
      } else {
        return groupCollisions;
      }
    }

    return collisions;
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setData((prev) => {
      const activeIndex = data.findIndex((item) => item.id === active.id);
      const activeParentId = findParent(active.id);

      prev.splice(activeIndex, 0, {
        id: 'placeholder',
        orderKey: 'placeholder',
        isGroup: false,
        parentId: activeParentId,
      } as T);

      return prev;
    });

    if (!activeLayerIds.includes(active.id)) {
      setActiveLayer(active.id as string);
      setActive(active);
      setSelected([active.id]);
      return;
    }
    setActive(active);
    setSelected(activeLayerIds);
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const { active, over, activatorEvent } = event;
    setOverGroup(null);

    // not over anything, we're either at the top or bottom of the list
    if (!over) {
      setData((prev) => {
        const placeholderIndex = prev.findIndex(
          (item) => item.id === 'placeholder'
        );

        prev[placeholderIndex] = {
          ...prev[placeholderIndex],
          parentId: null,
        };

        const newIndex =
          active.rect.current.translated!.top < 150 ? 0 : prev.length - 1;

        const newOrder = arrayMove(prev, placeholderIndex, newIndex);

        return newOrder;
      });
      return;
    }

    const clientY =
      activatorEvent instanceof PointerEvent ||
      activatorEvent instanceof MouseEvent
        ? activatorEvent.clientY
        : activatorEvent instanceof TouchEvent
        ? activatorEvent.touches[0].clientY
        : 0;
    const overlayOffset =
      active.rect.current.initial!.bottom -
      active.rect.current.initial!.height / 2 -
      clientY;
    const pos =
      active.rect.current.translated!.top +
      active.rect.current.translated!.height -
      overlayOffset;

    if (over.id === 'placeholder') return;

    const overIsContainer = isGroup(over.id);
    const overItem = data.find((item) => item.id === over.id);
    const topMargin = overIsContainer ? 20 : 0;
    const bottomMargin = overIsContainer ? 0 : 0;

    if (
      // we're hovering over the top half of an item
      // move the placeholder right before the item
      // if the item has a parent, move the placeholder inside the parent
      pos >
      over.rect.top + over.rect.height + topMargin
    ) {
      setData((prev) => {
        if (isDescendant(over.id, active.id)) {
          return prev;
        }

        const placeholderIndex = prev.findIndex(
          (item) => item.id === 'placeholder'
        );
        const overIndex = prev.findIndex((item) => item.id === over.id);
        const newIndex = overItem?.collapsed ? overIndex + 1 : overIndex;

        const depth = getDepth(over.id);
        if (depth > maxDepth) {
          return prev;
        }

        if (placeholderIndex === overIndex + 1) {
          if (overItem?.parentId !== prev[placeholderIndex]?.parentId) {
            const newData = [...prev];

            newData[placeholderIndex] = {
              ...prev[placeholderIndex],
              parentId: overItem?.parentId,
            };

            return newData;
          }

          return prev;
        }

        prev[placeholderIndex] = {
          ...prev[placeholderIndex],
          parentId: overItem?.parentId,
        };

        const nextItems = arrayMove(prev, placeholderIndex, newIndex);

        return nextItems;
      });

      return;
    } else if (
      // we're hovering over the bottom half of an item
      // move the placeholder right after the item
      // if the item has a parent, move the placeholder inside the parent
      pos <
      over.rect.top + over.rect.height + bottomMargin
    ) {
      setData((prev) => {
        if (isDescendant(over.id, active.id)) {
          return prev;
        }

        const placeholderIndex = prev.findIndex(
          (item) => item.id === 'placeholder'
        );
        const overIndex = prev.findIndex((item) => item.id === over.id);
        const newIndex =
          overIndex < placeholderIndex ? Math.max(overIndex, 0) : overIndex - 1;

        const depth = getDepth(over.id);
        if (depth > maxDepth) {
          return prev;
        }

        if (placeholderIndex === overIndex - 1) {
          if (overItem?.parentId !== prev[placeholderIndex]?.parentId) {
            const newData = [...prev];

            newData[placeholderIndex] = {
              ...prev[placeholderIndex],
              parentId: overItem?.parentId,
            };

            return newData;
          }

          return prev;
        }

        prev[placeholderIndex] = {
          ...prev[placeholderIndex],
          parentId: overItem?.parentId,
        };

        const nextItems = arrayMove(prev, placeholderIndex, newIndex);

        return nextItems;
      });
      return;
    }

    // we're hovering over a container
    // move the placeholder to the start of the container
    // if the container is a descendant of the active item, do nothing
    if (overIsContainer) {
      setData((prev) => {
        const placeholderIndex = prev.findIndex(
          (item) => item.id === 'placeholder'
        );
        const overIndex = prev.findIndex((item) => item.id === over.id);

        const depth = getDepth(over.id);
        if (depth === maxDepth) {
          return prev;
        }

        if (isDescendant(over.id, active.id) || over.id === active.id) {
          return prev;
        }

        prev[placeholderIndex] = {
          ...prev[placeholderIndex],
          parentId: over.id,
        };

        const nextItems = arrayMove(prev, placeholderIndex, overIndex + 1);

        return nextItems;
      });

      setOverGroup(over.id);
      return;
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id === over?.id) {
      removePlaceholder();
      setActive(null);
      setSelected([]);

      return;
    }

    const filteredItems = filterItems(data);
    const activeIndex = filteredItems.findIndex(({ id }) => id === active.id);
    const placeHolderIndex = filteredItems.findIndex(
      ({ id }) => id === 'placeholder'
    );

    // if the placeholder is at the top and the active item is the second item
    // skip the reordering
    if (placeHolderIndex === 0 && activeIndex === 1) {
      removePlaceholder();
      setActive(null);
      setSelected([]);

      return;
    }

    const newData = [...filteredItems];
    const placeholder = newData[placeHolderIndex];
    const parentId = placeholder?.parentId;

    // assign the final parentId to the active item
    newData[activeIndex] = {
      ...newData[activeIndex],
      parentId,
    };

    // move the active item to the final position of the placeholder
    const newOrder = arrayMove(newData, activeIndex, placeHolderIndex);
    const newPlaceholderIndex = newOrder.findIndex(
      (item) => item.id === 'placeholder'
    );

    // remove the placeholder
    newOrder.splice(newPlaceholderIndex, 1);
    setData(newOrder);

    const itemsAtDepth = newOrder.filter((item) => item.parentId === parentId);
    const activeIndexAtDepth = itemsAtDepth.findIndex(
      ({ id }) => id === active.id
    );

    const nextItem = itemsAtDepth[activeIndexAtDepth - 1];
    const previousItem = itemsAtDepth[activeIndexAtDepth + 1];

    onChange(
      data.map((i) => i.id) as string[],
      nextItem,
      previousItem,
      parentId
    );

    setActive(null);
    setSelected([]);
  };

  return (
    <DndContext
      sensors={disableSorting ? undefined : sensors}
      onDragStart={disableSorting ? undefined : handleDragStart}
      onDragMove={disableSorting ? undefined : handleDragMove}
      onDragEnd={disableSorting ? undefined : handleDragEnd}
      onDragCancel={() => {
        setActive(null);
        setSelected([]);
        removePlaceholder();
      }}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={customCollisionDetection}
    >
      <SortableContext
        items={filterItems(data)}
        strategy={verticalListSortingStrategy}
      >
        <List>
          {getItems().map((item, i) => {
            if (item.id === 'placeholder') {
              return <PlaceHolder key="placeholder" $depth={0} />;
            }

            if (item.isGroup) {
              return (
                <SortableContainer
                  key={item.id}
                  id={item.id}
                  depth={0}
                  item={item}
                  overGroupId={overGroup}
                  getItems={getItems}
                  getTotalNestedChildrenCount={getTotalNestedChildrenCount}
                  renderContainer={renderContainer}
                  renderItem={renderItem}
                  draggable={!disableDragging}
                />
              );
            }

            return (
              <SortableItem
                key={item.id}
                id={item.id}
                draggable={!disableDragging}
              >
                {renderItem(
                  item,
                  0,
                  i === getItems().length - 1,
                  getItems()[i + 1]?.isGroup
                )}
              </SortableItem>
            );
          })}
        </List>
      </SortableContext>
      {createPortal(
        <SortableOverlay
          selectedCount={selected?.length}
          selectedOffset={(active && selected?.indexOf(active?.id)) ?? 0}
        >
          {activeItem &&
            (activeItem.isGroup
              ? renderContainer(
                  { ...activeItem, collapsed: true },
                  0,
                  0,
                  getTotalNestedChildrenCount(activeItem.id),
                  null,
                  false
                )
              : renderItem(activeItem, 0, true, true))}
        </SortableOverlay>,
        document.getElementById('root')!
      )}
    </DndContext>
  );
}

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  z-index: 1;
`;

export const PlaceHolder = styled.li<{ $depth: number }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 2px;
  width: ${({ $depth }) => `calc(100% - ${$depth * 24 - 6}px)`};
  background-color: ${({ theme }) => theme.deprecated.primary.default};
  margin-left: ${({ $depth }) => $depth * 24 + 6}px;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    position: absolute;
    left: -8px;
    border: 2px solid ${({ theme }) => theme.deprecated.primary.default};
    z-index: 2;
  }
`;

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
