// Determines if the user is using an Apple device, see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
// Note that navigator.platform is marked as deprecated, but its successor navigator.userAgentData.platform is not yet supported by Apple.
export const IS_APPLE_DEVICE = /Mac|iPad/.test(navigator.platform);
export const IS_IPAD = IS_APPLE_DEVICE && 'ontouchend' in document;
export const IS_WINDOWS_DEVICE = /Win/.test(navigator.platform);

// Use these constants to display a single key in the UI
export const CMD_KEY = IS_APPLE_DEVICE ? '⌘' : 'Ctrl';
export const SHIFT_KEY = IS_APPLE_DEVICE ? '⇧' : 'Shift';
export const ALT_KEY = IS_APPLE_DEVICE ? '⌥' : 'Alt';

// Use these constants to display a key combination in the UI
export const CMD_KEY_PREFIX = IS_APPLE_DEVICE ? '⌘' : 'Ctrl+';
export const SHIFT_KEY_PREFIX = IS_APPLE_DEVICE ? '⇧' : 'Shift+';
export const ALT_KEY_PREFIX = IS_APPLE_DEVICE ? '⌥' : 'Alt+';

// name of the Cmd key on a MouseEvent
export const CMD_KEY_NAME = IS_APPLE_DEVICE ? 'metaKey' : 'ctrlKey';

export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edge',
  Opera = 'Opera',
  Unknown = 'Unknown',
}

export interface IBrowserResult {
  browser: Browser;
  version: string | undefined;
}

/*
Gets information about the user's browser and version.
const result = getBrowser();
const isFirefoxVersionCompatible = result.browser === Browser.Firefox && (result.version || "0") >= "119.0.1";
*/
export const getBrowser = (): IBrowserResult => {
  const userAgent = navigator.userAgent.toLowerCase();

  let browser: Browser = Browser.Unknown;
  let version: string | undefined;

  if (userAgent.includes('chrome')) {
    browser = Browser.Chrome;
    version = extractVersion(userAgent, 'chrome/');
  } else if (userAgent.includes('firefox')) {
    browser = Browser.Firefox;
    version = extractVersion(userAgent, 'firefox/');
  } else if (userAgent.includes('safari')) {
    browser = Browser.Safari;
    version = extractVersion(userAgent, 'version/');
  } else if (userAgent.includes('edg')) {
    browser = Browser.Edge;
    version = extractVersion(userAgent, 'edg/');
  } else if (userAgent.includes('opr') || userAgent.includes('opera')) {
    browser = Browser.Opera;
    version = extractVersion(userAgent, 'opr/');
  }

  return { browser, version };
};

const extractVersion = (
  userAgent: string,
  identifier: string
): string | undefined => {
  const startIndex = userAgent.indexOf(identifier);
  if (startIndex !== -1) {
    const endIndex = userAgent.indexOf(' ', startIndex);
    const versionString =
      endIndex !== -1
        ? userAgent.substring(startIndex + identifier.length, endIndex)
        : userAgent.substring(startIndex + identifier.length);
    return versionString;
  }
  return undefined;
};

// Some Android devices have issues with certain file types in their native file picker
// This helper ensures we provide the correct accept string based on the device
export const getDeviceFilePickerAcceptString = (options?: {
  imageOnly?: boolean;
}) => {
  const isAndroid = /Android/i.test(navigator.userAgent);

  if (options?.imageOnly) {
    return isAndroid ? 'image/*,application/pdf' : 'image/*';
  }

  return '*/*';
};
