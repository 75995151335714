import { useMutation } from 'urql';

import { graphql } from '../gql';
import { LoginInput } from '../gql/graphql';

// Make sure this body matches the body of the currentUser query
const Login = graphql(/* GraphQL */ `
  mutation login($input: LoginInput!) {
    login(input: $input) {
      authToken
      user {
        ...CurrentUserData
      }
    }
  }
`);

export const useLogin = () => {
  const [result, login] = useMutation(Login);

  const loginWrapper = async (input: LoginInput) => {
    const res = await login({ input });
    return res;
  };

  return [result, loginWrapper] as const;
};
