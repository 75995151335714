import styled, { DefaultTheme } from 'styled-components';

import { textInputStyle } from '../TextInput/TextInput';

interface InputProps {
  $background?: keyof DefaultTheme['surface'];
  $height?: string;
  $resize?: boolean;
}

export const TextArea = styled.textarea<InputProps>`
  ${textInputStyle}

  height: ${({ $height = 'unset' }) => $height};
  resize: ${({ $resize }) => ($resize ? 'vertical' : 'none')};

  line-height: 16px;
`;
