// Use this function in a switch/case or a if/else if/else statement to make sure the latest `default` or `else`
// is never reached
// this is useful to make sure a TS error will be triggered if changing the source data of the condition

// https://tinytip.co/tips/ts-switch-assert-unreachable/
export function assertUnreachable(_value: never): never {
  throw new Error(
    'Statement should be unreachable but was reached with value: ' + _value
  );
}

export function assertExists<T>(
  val: T | null | undefined,
  message = 'Not defined'
): asserts val is T {
  if (val === null || val === undefined) {
    throw new Error(message);
  }
}

export function isEnumValue<T extends string>(
  value: string,
  enumObject: { [key: string]: T }
): value is T {
  return Object.values(enumObject).includes(value as unknown as T);
}

export function assertIsEnumValue<T extends string>(
  value: string,
  enumObject: { [key: string]: T },
  message: string
): asserts value is T {
  if (!Object.values(enumObject).includes(value as unknown as T)) {
    throw new Error(message);
  }
}

export function assertNotExists<T>(
  val: T | null | undefined,
  message = 'Is defined'
): asserts val is null | undefined {
  if (val !== null && val !== undefined) {
    throw new Error(message);
  }
}

export function assertBoolean(
  val: boolean,
  message = 'Assert failed'
): asserts val is true {
  if (!val) {
    throw new Error(message);
  }
}

// Asserts that the value is a number between 0 and 1
export function assertIsNormalizedNumber(val: number) {
  if (val < 0 || val > 1) {
    throw new Error('Value should be normalized between 0 and 1');
  }
}
