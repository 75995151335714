import { useMutation } from 'urql';

import { graphql } from '../gql';

export const CreateLayerMutation = graphql(/* GraphQL */ `
  mutation CreateLayer($input: CreateLayerInput!) {
    createLayer(input: $input) {
      layer {
        ...LayerData
      }
    }
  }
`);

export const useCreateLayer = () => useMutation(CreateLayerMutation);
