import { graphql } from '../../gql';

export const CancelPaletteTrainingMutation = graphql(/* GraphQL */ `
  mutation CancelPaletteTraining($input: CancelPaletteTrainingInput!) {
    cancelPaletteTraining(input: $input) {
      palette {
        ...WorkbenchElementPaletteData
      }
    }
  }
`);

export const TrainPaletteMutation = graphql(/* GraphQL */ `
  mutation TrainPalette($input: TrainPaletteInput!) {
    trainPalette(input: $input) {
      palette {
        ...WorkbenchElementPaletteData
      }
    }
  }
`);

export const UpdateWorkbenchElementPaletteMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementPalette(
    $input: UpdateWorkbenchElementPaletteInput!
  ) {
    updateWorkbenchElementPalette(input: $input) {
      workbenchElementPalette {
        ...WorkbenchElementPaletteData
      }
    }
  }
`);

export const DeleteWorkbenchElementPaletteMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementPalette(
    $input: DeleteWorkbenchElementPaletteInput!
  ) {
    deleteWorkbenchElementPalette(input: $input) {
      deletedWorkbenchElementPaletteNodeId
    }
  }
`);

export const InsertImagesToPaletteMutation = graphql(/* GraphQL */ `
  mutation InsertImagesToPalette($input: InsertImagesToPaletteInput!) {
    insertImagesToPalette(input: $input) {
      palette {
        ...WorkbenchElementPaletteWithSourceImagesData
      }
    }
  }
`);

export const RemoveImagesFromPaletteMutation = graphql(/* GraphQL */ `
  mutation RemoveImagesFromPalette($input: RemoveImagesFromPaletteInput!) {
    removeImagesFromPalette(input: $input) {
      status
    }
  }
`);

export const PublishWorkbenchElementPaletteMutation = graphql(/* GraphQL */ `
  mutation PublishWorkbenchElementPalette(
    $input: PublishWorkbenchElementPaletteInput!
  ) {
    publishWorkbenchElementPalette(input: $input) {
      organization {
        id
        ...OrganizationCurrentUserPalettes
      }
    }
  }
`);

export const UpdateUserPaletteMutation = graphql(/* GraphQL */ `
  mutation UpdateUserPalette($input: UpdateUserPaletteInput!) {
    updateUserPalette(input: $input) {
      organization {
        id
        ...OrganizationCurrentUserPalettes
      }
    }
  }
`);

export const DeleteUserPaletteMutation = graphql(/* GraphQL */ `
  mutation DeleteUserPalette($input: DeleteUserPaletteInput!) {
    deleteUserPalette(input: $input) {
      organization {
        id
        currentUserPalettes {
          nodes {
            ...UserPaletteData
          }
        }
      }
    }
  }
`);
