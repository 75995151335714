import { BillingInterval, OrganizationSubscriptionPlan } from './plans-types';

export const PLAN_PRICES_PER_EDITOR_PER_MONTH_MONTHLY: Record<
  OrganizationSubscriptionPlan,
  number
> = {
  FREE: 0,
  PRO: 49,
  ORGANIZATION: 250,
  ENTERPRISE: 0,
  EDU: 0,
};

export const PLAN_PRICES_PER_EDITOR_PER_MONTH_ANNUALLY: Record<string, number> =
  {
    FREE: 0,
    PRO: 40,
  };

export const getMonthlyPricePerEditor = (
  plan: OrganizationSubscriptionPlan,
  interval?: BillingInterval
) => {
  return interval === BillingInterval.Year
    ? PLAN_PRICES_PER_EDITOR_PER_MONTH_ANNUALLY[plan]
    : PLAN_PRICES_PER_EDITOR_PER_MONTH_MONTHLY[plan];
};

export const isPlanValid = (
  plan: string | null
): plan is OrganizationSubscriptionPlan =>
  Object.values(OrganizationSubscriptionPlan).includes(
    plan as OrganizationSubscriptionPlan
  );

export const isBillingIntervalValid = (
  interval: string | null
): interval is BillingInterval =>
  Object.values(BillingInterval).includes(interval as BillingInterval);

export const billingIntervalWording: Record<BillingInterval, string> = {
  [BillingInterval.Month]: 'monthly',
  [BillingInterval.Year]: 'annually',
};
