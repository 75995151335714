import { v4 as uuidv4 } from 'uuid';

import { graphql } from '../gql';
import { urqlClient } from '../lib/graphql';
import { waitForEphemeralJobResult } from '../subscriptions';
import { ephemeralJobMailboxId } from '../subscriptions/ephemeralJobs/ephemeralJobMailboxId';

export const SegmentImageMutation = graphql(/* GraphQL */ `
  mutation SegmentImage($input: SegmentImageInput!) {
    segmentImage(input: $input) {
      jobId
    }
  }
`);

export const segmentImage = async (image: Blob) => {
  const jobId = uuidv4();
  const res = await urqlClient.mutation(SegmentImageMutation, {
    input: { image, jobId, mailboxId: ephemeralJobMailboxId },
  });
  const jobOutput = await waitForEphemeralJobResult<'imageSegmentation'>(
    jobId,
    20000
  );
  return jobOutput;
};
