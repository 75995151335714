import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const SubscriptionErrorsTypeZodEnum = z.enum([
  'PLAN_SEATS_LIMITATION_ERROR',
  'PAID_SEATS_LIMITATION_ERROR',
  'MANUALLY_MANAGED_LIMITATION_ERROR',
]);

export const SubscriptionErrorsEventName = SubscriptionErrorsTypeZodEnum.Enum;

export const SubscriptionErrorsEventSchema = BaseTrackingEventSchema.extend({
  type: SubscriptionErrorsTypeZodEnum,
  data: z.object({
    organizationId: z.string(),
  }),
});
