import React, {
  createContext,
  Children,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import styled from 'styled-components';

import { Modal } from '../Modal/Modal';

const MultiStepsModalContent = styled.div`
  color: ${(p) => p.theme.text.body};
`;

interface MultiStepsModalContextValue {
  currentStepIndex: number;
  stepsCount: number;
  changeStep: (index: number) => void;
}

const MultiStepsModalContext =
  createContext<MultiStepsModalContextValue | null>(null);

export const MultiStepsModal = (props: {
  isOpen: boolean;
  children: ReactNode;
  setIsOpen: (value: boolean) => void;
  currentStepIndex: number;
  setCurrentStepIndex: (index: number) => void;
  style?: React.CSSProperties;
}) => {
  const { currentStepIndex, setCurrentStepIndex } = props;

  const steps = useMemo(
    () => Children.toArray(props.children),
    [props.children]
  );
  const stepsCount = steps.length;
  const currentStep = steps[currentStepIndex];

  const changeStep = (index: number) => {
    if (index >= 0 && index <= stepsCount) {
      setCurrentStepIndex(index);
    }
  };

  return (
    <Modal
      style={{
        maxWidth: '800px',
        padding: '0',
        overflow: 'hidden',
        border: 'none',
        ...props.style,
      }}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    >
      <MultiStepsModalContext.Provider
        value={{ currentStepIndex, stepsCount, changeStep }}
      >
        <MultiStepsModalContent>{currentStep}</MultiStepsModalContent>
      </MultiStepsModalContext.Provider>
    </Modal>
  );
};

export const useMultiStepsModalContext = () => {
  const context = useContext(MultiStepsModalContext);

  if (!context) {
    throw new Error(
      'MultiStepsModal components must be wrapped in <MultiStepsModal />'
    );
  }

  return context;
};
