import { useMutation } from 'urql';

import { graphql } from '../gql';
import { setAccessToken } from '../lib/auth';

const ForgotPassword = graphql(/* GraphQL */ `
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
    }
  }
`);

export const useForgotPassword = () => useMutation(ForgotPassword);

const ResetPassword = graphql(/* GraphQL */ `
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      authToken
      user {
        id
        name
        email
      }
    }
  }
`);

export const useResetPasswordAndLogin = () => {
  const [result, resetPassword] = useMutation(ResetPassword);

  const resetPasswordWrapper = async (token: string, password: string) => {
    const res = await resetPassword({ input: { token, password } });
    if (!res.error && res.data?.resetPassword) {
      setAccessToken(res.data.resetPassword.authToken);
    }
    return res;
  };

  return [result, resetPasswordWrapper] as const;
};
