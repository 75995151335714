import styled from 'styled-components';

export const Clickable = styled.button<{
  $active?: boolean;
  $fullWidth?: boolean;
}>`
  padding: 8px;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  background-color: ${({ theme, $active }) =>
    $active ? theme.surface.secondary : 'transparent'};

  transition: background-color 250ms ease;

  :hover {
    background-color: ${({ theme, $active }) =>
      $active ? theme.surface.secondary : theme.button.secondaryHover};
    cursor: ${({ $active }) => ($active ? 'default' : 'pointer')};
  }

  :disabled {
    color: ${({ theme }) => theme.text.subtext};
    background-color: transparent;
    cursor: default;
  }
`;
