import { graphql } from '../gql';
import { OutputFormat } from '../gql/graphql';
import { urqlClient } from '../lib/graphql';
import { waitForEphemeralJobResult } from '../subscriptions';
import { ephemeralJobMailboxId } from '../subscriptions/ephemeralJobs/ephemeralJobMailboxId';

export const MeshConvertMutation = graphql(/* GraphQL */ `
  mutation MeshConversion($input: MeshConversionInput!) {
    MeshConversion(input: $input) {
      jobId
    }
  }
`);

export const meshConvert = async (
  drawingId: string,
  quadMeshPath: string,
  outputFormat: OutputFormat
) => {
  const res = await urqlClient.mutation(MeshConvertMutation, {
    input: {
      id: drawingId,
      quadMeshPath,
      outputFormat,
      mailboxId: ephemeralJobMailboxId,
    },
  });

  if (res.data?.MeshConversion.jobId) {
    return waitForEphemeralJobResult<'meshConvert'>(
      res.data.MeshConversion.jobId,
      5 * 60 * 1000 // 5 minutes
    );
  } else {
    throw res.error ?? new Error('Failed to convert mesh');
  }
};
