import { useMutation } from 'urql';

import { graphql } from '../gql';

export const DeleteLayerMutation = graphql(/* GraphQL */ `
  mutation DeleteLayer($id: UUID!) {
    deleteLayer(input: { id: $id }) {
      layer {
        id
      }
      drawing {
        id
      }
    }
  }
`);

export const useDeleteLayer = () => useMutation(DeleteLayerMutation);
