import { useMutation } from 'urql';

import { graphql } from '../gql';

export { HelpType } from '../gql/graphql';

const HelpMutation = graphql(/* GraphQL */ `
  mutation Help($input: HelpInput!) {
    help(input: $input) {
      status
    }
  }
`);

export const useHelp = () => useMutation(HelpMutation);
