import { useMutation } from 'urql';

import { graphql } from '../gql';

export const UpdateDrawingLayersMutation = graphql(/* GraphQL */ `
  mutation UpdateDrawingLayers($input: UpdateDrawingLayersInput!) {
    updateDrawingLayers(input: $input) {
      drawing {
        id
        layers {
          nodes {
            ...LayerData
          }
        }
      }
    }
  }
`);

export const useUpdateDrawingLayers = () =>
  useMutation(UpdateDrawingLayersMutation);
