import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const TeamEventTypeZodEnum = z.enum(['TEAM_CREATED', 'TEAM_DELETED']);

export const TeamEventName = TeamEventTypeZodEnum.Enum;
export const TeamTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: TeamEventTypeZodEnum,
  data: z.object({
    teamId: z.string(),
  }),
});
