import { useMutation } from 'urql';

import { graphql } from '../../gql';

const CreateOrganizationInviteMutation = graphql(/* GraphQL */ `
  mutation CreateOrganizationInviteMutation(
    $input: CreateOrganizationInviteInput!
  ) {
    createOrganizationInvite(input: $input) {
      organization {
        id
        ...OrganizationInvitesData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useCreateOrganizationInvite = () =>
  useMutation(CreateOrganizationInviteMutation);

const DeleteOrganizationInviteMutation = graphql(/* GraphQL */ `
  mutation DeleteOrganizationInviteMutation(
    $input: DeleteOrganizationInviteInput!
  ) {
    deleteOrganizationInvite(input: $input) {
      organization {
        id
        ...OrganizationInvitesData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useDeleteOrganizationInvite = () =>
  useMutation(DeleteOrganizationInviteMutation);

const DeleteOrganizationInvitesOrMembersMutation = graphql(/* GraphQL */ `
  mutation deleteOrganizationInvitesOrMembersMutation(
    $input: ManageOrganizationInvitesOrMembersInput!
  ) {
    deleteOrganizationInvitesOrMembers(input: $input) {
      organization {
        id
        ...OrganizationInvitesData
        ...OrganizationMembersData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useDeleteOrganizationMembersOrInvite = () =>
  useMutation(DeleteOrganizationInvitesOrMembersMutation);

const ResendOrganizationInviteMutation = graphql(/* GraphQL */ `
  mutation ResendOrganizationInviteMutation(
    $input: ManageOrganizationInvitesOrMembersInput!
  ) {
    resendOrganizationInvites(input: $input) {
      success
    }
  }
`);

export const useResendOrganizationInvite = () =>
  useMutation(ResendOrganizationInviteMutation);
