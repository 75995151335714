import { useMutation } from 'urql';

import { graphql } from '../../gql';

const InviteToTeam = graphql(/* GraphQL */ `
  mutation InviteToTeam($input: InviteToTeamInput!) {
    inviteToTeam(input: $input) {
      team {
        ...TeamMembers
      }
      invitedToOrganizationEmails
    }
  }
`);

export const useInviteToTeam = () => {
  return useMutation(InviteToTeam);
};

const DeleteTeamInvite = graphql(/* GraphQL */ `
  mutation DeleteTeamInvite($input: DeleteTeamInviteInput!) {
    deleteTeamInvite(input: $input) {
      team {
        ...TeamMembers
      }
    }
  }
`);

export const useDeleteTeamInvite = () => {
  return useMutation(DeleteTeamInvite);
};
