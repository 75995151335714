import { assertExists } from '@vizcom/shared/js-utils';

import { graphql } from '../../gql';
import { urqlClient } from '../../lib/graphql';
import { waitForEphemeralJobResult } from '../../subscriptions';
import { ephemeralJobMailboxId } from '../../subscriptions/ephemeralJobs/ephemeralJobMailboxId';

export const EnhanceDrawingMutation = graphql(/* GraphQL */ `
  mutation EnhanceDrawing($input: EnhanceDrawingInput!) {
    enhanceDrawing(input: $input) {
      jobId
    }
  }
`);

export const enhanceImage = async (
  drawingId: string,
  image: Blob | string,
  options?: { isRetry?: boolean }
) => {
  const res = await urqlClient.mutation(EnhanceDrawingMutation, {
    input: {
      id: drawingId,
      image,
      mailboxId: ephemeralJobMailboxId,
      isRetry: options?.isRetry ?? false,
    },
  });

  assertExists(res.data?.enhanceDrawing.jobId);

  const jobOutput = await waitForEphemeralJobResult<'enhance'>(
    res.data.enhanceDrawing.jobId,
    65000 // timeout on the job-scheduler side is 60s, so we add a lit bit more on top of this to account for network latency
  );
  return jobOutput;
};
