import styled, { css } from 'styled-components';

export const buttonTypes = {
  primary: css`
    background-color: ${(p) => p.theme.button.primary};
    box-shadow: ${({ theme }) => theme.boxShadow.level1};
    outline-offset: 2px;
    outline: 1px solid transparent;

    :hover {
      background-color: ${(p) => p.theme.button.primaryHover};
    }
    :disabled {
      color: ${(p) => p.theme.text.primaryDisabled};
      background-color: ${(p) => p.theme.button.primaryDisabled};
      box-shadow: none;
    }
    :focus {
      outline: 1px solid ${(p) => p.theme.button.primary};
      box-shadow: none;
    }
  `,
  secondary: css`
    background-color: ${(p) => p.theme.button.secondary};

    :hover {
      background-color: ${(p) => p.theme.button.secondaryHover};
    }
    :disabled {
      color: ${(p) => p.theme.text.secondaryDisabled};
      background-color: ${(p) => p.theme.button.secondaryDisabled};
    }
    :focus {
      background-color: transparent;
      outline: 2px solid ${(p) => p.theme.button.primaryHover};
    }
  `,
  tertiary: css`
    background-color: transparent;

    :hover {
      color: ${(p) => p.theme.text.tertiaryHover};
    }
    :disabled {
      color: ${(p) => p.theme.text.secondaryDisabled};
    }
    :focus {
      outline: 2px solid ${(p) => p.theme.button.primaryHover};
    }
  `,
  danger: css`
    background-color: ${(p) => p.theme.surface.danger};
    color: ${(p) => p.theme.text.body};
    :disabled {
      color: ${(p) => p.theme.text.subtext};
      background-color: ${(p) => p.theme.surface.tertiary};
    }
    :not(:disabled) {
      :hover,
      :active,
      :focus {
        background-color: ${(p) => p.theme.surface.dangerHover};
      }
    }
  `,
  warning: css`
    background-color: ${(p) => p.theme.surface.warning};
    color: ${(p) => p.theme.text.body};
    :disabled {
      color: ${(p) => p.theme.text.subtext};
      background-color: ${(p) => p.theme.surface.tertiary};
    }
    :not(:disabled) {
      :hover,
      :active,
      :focus {
        background-color: ${(p) => p.theme.surface.warningHover};
      }
    }
  `,
  bare: css`
    background-color: transparent;
    padding: 0;
  `,
} as const;

const buttonSizes = {
  L: css`
    height: 38px;
    padding: 12px 20px;
    font-size: 14px;
  `,
  M: css`
    height: 32px;
    padding: 10px 18px;
    font-size: 12px;
  `,
  S: css`
    height: 30px;
    padding: 10px 16px;
    font-size: 10px;
    font-weight: 600;
  `,
  icon: css`
    height: 32px;
    width: 32px;
    padding: 8px;

    border-radius: ${({ theme }) => theme.borderRadius.m};
  `,
} as const;

export interface ButtonElProps {
  variant?: keyof typeof buttonTypes;
  size?: keyof typeof buttonSizes;
  $flat?: boolean;
  $fullWidth?: boolean;
}

const StyledButton = styled.button<ButtonElProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  outline: 0px solid transparent;
  box-shadow: none;

  color: ${(p) => p.theme.text.body};
  font-weight: 500;
  line-height: 1;

  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }

  transition: background-color 250ms ease, color 250ms ease, outline 250ms ease,
    box-shadow 0.05s ease;

  ${({ variant }) => buttonTypes[variant || 'bare']}
  /* Use specified size or default to M
   * (except for bare buttons which do not have a default size) */
  ${({ size, variant }) =>
    size
      ? buttonSizes[size]
      : variant !== undefined && variant !== 'bare'
      ? buttonSizes['M']
      : ''}
  ${({ $flat }) => $flat && 'box-shadow: none;'}
  ${({ $fullWidth }) => $fullWidth && 'width: 100%;'}
`;

export const Button = StyledButton;
