import { useMutation } from 'urql';

import { graphql } from '../../gql';

const DuplicateWorkbenchMutation = graphql(/* GraphQL */ `
  mutation DuplicateWorkbench($input: DuplicateWorkbenchInput!) {
    duplicateWorkbench(input: $input) {
      workbench {
        ...WorkbenchBasicData
      }
    }
  }
`);

export const useDuplicateWorkbench = () => {
  return useMutation(DuplicateWorkbenchMutation);
};
