import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  MouseEventHandler,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes, useTheme } from 'styled-components';

import { Button } from '../Button/Button';

interface ContextMenuProps {
  items: React.ReactNode;
  contextMenuPosition?: { x: number; y: number };
  withButton?: boolean;
  buttonProps?: React.ComponentProps<typeof Button>;
  parentRef?: MutableRefObject<HTMLElement | undefined>;
  referenceElementRef?: MutableRefObject<HTMLDivElement | null>;
  style?: React.CSSProperties;
  matchButtonWidth?: boolean;
  backdrop?: boolean;
  onOpenStateChange?: (open: boolean) => void;
  openOnRightClick?: boolean;
}

const cloneNodeWithBoundingBoc = (el: HTMLElement | undefined) => {
  if (!el) {
    return;
  }
  const parentClone = el.cloneNode(true) as HTMLDivElement;
  const originalBoundingBox = el.getBoundingClientRect();
  return {
    el: parentClone,
    position: {
      top: originalBoundingBox.top,
      left: originalBoundingBox.left,
      width: originalBoundingBox.width,
      height: originalBoundingBox.height,
    },
  };
};

export const ContextMenu = (props: PropsWithChildren<ContextMenuProps>) => {
  const [state, setState] = useState<null | {
    top: number;
    left: number;
    buttonWidth: number;
    isClosing?: boolean;
    parentEl?: {
      el: Node;
      position: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
    };
  }>(null);
  const buttonRef = useRef<HTMLButtonElement>();
  const contextMenuRef = useRef<HTMLDivElement>();
  const theme = useTheme();

  const openMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    let top, left;

    // If a reference element is provided, use its position
    if (props.referenceElementRef && props.referenceElementRef.current) {
      const rect = props.referenceElementRef.current.getBoundingClientRect();
      top = rect.bottom;
      left = rect.left;
    } else {
      // Else, calculate the position from the event
      top = e.clientY + 10;
      left = e.clientX;
      if (buttonRef.current) {
        const bb = buttonRef.current.getBoundingClientRect();
        top = bb.bottom + 10;
        left = bb.left;
      }
    }

    setState({
      top,
      left,
      parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
      buttonWidth: buttonRef.current?.clientWidth ?? 200,
    });
    props.onOpenStateChange?.(true);
  };

  const closeMenu = () => {
    setState(
      (s) =>
        s && {
          ...s,
          isClosing: true,
        }
    );
    setTimeout(() => {
      setState(null);
      props.onOpenStateChange?.(false);
    }, parseFloat(theme.animation.duration));
  };

  const onBackdropClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      contextMenuRef.current &&
      e.target &&
      !contextMenuRef.current.contains(e.target as any)
    ) {
      closeMenu();
    }
  };

  const handleMenuItemClick: MouseEventHandler = (e) => {
    closeMenu();
  };

  // listen to right-click on parent element
  useEffect(() => {
    if (props.contextMenuPosition) {
      return;
    }

    const handler = (e: MouseEvent) => {
      if (
        props.parentRef?.current &&
        props.parentRef.current.contains(e.target as any)
      ) {
        e.preventDefault();
        setState({
          top: e.clientY,
          left: e.clientX,
          buttonWidth: buttonRef.current?.clientWidth ?? 200,
          parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
        });
        props.onOpenStateChange?.(true);
      }
    };

    document.addEventListener('contextmenu', handler);

    return () => {
      document.removeEventListener('contextmenu', handler);
    };
  }, [props.parentRef, props.onOpenStateChange, props.contextMenuPosition]);

  useEffect(() => {
    if (!props.contextMenuPosition) {
      return;
    }

    setState({
      top: props.contextMenuPosition.y,
      left: props.contextMenuPosition.x,
      buttonWidth: buttonRef.current?.clientWidth ?? 200,
      parentEl: cloneNodeWithBoundingBoc(props.parentRef?.current),
    });
    props.onOpenStateChange?.(true);
  }, [props.contextMenuPosition]);

  const mountParent = (el: HTMLDivElement | null) => {
    if (!el || !state?.parentEl) {
      return;
    }

    el.appendChild(state.parentEl.el);
  };

  const openMethod = props.openOnRightClick
    ? { onContextMenu: openMenu }
    : { onClick: openMenu };

  return (
    <>
      {props.withButton !== false && (
        <Button
          ref={buttonRef as any}
          type="button"
          {...openMethod}
          {...props.buttonProps}
        >
          {props.children}
        </Button>
      )}
      {state
        ? createPortal(
            <Backdrop
              onClick={onBackdropClick}
              onContextMenu={onBackdropClick}
              $closing={state.isClosing}
              $visible={props.backdrop ?? false}
            >
              {state.parentEl && (
                <ParentContainer
                  $closing={state.isClosing}
                  className="hover"
                  style={state.parentEl.position}
                  ref={mountParent}
                />
              )}
              <Container
                $closing={state.isClosing}
                id="context-menu"
                ref={contextMenuRef as any}
                onClick={handleMenuItemClick}
                style={{
                  ['--menu-left' as any]: `${state.left}px`,
                  ['--menu-top' as any]: `${state.top}px`,
                  ...(props.matchButtonWidth
                    ? {
                        width: state.buttonWidth,
                      }
                    : {}),
                  // transform: `translate3d(min(calc(100dvw - 100%), ${state.left}px), min(calc(100dvh - 100%), ${state.top}px))`,
                  // left: `min(calc(100dvw - 100%), ${state.left}px)`,
                  // top: state.top,
                  ...props.style,
                }}
              >
                {props.items}
              </Container>
            </Backdrop>,
            document.body
          )
        : createPortal(
            /* NOTE Instantiate context menu items in the background to let passive listeners / keyboard shortcuts to stay active */
            <ContainerHidden>{props.items}</ContainerHidden>,
            document.body
          )}
    </>
  );
};

const Backdrop = styled.div<{ $closing?: boolean; $visible: boolean }>`
  width: 100vw; // fallback for older browsers
  width: 100dvw;
  height: 100vh; // fallback for older browsers
  height: 100dvh;
  background-color: ${({ $visible }) =>
    $visible ? 'rgba(0, 0, 0, 0.2)' : 'transparent'};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000000000;
  animation: ${({ theme }) => theme.animation.duration} ease
    ${({ theme, $visible, $closing }) =>
      !$visible
        ? 'none'
        : $closing
        ? theme.animation.backgroundFadeOut
        : theme.animation.backgroundFadeIn};
  animation-fill-mode: both;
  ${({ $closing }) => $closing && 'pointer-events: none;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const parentGrow = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

const parentGrowReverse = keyframes`
  from {
    transform: scale(1.03);
  }
  to {
    transform: scale(1);
    // setting opacity to 0 only when exiting to make sure we have a transition from the hover to non-hover state below it
    opacity: 0;
  }
`;

const ParentContainer = styled.div<{ $closing?: boolean }>`
  animation: ${({ theme }) => theme.animation.duration} ease
    ${(p) => (p.$closing ? parentGrowReverse : parentGrow)};
  animation-fill-mode: both;

  position: absolute;
  pointer-events: none;
`;

// using menu-left and menu-top to prevent context menu from clipping outside of screen
const contextMenuContainerEnter = keyframes`
  from {
    opacity: 0;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      calc(min(calc(100dvh - 100% - 10px), var(--menu-top)) - 10px),
      0
    );
  }
  to {
    opacity: 1;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      min(calc(100dvh - 100% - 10px), var(--menu-top)),
      0
    );
  }
`;

const contextMenuContainerExit = keyframes`
  from {
    opacity: 1;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      min(calc(100dvh - 100% - 10px), var(--menu-top)),
      0
      );
  }
  to {
    opacity: 0;
    transform: translate3d(
      min(calc(100dvw - 100% - 10px), var(--menu-left)),
      calc(min(calc(100dvh - 100% - 10px), var(--menu-top)) - 10px),
      0
    );
  }
`;

const Container = styled.div<{ $closing?: boolean }>`
  min-width: 200px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.surface.secondary};
  border: 1px solid ${({ theme }) => theme.surface.tertiary};
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  z-index: 3;
  overflow: hidden;
  animation: ${({ theme }) => theme.animation.duration} ease
    ${(p) =>
      p.$closing ? contextMenuContainerExit : contextMenuContainerEnter};
  animation-fill-mode: both;

  transform-origin: 50% 50%;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

const ContainerHidden = styled.div`
  display: none;
  pointer-events: none;
`;
