import { useEffect } from 'react';
import { CombinedError, useMutation, useQuery } from 'urql';

import { graphql } from '../gql';
import { FolderDataFragment } from '../gql/graphql';

const CreateCurrentUserDraftFolderForOrganization = graphql(/* GraphQL */ `
  mutation CreateCurrentUserDraftFolderForOrganization(
    $input: CreateCurrentUserDraftFolderForOrganizationInput!
  ) {
    createCurrentUserDraftFolderForOrganization(input: $input) {
      rootFolderOfDraftOrganization {
        id
        currentUserDraftFolder {
          ...FolderData
        }
      }
    }
  }
`);

export const draftFolder = graphql(/* GraphQL */ `
  query draftFolder($id: UUID!) {
    organization(id: $id) {
      id
      currentUserDraftFolder {
        ...FolderData
      }
    }
  }
`);

// This first fetches the currentUserDraftFolder for the selected organization, and if it doesn't exist
// (but if the organization was fetched successfully), creates it
export const useFetchOrCreateUserOrganizationDraftFolder = (
  organizationId: string | undefined
):
  | { loading: false; error: CombinedError; data: null }
  | { loading: false; error: null; data: FolderDataFragment }
  | { loading: true; error: null; data: null } => {
  const [draftFolderRes] = useQuery({
    query: draftFolder,
    variables: {
      id: organizationId!,
    },
    pause: !organizationId,
  });

  const [createRes, createDraftFolder] = useMutation(
    CreateCurrentUserDraftFolderForOrganization
  );

  useEffect(() => {
    if (createRes.fetching || createRes.error) {
      return;
    }
    if (
      !!draftFolderRes.data?.organization &&
      draftFolderRes.data.organization.currentUserDraftFolder === null
    ) {
      createDraftFolder({
        input: {
          targetOrganizationId: organizationId,
        },
      });
    }
  }, [createRes, createDraftFolder, draftFolderRes, organizationId]);

  const error = draftFolderRes.error || createRes.error;
  if (error) {
    return {
      loading: false,
      error,
      data: null,
    };
  }

  if (draftFolderRes.data?.organization?.currentUserDraftFolder) {
    return {
      loading: false,
      error: null,
      data: draftFolderRes.data.organization.currentUserDraftFolder,
    };
  }

  return {
    loading: true,
    error: null,
    data: null,
  };
};
