import { MutableRefObject, createContext, useContext } from 'react';

interface ModalContextType {
  backdropElement: MutableRefObject<HTMLDivElement | null>;
}

const modalContext = createContext<ModalContextType | null>(null);

export const ModalContextProvider = modalContext.Provider;
export const useModalContext = () => {
  return useContext(modalContext);
};
