import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const FolderAssetColorDataFragment = graphql(/* GraphQL */ `
  fragment FolderAssetColorData on FolderAssetColor {
    id
    name
    red
    green
    blue
  }
`);

export const WorkbenchAssetColorDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchAssetColorData on WorkbenchAssetColor {
    id
    name
    red
    green
    blue
  }
`);

export type FolderAssetColorData = ResultOf<
  typeof FolderAssetColorDataFragment
>;

export type WorkbenchAssetColorData = ResultOf<
  typeof WorkbenchAssetColorDataFragment
>;

export type AssetColorData =
  | Omit<FolderAssetColorData, '__typename'>
  | Omit<WorkbenchAssetColorData, '__typename'>;
