import { useTransition } from '@react-spring/web';
import styled from 'styled-components';

import { LoadingLogo } from '../Logo';
import { Tooltip } from '../Tooltip/Tooltip';
import { Spinner } from './Loader.styled';

type LoaderProps = {
  size?: {
    width: number;
    height: number;
  };
  tooltip?: string;
  active?: boolean;
};

export const Loader = (props: LoaderProps) => {
  const { size, tooltip, active = true } = props;

  const transitions = useTransition(active ? [true] : [], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      delay: 300, // if Loader is displayed for less than 300ms, don't show anything
    },
    leave: {
      opacity: 0,
    },
  });

  return transitions((style) =>
    tooltip ? (
      <Tooltip tip={tooltip}>
        <Spinner style={style} size={size} />
      </Tooltip>
    ) : (
      <Spinner style={style} size={size} />
    )
  );
};

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.surface.primary};
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000000001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const FullPageDarkLoader = () => {
  return (
    <FullPageContainer>
      <LoadingLogo />
    </FullPageContainer>
  );
};
