import { graphql } from '../../gql';

export const CreateWorkbenchElementAnimateMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementAnimate(
    $input: CreateWorkbenchElementAnimateInput!
  ) {
    createWorkbenchElementAnimate(input: $input) {
      workbenchElementAnimate {
        ...WorkbenchElementAnimateData
      }
    }
  }
`);

export const UpdateWorkbenchElementAnimateMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementAnimate(
    $input: UpdateWorkbenchElementAnimateInput!
  ) {
    updateWorkbenchElementAnimate(input: $input) {
      workbenchElementAnimate {
        ...WorkbenchElementAnimateData
      }
    }
  }
`);

export const DeleteWorkbenchElementAnimateMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementAnimate(
    $input: DeleteWorkbenchElementAnimateInput!
  ) {
    deleteWorkbenchElementAnimate(input: $input) {
      deletedWorkbenchElementAnimateNodeId
    }
  }
`);

export const TriggerWorkbenchElementAnimateMutation = graphql(/* GraphQL */ `
  mutation TriggerWorkbenchElementAnimate(
    $input: TriggerWorkbenchElementAnimateInput!
  ) {
    triggerWorkbenchElementAnimate(input: $input) {
      placeholder {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);
