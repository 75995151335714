import * as React from "react";
import { forwardRef } from "react";
const Svg3DCube = (props, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", ref, ...props }, /* @__PURE__ */ React.createElement("path", { fill: "#a8e14b", d: "M3.58 4.09 6.763 2.32a2.546 2.546 0 0 1 2.473 0L12.42 4.09a2.546 2.546 0 0 1 1.31 2.226v4.12c0 .462-.251.887-.655 1.112l-3.838 2.132a2.546 2.546 0 0 1-2.473 0l-3.837-2.132a1.274 1.274 0 0 1-.655-1.113V6.315c0-.925.501-1.777 1.31-2.226z", style: {
  strokeWidth: 2.39344
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.742 2.013a2.546 2.546 0 0 0-.98.307L3.58 4.089a2.544 2.544 0 0 0-1.31 2.225v4.12a1.274 1.274 0 0 0 .655 1.112l3.837 2.133a2.546 2.546 0 0 0 2.475 0l3.838-2.133a1.274 1.274 0 0 0 .654-1.112v-4.12a2.546 2.546 0 0 0-1.31-2.225l-3.183-1.77a2.546 2.546 0 0 0-1.494-.306Zm-.02.899a1.657 1.657 0 0 1 1.082.186l3.052 1.694-3.841 1.922-3.857-1.928 3.038-1.687a1.657 1.657 0 0 1 .526-.187ZM3.43 5.415l4.138 2.069v5.55a1.657 1.657 0 0 1-.373-.133h-.002l-3.837-2.13a.384.384 0 0 1-.197-.337v-4.12c0-.326.1-.635.271-.899zm9.127.02a1.657 1.657 0 0 1 .282.88v4.119c0 .14-.075.268-.197.336L8.804 12.9a1.657 1.657 0 0 1-.344.123V7.484Z", style: {
  fill: "#f8f8f8",
  fillOpacity: 0.698039,
  strokeWidth: 2.39339
} }));
const ForwardRef = forwardRef(Svg3DCube);
export default ForwardRef;
