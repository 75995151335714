import styled from 'styled-components';

import { CloseIcon } from '../icons';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text.body};
`;

export const ModalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.surface.tertiary};
`;

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  flex: 1;
`;

export const ModalActions = styled.div`
  display: grid;
  margin-top: 24px;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

export const ModalCloseButton = styled(CloseIcon).attrs({
  'data-close-modal': true,
})`
  cursor: pointer;
`;

export const ModalContent = styled.div`
  padding: 20px 0;
  color: ${(p) => p.theme.text.body};
  flex: 1;
  overflow-y: auto;
  ${({ theme }) => theme.scrollbar.light}
`;

export const ModalContentDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.surface.tertiary};
`;
