import styled, { DefaultTheme, css } from 'styled-components';

const LegacyType = {
  b3: css`
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  `,
} as const;

interface TextProps {
  children: React.ReactNode;
  type?: keyof DefaultTheme['typography'] | keyof typeof LegacyType;
  color?: keyof DefaultTheme['text'];
  block?: boolean;
  ellipsis?: boolean;
  tabularNums?: boolean;
}

export const Text = styled.span<TextProps>`
  ${({ type, theme }) => {
    if (type && type in theme.typography) {
      return theme.typography[type as keyof DefaultTheme['typography']];
    } else if (type && type in LegacyType) {
      return LegacyType[type as keyof typeof LegacyType];
    }
    return theme.typography.b1;
  }}

  ${({ color, theme }) => color && `color: ${theme.text[color]};`}

  ${({ ellipsis }) =>
    ellipsis
      ? 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'
      : ''}

  ${({ block }) => block && 'display: block;'}

  ${({ tabularNums }) => tabularNums && 'font-variant-numeric: tabular-nums;'}
`;
