import { DragOverlay, Modifier } from '@dnd-kit/core';
import type { ClientRect } from '@dnd-kit/core';
import { getEventCoordinates } from '@dnd-kit/utilities';
import type { Transform } from '@dnd-kit/utilities';
import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  selectedOffset: number;
  selectedCount: number;
}

const snapToCursor: Modifier = ({
  activatorEvent,
  draggingNodeRect,
  transform,
}) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetY = activatorCoordinates.y - draggingNodeRect.top;

    return {
      ...transform,
      y: transform.y + offsetY - draggingNodeRect.height / 2,
    };
  }

  return transform;
};

function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect
): Transform {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
}

const restrictToFirstScrollableAncestorWithOffset: (
  selectedOffset: number
) => Modifier =
  (selectedOffset: number) =>
  ({
    draggingNodeRect,
    transform,
    scrollableAncestorRects,
    activatorEvent,
  }) => {
    const firstScrollableAncestorRect = scrollableAncestorRects[0];

    if (!draggingNodeRect || !firstScrollableAncestorRect || !activatorEvent) {
      return transform;
    }

    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetY = activatorCoordinates.y - draggingNodeRect.top;
    const interactionDiff =
      offsetY -
      draggingNodeRect.height / 2 -
      selectedOffset * draggingNodeRect.height;

    const adjusttedAncestorRect = {
      ...firstScrollableAncestorRect,
      top:
        firstScrollableAncestorRect.top -
        selectedOffset * draggingNodeRect.height -
        interactionDiff,
    } as ClientRect;

    return restrictToBoundingRect(
      transform,
      draggingNodeRect,
      adjusttedAncestorRect
    );
  };

export function SortableOverlay({
  children,
  selectedOffset,
  selectedCount,
}: PropsWithChildren<Props>) {
  return (
    <DragOverlay
      modifiers={[
        restrictToFirstScrollableAncestorWithOffset(selectedOffset),
        snapToCursor,
      ]}
    >
      {selectedCount > 1 && <Count>{selectedCount}</Count>}
      <div
        style={{
          opacity: 0.3,
        }}
      >
        {children}
      </div>
    </DragOverlay>
  );
}

const Count = styled.div`
  position: absolute;
  top: -20px;
  right: 0;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;
