import { z } from 'zod';

export const TrackingEventContextSchema = z
  .object({
    /* Use "nullable" instead of "optional" because we want it to be intentionally unset. */
    userId: z.string().nullable(),
    userAgent: z.string().nullable(),
    clientIp: z.string().optional(),
  })
  .optional();

export type TrackingEventContext = z.infer<typeof TrackingEventContextSchema>;

export const BaseTrackingEventSchema = z.object({
  context: TrackingEventContextSchema,
});
