import {
  Text,
  Button,
  buttonTypes,
  CloseIcon,
  InlineFlex,
  BlockStack,
} from '@vizcom/shared-ui-components';

/*
 * This component is used to display a confirmation modal with a title and description.
 * It should be used  with the triggerConfirmModal function.
 *
 * @param onConfirm - The function to call when the user confirms the action.
 * @param onCancel - The function to call when the user cancels the action.
 * @param title - The title of the modal.
 * @param description - The description of the modal.
 *
 * @example
 * ```tsx
 * triggerConfirmModal({
 *  body: (resolve, reject) => (
 *   <ConfirmationModalComponent
 *     onConfirm={resolve}
 *     onCancel={reject}
 *     title="Are you sure?"
 *     description="This action cannot be undone."
 *   />
 *  ),
 * });
 * ```
 */
export function ConfirmationModalComponent({
  onConfirm,
  onCancel,
  title,
  description,
  variant = 'primary',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
}: {
  onConfirm: (value: unknown) => void;
  onCancel: (reason?: any) => void;
  title: string;
  description: string;
  variant?: keyof typeof buttonTypes;
  confirmLabel?: string;
  cancelLabel?: string;
}) {
  return (
    <BlockStack $gap={15} style={{ maxWidth: 350 }}>
      <InlineFlex $gap={10} $justifyContent="space-between">
        <Text type="sh2" color="body">
          {title}
        </Text>
        <CloseIcon
          onClick={onCancel}
          height={20}
          width={20}
          style={{ cursor: 'pointer' }}
          data-close-modal
        />
      </InlineFlex>

      <Text block color="subtext">
        {description}
      </Text>

      <InlineFlex $gap={10} $justifyContent="space-between">
        <Button
          variant="secondary"
          onClick={onCancel}
          style={{ flex: 1 }}
          data-close-modal
        >
          {cancelLabel}
        </Button>
        <Button
          variant={variant}
          onClick={onConfirm}
          style={{ flex: 1 }}
          data-close-modal
          data-autofocus="true"
        >
          {confirmLabel}
        </Button>
      </InlineFlex>
    </BlockStack>
  );
}
