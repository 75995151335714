import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const NewUserEventTypeZodEnum = z.enum([
  'VISITED_SIGNIN_PAGE',
  'VISITED_SIGNUP_PAGE',
  'SENT_SIGNUP_EMAIL',
  'VISITED_VERIFY_EMAIL_PAGE',
]);

export const NewUserEventName = NewUserEventTypeZodEnum.Enum;

export const NewUserTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: NewUserEventTypeZodEnum,
  data: z.object({
    email: z.string().optional(),
  }),
});
