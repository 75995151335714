import { CombinedError } from 'urql';

export function getCombinedErrorCode(error: CombinedError): string | undefined {
  const graphQLError = error.graphQLErrors[0];
  const exception = graphQLError.extensions['exception'] as { code?: string };

  return exception.code;
}

export function formatErrorMessage(
  error?: unknown,
  fallbackMessage: string = 'Unknown error'
): string {
  if (!error) return fallbackMessage;

  if (
    error instanceof CombinedError &&
    error.graphQLErrors &&
    error.graphQLErrors.length > 0
  ) {
    return error.graphQLErrors[0].message;
  }

  if (error instanceof Error && error.message) {
    return error.message;
  }

  return fallbackMessage;
}
