import React, { useState } from 'react';
import styled from 'styled-components';
import { InlineFlex } from '@vizcom/shared-ui-components';

import { Button } from '../../Button/Button';
import {
  CustomSelectTriggerContentProps,
  DefaultOptionComponent,
  Select,
} from '../../Select/Select';
import { Text } from '../../Text/Text';
import { EyeDropperIcon, NarrowCarretIcon } from '../../icons';
import {
  hexToHsva,
  hslaToHsva,
  hsvaToHex,
  hsvaToHsla,
  hsvaToRgba,
  rgbaToHsva,
} from '../utils/convert';
import { ColorModel } from '../utils/types';
import { useColorManipulation } from '../utils/useColorManipulation';
import { equalHex } from '../utils/utils';
import { ColorComponentsInput, HexColorInput } from './ColorInput';
import { Hue } from './Hue';
import { Saturation } from './Saturation';

const colorModel: ColorModel<string> = {
  defaultColor: '000',
  toHsva: hexToHsva,
  fromHsva: ({ h, s, v }) => hsvaToHex({ h, s, v, a: 1 }),
  equal: equalHex,
};

const colorModeOptions = [
  { label: 'Hex', value: 'hex' },
  { label: 'RGB', value: 'rgb' },
  { label: 'HSL', value: 'hsl' },
];

export type ColorPickerBaseProps = {
  color: string;
  onChange: (color: string) => void;
  onEyeDropperClicked?: () => void;
  className?: string;
};

const ColorPickerBase = ({
  color,
  onChange,
  onEyeDropperClicked,
  className,
}: ColorPickerBaseProps) => {
  const [hsva, updateHsva] = useColorManipulation(colorModel, color, onChange);
  const [colorMode, setColorMode] = useState<'hex' | 'rgb' | 'hsl'>('hex');

  return (
    <Container className={className}>
      <StyledSaturation
        hsva={hsva}
        onChange={updateHsva}
        className="saturation"
      />
      <HueRow>
        <StyledHue hue={hsva.h} onChange={updateHsva} />
        {onEyeDropperClicked && (
          <StyledEyeDropperButton
            variant="secondary"
            size="icon"
            onClick={onEyeDropperClicked}
          >
            <EyeDropperIcon />
          </StyledEyeDropperButton>
        )}
      </HueRow>
      <InputRow>
        {colorMode === 'hex' && (
          <StyledColorStringInput
            color={color}
            onChange={(c) => onChange('#' + c)}
          />
        )}
        {colorMode === 'rgb' && (
          <StyledColorComponentsInput
            config={[
              { min: 0, max: 255, unit: '' },
              { min: 0, max: 255, unit: '' },
              { min: 0, max: 255, unit: '' },
            ]}
            values={(() => {
              const { r, g, b } = hsvaToRgba(hsva);
              return [r, g, b];
            })()}
            onChange={([r, g, b]) =>
              updateHsva(
                rgbaToHsva({ r: Number(r), g: Number(g), b: Number(b), a: 1 })
              )
            }
          />
        )}
        {colorMode === 'hsl' && (
          <StyledColorComponentsInput
            className="componentsInput"
            config={[
              { min: 0, max: 360, unit: '' },
              { min: 0, max: 100, unit: '%' },
              { min: 0, max: 100, unit: '%' },
            ]}
            values={(() => {
              const { h, s, l } = hsvaToHsla(hsva);
              return [h, s, l];
            })()}
            onChange={([h, s, l]) =>
              updateHsva(
                hslaToHsva({ h: Number(h), s: Number(s), l: Number(l), a: 1 })
              )
            }
          />
        )}

        <Select
          onSelectElement={(e) => setColorMode(e as 'hex' | 'rgb' | 'hsl')}
          customSelectedTriggerContent={CustomSelectedTriggerContent}
          selectedOptionIndex={0}
          style={{ flexGrow: 0 }}
        >
          {colorModeOptions.map((mode) => (
            <DefaultOptionComponent
              key={mode.value}
              value={mode.value}
              label={mode.label}
            />
          ))}
        </Select>
      </InputRow>
    </Container>
  );
};

const CustomSelectedTriggerContent = (
  props: CustomSelectTriggerContentProps
) => {
  const { selectedIndex } = props;

  if (selectedIndex === null) {
    return (
      <Text block type="b1">
        Select Color mode
      </Text>
    );
  }

  const selectedMode = colorModeOptions[selectedIndex];
  return (
    <InlineFlex $gap={8} style={{ margin: 6 }}>
      <Text type="b1">{selectedMode.label}</Text>
      <NarrowCarretIcon />
    </InlineFlex>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSaturation = styled(Saturation)`
  cursor: pointer;
  height: 140px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  & .pointer {
    color: red;
    z-index: 3;
  }
`;

const HueRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: center;
`;

const StyledHue = styled(Hue)`
  flex-grow: 1;
  height: 14px;
  border-radius: 7px;
  cursor: pointer;
  & .pointer {
    z-index: 2;
  }
`;

const StyledEyeDropperButton = styled(Button)`
  width: 60px;
  background-color: ${({ theme }) => theme.surface.secondary};
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
`;

const StyledColorStringInput = styled(HexColorInput)`
  width: 0;
  flex-grow: 1;
  text-transform: uppercase;
`;

const StyledColorComponentsInput = styled(ColorComponentsInput)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 0;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  & input {
    flex-grow: 1;
    border: 1px solid ${({ theme }) => theme.surface.secondary};
    background-color: ${({ theme }) => theme.surface.secondary};
    color: ${({ theme }) => theme.text.body};
    border-radius: 0;
    padding: 0 10px;
    outline: none;
    transition: box-shadow 0.2s ease-in-out;
    font-size: 12px;
  }

  & > :first-child {
    border-right: 1px solid ${({ theme }) => theme.surface.tertiary};
    & input {
      border-radius: ${({ theme }) => theme.borderRadius.m} 0px 0px
        ${({ theme }) => theme.borderRadius.m};
    }
  }
  & > :last-child {
    border-left: 1px solid ${({ theme }) => theme.surface.tertiary};
    & input {
      border-radius: 0px ${({ theme }) => theme.borderRadius.m}
        ${({ theme }) => theme.borderRadius.m} 0px;
    }
  }
`;

export const ColorPicker = React.memo(ColorPickerBase);
