import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const WorkbenchSharingEventTypeZodEnum = z.enum(['SHARING_LINK_COPIED']);

export const WorkbenchSharingName = WorkbenchSharingEventTypeZodEnum.Enum;

export const WorkbenchSharingEventSchema = BaseTrackingEventSchema.extend({
  type: WorkbenchSharingEventTypeZodEnum,
  data: z.object({
    workbenchId: z.string(),
  }),
});
