import { useMutation } from 'urql';

import { graphql } from '../gql';

const AcceptEducationPlanRequestMutation = graphql(/* GraphQL */ `
  mutation AcceptEducationPlanRequest(
    $input: AcceptEducationPlanRequestInput!
  ) {
    acceptEducationPlanRequest(input: $input) {
      educationPlanRequest {
        ...EducationPlanRequestData
      }
    }
  }
`);

const RejectEducationPlanRequestMutation = graphql(/* GraphQL */ `
  mutation RejectEducationPlanRequest(
    $input: RejectEducationPlanRequestInput!
  ) {
    rejectEducationPlanRequest(input: $input) {
      educationPlanRequest {
        ...EducationPlanRequestData
      }
    }
  }
`);

const CreateEducationPlanRequestMutation = graphql(/* GraphQL */ `
  mutation CreateEducationPlanRequest(
    $input: CreateEducationPlanRequestInput!
  ) {
    createEducationPlanRequest(input: $input) {
      educationPlanRequest {
        ...EducationPlanRequestData
      }
    }
  }
`);

export const useAcceptEducationPlanRequest = () =>
  useMutation(AcceptEducationPlanRequestMutation);

export const useRejectEducationPlanRequest = () =>
  useMutation(RejectEducationPlanRequestMutation);

export const useCreateEducationPlanRequest = () =>
  useMutation(CreateEducationPlanRequestMutation);
