import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { filterExists, filterNonFalse } from '@vizcom/shared/js-utils';

interface HorizontalSwitcherProps<T> {
  options: ({ value: T; label: ReactNode } | false | undefined)[];
  value: T;
  onChange: (value: T) => void;
  style?: CSSProperties;
  optionStyle?: CSSProperties;
}

export function HorizontalSwitcher<T>({
  value,
  options,
  onChange,
  style,
  optionStyle,
}: HorizontalSwitcherProps<T>) {
  const validOptions = options.filter(filterExists).filter(filterNonFalse);

  const activeOptionIndex = validOptions.findIndex(
    (option) => option.value === value
  );

  return (
    <Container style={style}>
      {validOptions.map((option, index) => (
        <Option
          type="button"
          $active={option.value === value}
          key={index}
          onClick={() => onChange(option.value)}
          style={optionStyle}
        >
          {option.label}
        </Option>
      ))}
      {activeOptionIndex !== -1 && (
        <SelectedTrack
          $optionsCount={validOptions.length}
          $activeOptionIndex={activeOptionIndex}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadius.m};
  background-color: ${(p) => p.theme.surface.secondary};
  padding: 1px;
  min-height: 32px;
  position: relative;
`;

const Option = styled.button<{ $active: boolean }>`
  color: ${(p) => (p.$active ? p.theme.text.body : p.theme.text.subtext)};
  border: none;
  outline: none;
  background-color: transparent;
  width: 0;
  flex: 1;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s;
`;

const SelectedTrack = styled.div<{
  $optionsCount: number;
  $activeOptionIndex: number;
}>`
  width: calc(calc(100% - 4px) / ${(p) => p.$optionsCount});
  background-color: ${(p) => p.theme.surface.tertiary};
  height: calc(100% - 4px);
  z-index: 0;
  transform: translateX(calc(100% * ${(p) => p.$activeOptionIndex}));
  position: absolute;
  transition: transform 0.3s;
  border-radius: calc(${(p) => p.theme.borderRadius.m} - 2px);
  left: 2px;
`;
