import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

export default [
  BaseTrackingEventSchema.extend({
    type: z.enum(['ADD_TO_LAYER']),
    data: z.object({
      publicPaletteId: z.string().optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['CANCEL_INFERENCE']),
    data: z.object({
      promptId: z.string(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['EXPORT_HISTORY_IMAGE']),
    data: z.object({
      promptId: z.string(),
      imagePaths: z.array(z.string()).optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['EXPORT_IMAGE']),
    data: z.object({
      promptId: z.string().optional(),
      imagePath: z.string(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['GENERATE_IMAGE']),
    data: z.object({
      source: z.enum(['WORKBENCH', 'STUDIO']),
      imageInferenceType: z.string(),
      promptId: z.string().optional(),
      styleReferenceEnabled: z.boolean(),
      customModelId: z.string().optional(),
      publicPaletteId: z.string().optional(),
      workbenchPaletteId: z.string().optional(),
      userPaletteId: z.string().optional(),
      sourceImageInfluences: z.array(z.number()).optional(),
      sourceImageInfluence: z.number().optional(),
      prompt: z.string().optional(),
      outputsCount: z.number().optional(),
      paletteInfluence: z.number().optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['SANITIZED_PROMPT']),
    data: z.object({
      prompt: z.string(),
      sanitizedPrompt: z.string(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['IMAGE_GENERATED']),
    data: z.object({
      prompt: z.string(),
      outputsCount: z.number(),
      imagePaths: z.array(z.string()),
      imageInferenceType: z.string(),
      sourceImageInfluence: z.number(),
      paletteInfluence: z.number(),
      publicPaletteId: z.string().optional(),
      customModelId: z.string().optional(),
      workbenchPaletteId: z.string().optional(),
      sourceImagePath: z.string(),
      styleReferenceImagePath: z.string().optional(),
      nsfwScores: z.array(z.number()),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['GENERATE_VIDEO']),
    data: z.object({
      source: z.enum(['WORKBENCH']),
      prompt: z.string().optional(),
      sourceImageId: z.string().optional(),
    }),
  }),
];
