import { atom } from 'recoil';

export const moveModalTarget = atom<{
  open: boolean;
  id: string | null;
  type: 'workbench' | 'folder';
  preventRedirectAfterMove?: boolean | null;
}>({
  key: 'moveModalTarget',
  default: { open: false, type: 'workbench', id: null },
});
