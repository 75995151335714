import { useIsWorkbenchViewer } from 'libs/web/features/workbench/src/lib/utils';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';

import { RichTooltipContent, useTooltipContext } from './RichTooltip';

const OnboardingTourArrow = React.forwardRef(function OnboardingTourArrow(
  { ...props },
  ref
) {
  const theme = useTheme();
  const context = useTooltipContext();
  const arrowX = context.middlewareData.arrow?.x ?? 0;
  const arrowY = context.middlewareData.arrow?.y ?? 0;

  const arrowStyles: React.CSSProperties = {
    ...context.floatingStyles,
    zIndex: -1,
  };

  const arrowLength = Math.abs(
    context.middlewareData.offset?.y || context.middlewareData.offset?.x || 0
  );

  switch (context.placement) {
    case 'left':
      arrowStyles.left = 'unset';
      arrowStyles.right = 0;
      arrowStyles.transform = `translate(${
        arrowX + arrowLength / 2
      }px, ${arrowY}px) rotate(90deg)`;
      break;
    case 'right':
      arrowStyles.transform = `translate(${
        -arrowLength / 2
      }px, ${arrowY}px) rotate(-90deg)`;
      break;
    case 'top':
      arrowStyles.top = 'unset';
      arrowStyles.bottom = 0;
      arrowStyles.transform = `translate(${arrowX}px, ${arrowLength}px) rotate(180deg)`;
      break;
    case 'bottom':
    default:
      arrowStyles.transform = `translate(${arrowX}px, ${-arrowLength}px)`;
      break;
  }

  return (
    <div ref={context.arrowRef} style={arrowStyles} {...props}>
      <svg
        width={8}
        viewBox={`0 0 8 ${arrowLength}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 6v104" stroke={theme.icon.action} strokeWidth="2" />
        <circle
          cx="4"
          cy="4"
          r="3"
          fill={theme.icon.primary}
          stroke={theme.icon.action}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
});

const OnboardingTourContentContainer = styled.div`
  padding: 0px;
  border: 2px solid ${(p) => p.theme.deprecated.primary.default};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  box-shadow: 0px 2px 4px 0px #00000040;
  background-color: ${(p) => p.theme.surface.secondary};
`;

export const OnboardingTourTooltipContent = (
  props: Parameters<typeof RichTooltipContent>[0]
) => {
  return (
    <RichTooltipContent
      {...props}
      renderArrow={() => <OnboardingTourArrow />}
      renderContainer={(props) => (
        <OnboardingTourContentContainer {...(props as any)} />
      )}
    />
  );
};

export function useShouldDisplayOnboardingTour(version = 'v_1_1') {
  const isViewer = useIsWorkbenchViewer();
  const key = UserClientStateKeys.OnboardingStatus;
  const status = useCurrentUserClientStateByKey(key) || {};

  return !status[version] && !isViewer;
}
