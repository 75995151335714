import { useMutation } from 'urql';

import { graphql } from '../gql';

export const CreateWorkbenchElementsPaletteMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementsPalette(
    $input: [WorkbenchElementPaletteInput!]!
  ) {
    createWorkbenchElementsPalette(input: $input) {
      palettes {
        ...WorkbenchElementPaletteData
      }
    }
  }
`);

export const useCreateWorkbenchElementsPalette = () =>
  useMutation(CreateWorkbenchElementsPaletteMutation);
