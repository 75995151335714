import { useState } from 'react';

/**
 * Hook that allows for controlled or uncontrolled state management.
 * If controlledState is provided, it will be used, otherwise a local state will be created.
 *
 * @param initialValue - The initial value for the local state
 * @param controlledState - Optional tuple of [state, setState] to use instead of local state
 * @returns A tuple of [state, setState]
 */
export const useControlledState = <T>(
  initialValue: T,
  controlledState?: [T, React.Dispatch<React.SetStateAction<T>>]
) => {
  const localState = useState<T>(initialValue);
  return controlledState ?? localState;
};
