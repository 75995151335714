import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import originStyled, {
  ThemeProvider,
  DefaultTheme,
  ThemedStyledInterface,
  css,
  keyframes,
} from 'styled-components';

import { deviceThemeState, selectedThemeState } from './atoms';

export const BRAND_COLORS = {
  purple: {
    darker: '#23234D',
    dark: '#3535A7',
    default: '#4C4CEF',
    light: '#7070F2',
    lighter: '#B7B7F3',
  },
  black: {
    darker: '#161616',
    dark: '#242425',
    default: '#2F2F31',
    light: '#3C3C3E',
    lighter: '#464649',
  },
  gray: {
    darker: '#39393A',
    dark: '#808085',
    default: '#98989B',
    light: '#CBCBCD',
    lighter: '#FAFAFA',
  },
  red: {
    darker: '#79252C',
    dark: '#C23B46',
    default: '#F24A58',
    light: '#F46E79',
    lighter: '#FEEDEE',
  },
  green: {
    darker: '#054D2B',
    dark: '#088048',
    default: '#0BAA60',
    light: '#6DCCA0',
    lighter: '#B2E6CE',
  },
  yellow: {
    darker: '#6F5001',
    dark: '#CA950C',
    default: '#FDBA0F',
    light: '#FEE29A',
    lighter: '#FFF3D7',
  },
  orange: {
    darker: '#532107',
    dark: '#DB4200',
    default: '#FF5106',
  },
  blue: {
    darker: '#28285C',
    dark: '#343476',
    default: '#368CE4',
    light: '#7C7CF3',
  },
} as const;

export const defaultTheme: DefaultTheme = {
  text: {
    headings: BRAND_COLORS.gray.lighter,
    body: BRAND_COLORS.gray.lighter,
    bodyInverted: BRAND_COLORS.black.darker,
    bodyDisabled: BRAND_COLORS.gray.dark,
    subtext: BRAND_COLORS.gray.dark,
    subtextSecondary: BRAND_COLORS.blue.light,
    primary: BRAND_COLORS.gray.lighter,
    primaryDisabled: BRAND_COLORS.purple.default,
    secondary: BRAND_COLORS.gray.lighter,
    secondaryDisabled: BRAND_COLORS.gray.dark,
    tertiary: BRAND_COLORS.gray.lighter,
    tertiaryHover: BRAND_COLORS.gray.light,
    tertiaryDisabled: BRAND_COLORS.black.light,
    link: BRAND_COLORS.purple.light,
    linkHover: BRAND_COLORS.purple.lighter,
    success: BRAND_COLORS.green.dark,
    danger: '#601E17',
    dangerLink: BRAND_COLORS.red.default,
    warning: BRAND_COLORS.yellow.default,
    warningLink: BRAND_COLORS.yellow.dark,
    error: BRAND_COLORS.red.dark,
  },

  surface: {
    pageInverted: BRAND_COLORS.black.darker,
    page: BRAND_COLORS.gray.lighter,
    pagePattern: BRAND_COLORS.gray.light,
    primary: BRAND_COLORS.black.dark, // panels and toolbars
    secondary: BRAND_COLORS.black.default, // anything resting on a panel or toolbar, like the prompt box
    tertiary: BRAND_COLORS.black.light, // components on a secondary surface, like the active state of the Render/Refine mode selector
    modal: BRAND_COLORS.black.darker,
    warning: BRAND_COLORS.yellow.default,
    warningHover: BRAND_COLORS.yellow.dark,
    danger: BRAND_COLORS.red.default,
    dangerHover: BRAND_COLORS.red.dark,
    success: BRAND_COLORS.green.default,
    successHover: BRAND_COLORS.green.dark,
    palette: BRAND_COLORS.orange.default,
    paletteDisabled: BRAND_COLORS.orange.darker,
    layer: BRAND_COLORS.blue.dark,
    layerSecondary: BRAND_COLORS.blue.darker,
  },

  button: {
    primary: BRAND_COLORS.purple.default,
    primaryHover: BRAND_COLORS.purple.dark,
    primaryDisabled: BRAND_COLORS.purple.darker,
    secondary: BRAND_COLORS.black.dark,
    secondaryHover: BRAND_COLORS.black.light,
    secondaryDisabled: BRAND_COLORS.black.default,
    warning: BRAND_COLORS.yellow.default,
    warningHover: BRAND_COLORS.yellow.dark,
    warningDisabled: BRAND_COLORS.black.default,
    danger: BRAND_COLORS.red.default,
    dangerHover: BRAND_COLORS.red.dark,
    dangerDisabled: BRAND_COLORS.black.default,
    palette: BRAND_COLORS.orange.default,
    paletteHover: BRAND_COLORS.orange.dark,
    paletteDisabled: BRAND_COLORS.orange.darker,
  },

  border: {
    primary: BRAND_COLORS.gray.darker,
    secondary: BRAND_COLORS.gray.lighter,
    tertiary: BRAND_COLORS.gray.default,
    highlight: BRAND_COLORS.purple.light,
    action: BRAND_COLORS.purple.default,
    warning: BRAND_COLORS.yellow.default,
    danger: BRAND_COLORS.red.default,
    palette: BRAND_COLORS.gray.light,
    paletteActive: BRAND_COLORS.orange.default,
  },

  icon: {
    primary: BRAND_COLORS.gray.lighter,
    primaryInverted: BRAND_COLORS.black.darker,
    secondary: BRAND_COLORS.gray.dark,
    hover: BRAND_COLORS.gray.lighter,
    disabled: BRAND_COLORS.gray.darker,
    success: BRAND_COLORS.green.default,
    warning: BRAND_COLORS.yellow.default,
    error: BRAND_COLORS.red.default,
    action: BRAND_COLORS.purple.default,
    actionDisabled: BRAND_COLORS.purple.darker,
  },

  toast: {
    default: {
      background: BRAND_COLORS.black.default,
      ctaBackground: BRAND_COLORS.black.light,
      ctaColor: BRAND_COLORS.gray.lighter,
    },
    primary: {
      background: BRAND_COLORS.purple.default,
      ctaBackground: BRAND_COLORS.purple.light,
      ctaColor: BRAND_COLORS.gray.lighter,
    },
    warning: {
      background: BRAND_COLORS.yellow.default,
      ctaBackground: BRAND_COLORS.yellow.light,
      ctaColor: BRAND_COLORS.yellow.darker,
    },
    danger: {
      background: BRAND_COLORS.red.default,
      ctaBackground: BRAND_COLORS.red.light,
      ctaColor: BRAND_COLORS.gray.lighter,
    },
  },

  avatar: {
    lightPurple: '#B85EFF',
    pink: '#FF3E8D',
    sunset: '#FF724A',
    orange: '#FFA113',
    limeGreen: '#81BB37',
    lightBlue: '#00A1E7',
    red: '#D54214',
    brown: '#937047',
    teal: '#00A3AE',
    darkPurple: '#6F33AB',
  },

  '3d': {
    primary: '#a8e14b',
    secondary: '#344517',
    hover: '#769a3c',
    disabled: '#43581d',
  },

  azure: {
    primary: '#0078d4',
    hover: '#106ebe',
  },

  tooltip: {
    delay: { open: 700, close: 0 },
    padding: 12,
  },

  chip: {
    primary: {
      background: BRAND_COLORS.purple.darker,
      text: BRAND_COLORS.purple.light,
    },
    lightPrimary: {
      background: BRAND_COLORS.purple.lighter,
      text: BRAND_COLORS.purple.default,
    },
    danger: {
      background: BRAND_COLORS.red.default,
      text: BRAND_COLORS.gray.lighter,
    },
    default: {
      background: BRAND_COLORS.black.default,
      text: BRAND_COLORS.gray.dark,
    },
    warning: {
      background: BRAND_COLORS.yellow.default,
      text: BRAND_COLORS.gray.lighter,
    },
  },

  deprecated: {
    white: '#ffffff', // @deprecated
    black: '#000000', // @deprecated
    primary: {
      default: '#4C4CEF', // @deprecated
      hover: '#3535A7', // @deprecated
      disabled: '#23234D', // @deprecated
    },
    secondary: {
      default: '#242425', // @deprecated
      hover: '#39393A', // @deprecated
      disabled: '#39393A', // @deprecated
    },
    tertiary: {
      default: '#A8E14A', // @deprecated
      hover: '#A8E14A', // @deprecated
      disabled: '#4F7413', // @deprecated
    },
  },

  typography: {
    h1: css`
      font-size: 34px;
      font-weight: 500;
      line-height: 1.2;
    `,
    h2: css`
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2;
    `,
    h3: css`
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    `,
    h4: css`
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
    `,
    sh1: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    `,
    sh2: css`
      font-size: 12px;
      font-weight: 600;
      line-height: 12px /* => 1 */;
    `,
    b1: css`
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
    `,
    b2: css`
      font-size: 12px;
      font-weight: 400;
      line-height: 12px /* => 1 */;
    `,
    button1: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 14px /* => 1 */;
    `,
    button2: css`
      font-size: 12px;
      font-weight: 600;
      line-height: 12px /* => 1 */;
    `,
    button3: css`
      font-size: 10px;
      font-weight: 600;
      line-height: 10px /* => 1 */;
    `,
  },

  spacing: {
    s: '8px',
    m: '16px',
    l: '24px',
  },

  borderRadius: {
    xs: '2px',
    s: '4px',
    m: '8px',
    l: '16px',
    round: '1000px',
  },

  boxShadow: {
    level1:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 1px 0px rgba(180, 180, 180, 0.25) inset',
    level2:
      '0px 2px 4px 0px rgba(22, 22, 22, 0.15), 0px 1px 1px 0px rgba(250, 250, 250, 0.00) inset',
  },

  scrollbar: {
    light: css`
      ::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${({ theme }) => theme.borderRadius.s};
        background: ${({ theme }) => theme.icon.secondary};
      }
    `,

    dark: css`
      ::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${({ theme }) => theme.borderRadius.s};
        background: ${({ theme }) => theme.icon.disabled};
      }
    `,
  },

  animation: {
    duration: '150ms',
    slideInFromTop: keyframes`
      from { transform: translateY(calc(-100% - 1rem)); }
      to { transform: translateY(0); }`,
    slideInFromLeft: keyframes`
      from { transform: translateX(calc(-100% - 1rem)); }
      to { transform: translateX(0); }`,
    slideInFromRight: keyframes`
      from { transform: translateX(calc(100% + 14px)); }
      to { transform: translateX(0); }`,
    fadeIn: keyframes`
      from { opacity: 0; }
      to { opacity: 1; }`,
    backgroundFadeIn: keyframes`
      from {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
      }
      to {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(1px);
      }`,
    backgroundFadeOut: keyframes`
      from {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(1px);
      }
      to {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
      }`,
    pulseIn: keyframes`
      from { transform: scale(1); }
      to { transform: scale(1.05); }`,
    pulseOut: keyframes`
      from { transform: scale(1.05); }
      to { transform: scale(1); }`,
  },
};

const lightTheme = defaultTheme;
const darkTheme: DefaultTheme = {
  ...defaultTheme,
  // background: {
  //   base: '#0C0C0D',
  //   pattern: '#1E1E20',
  // },
};

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const VizcomThemeProvider = ({ children }: React.PropsWithChildren) => {
  const [deviceTheme, setDeviceTheme] = useRecoilState(deviceThemeState);
  const selectedTheme = useRecoilValue(selectedThemeState);

  const theme = useMemo(() => {
    if (selectedTheme === 'device') {
      return themes[deviceTheme];
    }
    return themes[selectedTheme];
  }, [deviceTheme, selectedTheme]);

  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const change = ({ matches }: MediaQueryListEvent) =>
      setDeviceTheme(matches ? 'dark' : 'light');
    prefersDark.addEventListener('change', change);
    return () => prefersDark.removeEventListener('change', change);
  }, [setDeviceTheme]);

  return <ThemeProvider children={children} theme={theme} />;
};

export const styled = originStyled as ThemedStyledInterface<DefaultTheme>;
