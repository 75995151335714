import { useMutation } from 'urql';

import { graphql } from '../../gql';

const UpdateOrganizationMutation = graphql(/* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...OrganizationFullData
      }
    }
  }
`);

export const useUpdateOrganization = () =>
  useMutation(UpdateOrganizationMutation);
