export const PUBLIC_RESOURCES_TEAM_ID = '983005af-87a0-43e6-ab1a-49b11d4cd647';

export const TEMPLATE_FOLDER_ID = 'bedbee08-c63e-4f38-bac4-658b2bcbcccc';

export const PALETTE_ONBOARDING_TEMPLATE = {
  id: 'fe3467f9-ffc7-4711-b6be-d0b43a0b55a8',
  name: '🎨 Palettes Basics',
};

export const WORKBENCH_TEMPLATES = [PALETTE_ONBOARDING_TEMPLATE];
