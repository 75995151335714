import type { CSSProperties } from 'react';
import styled from 'styled-components';

export const InlineFlex = styled.div<{
  $gap?: number;
  $alignItems?: CSSProperties['alignItems'];
  $justifyContent?: CSSProperties['justifyContent'];
}>`
  display: inline-flex;
  align-items: ${({ $alignItems }) => $alignItems || 'center'};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'normal'};
  gap: ${({ $gap }) => $gap || 5}px;
  width: 100%;
`;

export const BlockStack = styled.div<{
  $gap?: number;
  $justifyContent?: CSSProperties['justifyContent'];
  $alignItems?: CSSProperties['alignItems'];
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap ?? 5}px;
  justify-content: ${({ $justifyContent }) => $justifyContent || 'normal'};
  align-items: ${({ $alignItems }) => $alignItems || 'normal'};
`;
