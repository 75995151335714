/**
 * You should recreate those enums instead of importing them from the shared library to avoid attempting to import @vizcom/shared/data-access/graphql from the backend.
 */

export enum OrganizationSubscriptionPlan {
  Enterprise = 'ENTERPRISE',
  Free = 'FREE',
  Organization = 'ORGANIZATION',
  Pro = 'PRO',
  Edu = 'EDU',
}

export enum BillingInterval {
  Year = 'year',
  Month = 'month',
}
