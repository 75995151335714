import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const RemoveBackgroundEventTypeZodEnum = z.enum(['REMOVE_BACKGROUND_FEEDBACK']);

export const RemoveBackgroundEventName = RemoveBackgroundEventTypeZodEnum.Enum;

export const RemoveBackgroundTrackingEventSchema =
  BaseTrackingEventSchema.extend({
    type: RemoveBackgroundEventTypeZodEnum,
    data: z.object({
      drawingId: z.string(),
      feedback: z.enum(['approve', 'retry']),
    }),
  });
