import {
  Cache,
  cacheExchange,
  DataFields,
  ResolveInfo,
  Variables,
} from '@urql/exchange-graphcache';
import { genBottomOrderKey, genOrderKeys } from '@vizcom/shared/js-utils';

import { DrawingPromptsFragment } from '../fragments/drawingFragment';
import {
  CurrentUserDocument,
  DeleteTeamInput,
  DeleteUsersOnTeamInput,
} from '../gql/graphql';
import { layerMutationCacheEffectors } from '../mutations/layerCacheEffectors';
import { updateLastPaginatedPromptQuery } from '../mutations/updateLastPaginatedPrompt';
import { assetsLibraryMutationCacheEffectors } from '../mutations/workbench/assetLibraryCacheEffector';
import { workbenchMutationCacheEffectors } from '../mutations/workbench/workbenchMutationCacheEffectors';
import { currentUserQuery } from '../queries/currentUser';
import { setAccessToken } from './auth';
import { folderMutationCacheEffectors } from './folderMutationCacheEffectors';
import { handleWorkbenchSubscriptionMessage } from './workbenchCacheUpdater';

const invalidateRootQuery =
  (queryName: string | string[]) =>
  (_result: DataFields, args: Variables, cache: Cache, _info: ResolveInfo) => {
    cache
      .inspectFields('Query')
      .filter(({ fieldName }) => {
        if (typeof queryName === 'string') {
          return fieldName === queryName;
        } else {
          return queryName.includes(fieldName);
        }
      })
      .forEach((x) => {
        cache.invalidate('Query', x.fieldName, x.arguments);
      });
  };

export const gqlCache = cacheExchange({
  keys: {
    // a team subscription object doesn't have any id, it's always linked to its parent object (the team)
    TeamSubscription: () => null,
    WebConfigPayload: () => null,
    UserAccessLog: () => null,
    OrganizationSubscription: () => null,
    UsersOnTeam: () => null,
    OrganizationInvite: (organizationInvite) =>
      `${organizationInvite.organizationId}/${organizationInvite.email}`,
    UserClientState: (clientState) =>
      `${clientState.userId}/${clientState.key}`,
  },
  updates: {
    Mutation: {
      // invalidate all drawings queries
      // TODO: instead of invalidating, we can add the new drawing to the cache and save one request
      createDrawing: invalidateRootQuery('drawings'),
      deleteDrawing: invalidateRootQuery('drawings'),
      createOrganization: invalidateRootQuery(['organizations', 'currentUser']),
      deleteOrganization: invalidateRootQuery(['organizations', 'currentUser']),
      createManuallyManagedOrganization: invalidateRootQuery(['organizations']),
      subscribeToPlan: invalidateRootQuery(['previewSeatsChange']),
      acceptEducationPlanRequest: invalidateRootQuery([
        'educationPlanRequests',
        'getEducationOrganizations',
      ]),
      rejectEducationPlanRequest: invalidateRootQuery([
        'educationPlanRequests',
      ]),
      deleteUserOrganization: invalidateRootQuery([
        'organization',
        'currentUser',
      ]),
      acceptOrganizationInvite: invalidateRootQuery([
        'team',
        'organization',
        'currentUser',
      ]),
      createPrompt(res, args, cache, _info) {
        // Add prompt in drawing prompts list
        const drawingId = (res?.createPrompt as any)?.prompt?.drawingId as
          | string
          | undefined;
        if (!drawingId) {
          return;
        }

        updateLastPaginatedPromptQuery(drawingId, res, cache);

        // add prompt to drawing prompts list
        const cachedDrawing = cache.readFragment(DrawingPromptsFragment, {
          id: drawingId,
        } as any);

        if (cachedDrawing) {
          // cannot use cache.writeQuery here because it breaks when updating the collection just after having created
          // the actual drawing, instead using writeFragment
          cache.writeFragment(DrawingPromptsFragment, {
            ...cachedDrawing,
            prompts: {
              ...cachedDrawing.prompts,
              nodes: [
                (res.createPrompt as any)?.prompt,
                ...cachedDrawing.prompts.nodes,
              ],
            },
          });
        }
      },
      login(res, args, cache, _info) {
        if (!(res as any).login?.authToken) {
          return;
        }
        setAccessToken((res as any).login.authToken);
        // invalidate everything in the cache
        // this is useful in case some component already execute a graphql request that returned an empty response from the server
        // because the user was logged out. We need to clear the cache to make sure these queries are re-exected with the access token
        // and the server actually respond with the correct data
        cache.invalidate('Query');
        // Populate the currentUser query directly from the result of the login operation
        cache.updateQuery(
          {
            query: CurrentUserDocument,
          },
          () => {
            return {
              currentUser: (res as any).login.user,
            };
          }
        );
      },
      createUser(res, args, cache, _info) {
        if (!(res as any).createUser?.authToken) {
          return;
        }
        setAccessToken((res as any).createUser.authToken);
        cache.invalidate('Query');
        cache.updateQuery(
          {
            query: CurrentUserDocument,
          },
          () => {
            return {
              currentUser: (res as any).createUser.user,
            };
          }
        );
      },
      deleteUsersOnTeam(res, args, cache, _info) {
        // if quiting a team, invalidate previous teams query
        const currentUser = cache.readQuery({
          query: currentUserQuery,
        });

        if (
          (args.input as DeleteUsersOnTeamInput)?.userId ===
          currentUser?.currentUser?.id
        ) {
          invalidateRootQuery('organization')(res, args, cache, _info);
        }
      },
      deleteTeam(res, args, cache, _info) {
        const input = args.input as DeleteTeamInput;
        invalidateRootQuery('team')(res, { teamId: input?.id }, cache, _info);
      },
      ...folderMutationCacheEffectors,
      ...layerMutationCacheEffectors,
      ...workbenchMutationCacheEffectors,
      ...assetsLibraryMutationCacheEffectors,

      // handle the cache invalidation for insert & delete of palette source images
      insertImagesToPalette(res, args, cache, _info) {
        // TODO
      },
      removeImagesFromPalette(res, args, cache, _info) {
        // TODO
      },
      createChangelog: invalidateRootQuery('changelogs'),
      updateChangelog: invalidateRootQuery('changelogs'),
      deleteChangelog: invalidateRootQuery('changelogs'),
    },
    Subscription: {
      workbenchUpdates: handleWorkbenchSubscriptionMessage,
    },
  },
  resolvers: {
    Query: {
      // don't refetch the PromptOutput if already in the cache, for example from loading all the drawing data
      promptOutput: (_, args) => {
        return { __typename: 'PromptOutput', id: args.id };
      },
      folder: (_, args) => {
        return { __typename: 'Folder', id: args.id };
      },
      drawing: (_, args) => {
        return { __typename: 'Drawing', id: args.id };
      },
      prompt: (_, args) => {
        return { __typename: 'Prompt', id: args.id };
      },
      organization: (_, args) => {
        return { __typename: 'Organization', id: args.id };
      },
      team: (_, args) => {
        return { __typename: 'Team', id: args.id };
      },
      workbench: (_, args) => {
        return { __typename: 'Workbench', id: args.id };
      },
    },
    Layer: {
      // If the layer doesn't have an orderKey, check the drawing's legacy layersOrder
      // and generate an orderKey based on the layer's index in the layersOrder
      orderKey: (parent, _args, cache) => {
        const layersOrder = cache.resolve(
          {
            __typename: 'Drawing',
            id: cache.resolve(parent as any, 'drawingId') as string,
          },
          'layersOrder'
        ) as string[] | undefined;

        if (parent.orderKey || !layersOrder) {
          return parent.orderKey;
        }

        const legacyOrder = genOrderKeys(layersOrder.length).reverse();

        return (
          legacyOrder[layersOrder.indexOf(parent.id as string)] ||
          genBottomOrderKey(
            legacyOrder.map((orderKey, i) => ({ orderKey, id: layersOrder[i] }))
          )
        );
      },
    },
    PromptOutput: {
      // From promptId in the promptOutput, reference the parent prompt
      // This prevent a network call for promptOutput if the cached has promptId and prompt is aready in cache
      prompt: (parent, _args, cache) => {
        return {
          __typename: 'Prompt',
          id: cache.resolve(parent as any, 'promptId') as string,
        };
      },
    },
  },
});
