import React, { forwardRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  Button,
  NarrowCarretIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToolbarButton,
  ToolbarButtonProps,
  ToolbarButtonState,
  TooltipOptions,
} from '@vizcom/shared-ui-components';
import { useControlledState } from '@vizcom/shared-utils-hooks';

type MenuButtonProps = ToolbarButtonProps & {
  menu?: React.ReactNode;
  menuOptions?: TooltipOptions;
  menuStyle?: React.CSSProperties;
  menuTooltip?: React.ReactNode;
  menuTooltipOptions?: TooltipOptions;
  showMenuIcon?: boolean;
  disabledWhenInactive?: boolean;
  controlledMenuState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  disabled?: boolean;
};

interface MenuIconProps {
  tooltip: React.ReactNode;
  tooltipOptions?: TooltipOptions;
  onClick?: (e: React.MouseEvent) => void;
}

export const MenuIcon = forwardRef<HTMLDivElement, MenuIconProps>(
  ({ onClick }, ref) => (
    <CarrotWrapper
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      ref={ref}
    >
      <NarrowCarretIcon />
    </CarrotWrapper>
  )
);

export const ToolbarMenuButton = React.forwardRef<HTMLElement, MenuButtonProps>(
  (
    {
      state = ToolbarButtonState.INACTIVE,
      icon,
      tooltip,
      tooltipOptions,
      menu,
      menuOptions,
      menuTooltip,
      menuTooltipOptions,
      showMenuIcon = false,
      disabledWhenInactive = false,
      menuStyle,
      onClick,
      controlledMenuState,
      disabled,
      buttonProps,
    },
    ref
  ) => {
    const theme = useTheme();
    const forceMenuOpen = menuOptions?.manualOpen;
    const isTooltipOpenState = useState(false);
    const [isOpen, setIsOpen] = useControlledState(
      forceMenuOpen ?? false,
      controlledMenuState
    );

    const handleMainButtonClick = (e: React.MouseEvent) => {
      onClick?.(e);
      if (isTooltipOpenState[0]) isTooltipOpenState[1](false);
      if (showMenuIcon && isOpen) setIsOpen(false);
    };

    return (
      <CanBeDisabled disabled={disabled}>
        <RichTooltip
          trigger="click"
          onOpenChange={(open) => {
            if (!open) {
              setIsOpen(false);
            } else if (!showMenuIcon) {
              setIsOpen(open);
            }
          }}
          disabled={
            disabledWhenInactive && state === ToolbarButtonState.INACTIVE
          }
          isOpen={isOpen}
          displayArrow={false}
          placement="bottom"
          {...theme.tooltip}
          {...menuOptions}
        >
          <RichTooltipTrigger ref={ref}>
            <ToolbarButtonWrapper
              $isMenuOpen={isOpen}
              showMenuIcon={showMenuIcon}
            >
              <ToolbarButton
                icon={icon}
                tooltip={tooltip}
                state={state}
                onClick={handleMainButtonClick}
                tooltipOptions={{
                  ...tooltipOptions,
                  trigger:
                    state === ToolbarButtonState.ACTIVE ? 'none' : 'hover',
                  controlledOpenState: isTooltipOpenState,
                }}
                buttonProps={buttonProps}
              >
                {showMenuIcon ? (
                  <MenuIcon
                    tooltip={menuTooltip}
                    tooltipOptions={menuTooltipOptions}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                ) : null}
              </ToolbarButton>
            </ToolbarButtonWrapper>
          </RichTooltipTrigger>
          <MenuContent style={menuStyle}>{menu}</MenuContent>
        </RichTooltip>
      </CanBeDisabled>
    );
  }
);

const CanBeDisabled = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const CarrotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 32px;

  svg {
    transition: transform 0.25s;
  }
`;

const ToolbarButtonWrapper = styled.div<{
  $isMenuOpen?: boolean;
  showMenuIcon?: boolean;
}>`
  ${({ showMenuIcon }) =>
    showMenuIcon &&
    css`
      ${Button} {
        width: auto;
        gap: 8px;
        padding-right: 0;
      }
    `}

  ${Button}:not(:disabled) {
    ${CarrotWrapper}:hover svg {
      transform: translateY(3px);
    }

    ${CarrotWrapper} svg {
      transform: translateY(
        ${({ $isMenuOpen }) => ($isMenuOpen ? '3px' : '0')}
      );
    }
  }
`;

const MenuContent = styled(RichTooltipContent)`
  color: ${({ theme }) => theme.text.body};

  display: flex;
  flex-direction: column;
  gap: 4px;

  border-radius: ${({ theme }) => theme.borderRadius.l};
`;
