import styled from 'styled-components';
import { OrganizationBasic } from '@vizcom/shared/data-access/graphql';

import { getColorFromHash } from './UserAvatar/UserAvatar';
import { useStringHashNumber } from './hooks/useStringHashNumber';

interface OrganizationAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  organization: Pick<OrganizationBasic, 'id' | 'logoPath' | 'name'>;
  size?: 'small' | 'medium' | 'large';
}

export const OrganizationAvatar = ({
  organization,
  size,
  ...rest
}: OrganizationAvatarProps) => {
  const hash = useStringHashNumber(organization.id);

  return (
    <Container
      $teamHash={organization.logoPath ? null : hash || null}
      $size={size ?? 'medium'}
      {...rest}
    >
      {organization.logoPath ? (
        <img src={organization.logoPath} alt={organization.name} />
      ) : (
        organization.name[0]
      )}
    </Container>
  );
};

const Container = styled.div<{
  $teamHash: number | null;
  $size: 'small' | 'medium' | 'large';
}>`
  height: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  width: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.surface.primary};
  background-color: ${({ theme, $teamHash }) =>
    $teamHash === null ? 'transparent' : getColorFromHash(theme, $teamHash)};
  text-transform: uppercase;
  border-radius: ${(p) =>
    p.$size === 'large' ? 5 : p.$size === 'medium' ? 6 : 2}px;
  font-size: ${(p) =>
    p.$size === 'large' ? 20 : p.$size === 'medium' ? 15 : 9}px;
  flex-shrink: 0;

  img {
    object-fit: cover;
    height: 100%;
  }
`;
