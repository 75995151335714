import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ReactNode } from 'react';

import { SortableItem } from './SortableItem';
import { BaseItem, List, PlaceHolder } from './SortableList';

type Props<T extends BaseItem> = {
  id: UniqueIdentifier;
  item: T;
  depth: number;
  draggable: boolean;
  parentId?: UniqueIdentifier;
  overGroupId: UniqueIdentifier | null;
  getItems: (id?: UniqueIdentifier) => T[];
  getTotalNestedChildrenCount: (id: UniqueIdentifier) => number;
  renderContainer: (
    item: T,
    depth: number,
    index: number,
    childCount: number,
    innerContent: ReactNode,
    isOver: boolean
  ) => ReactNode;
  renderItem: (
    item: T,
    depth: number,
    isLast: boolean,
    nextLayerIsGroup: boolean
  ) => ReactNode;
};

export function SortableContainer<T extends BaseItem>({
  id,
  item,
  depth,
  draggable,
  overGroupId,
  renderContainer,
  getTotalNestedChildrenCount,
  renderItem,
  getItems,
}: Props<T>) {
  const items = getItems(id);
  const itemIds = items.map((item) => item.id);
  const { setNodeRef } = useDroppable({
    id,
    data: {
      container: true,
    },
  });

  const content = item.collapsed ? null : (
    <div
      style={{
        width: '100%',
      }}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        <List>
          {items.map((item, i) => {
            if (item.id === 'placeholder') {
              return <PlaceHolder key="placeholder" $depth={depth + 1} />;
            }

            if (item.isGroup) {
              return (
                <SortableContainer<T>
                  key={item.id}
                  id={item.id}
                  depth={depth + 1}
                  item={item}
                  parentId={id}
                  overGroupId={overGroupId}
                  getItems={getItems}
                  renderItem={renderItem}
                  renderContainer={renderContainer}
                  getTotalNestedChildrenCount={getTotalNestedChildrenCount}
                  draggable={draggable}
                />
              );
            }

            return (
              <SortableItem key={item.id} id={item.id} draggable={draggable}>
                {renderItem(
                  item,
                  depth + 1,
                  i === items.length - 1,
                  items[i + 1]?.isGroup
                )}
              </SortableItem>
            );
          })}
        </List>
      </SortableContext>
    </div>
  );

  return (
    <SortableItem id={id} draggable={draggable}>
      <div
        ref={setNodeRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div>
          {renderContainer(
            item,
            depth,
            0,
            getTotalNestedChildrenCount(id),
            content,
            overGroupId === id
          )}
        </div>
      </div>
    </SortableItem>
  );
}
