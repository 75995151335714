import styled from 'styled-components';
import { ChangelogData } from '@vizcom/shared/data-access/graphql';
import {
  FormattedDate,
  VizcomLogoFilled,
  VizcomText,
} from '@vizcom/shared-ui-components';

import { LoadingLogoInset } from '../Logo';
import { Markdown } from '../Markdown/Markdown';
import { Modal } from '../Modal/Modal';
import {
  ModalHeader,
  ModalDivider,
  ModalCloseButton,
  ModalContent,
} from '../Modal/ModalItems';
import { Text } from '../Text/Text';

export const ChangelogModal = (props: {
  changelogs: ChangelogData[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { changelogs } = props;

  if (!props.isOpen) {
    return null;
  }

  if (!props.changelogs) {
    return (
      <Modal
        isOpen={props.isOpen}
        setIsOpen={() => {}}
        style={{
          width: 600,
        }}
      >
        <ModalContent>
          <LoadingLogoInset />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={props.onClose}
      style={{
        width: 600,
        maxHeight: 700,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }}
    >
      <ModalHeader>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 32,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <VizcomLogoFilled height={16} width={16} />
              <VizcomText height={12} width={'100%'} />
            </div>
            <ModalCloseButton />
          </div>
          <Text type="h2" style={{ fontSize: '24px' }}>
            🚀 Changelog
          </Text>
          <Text
            block
            color="subtext"
            type="b1"
            style={{ marginTop: 16, fontSize: '16px' }}
          >
            Everything you've missed since your last visit.
          </Text>
        </div>
      </ModalHeader>
      <ModalDivider />
      <ModalContent style={{ padding: '24px 32px' }}>
        {changelogs.map((changelog, index) => (
          <div key={changelog.id}>
            <div style={{ marginBottom: 20 }}>
              <Text
                type="h3"
                block
                style={{ marginBottom: 12, fontSize: '20px' }}
              >
                {changelog.title}
              </Text>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <Text type="sh2" style={{ color: 'var(--text-secondary)' }}>
                  <FormattedDate date={changelog.publishedAt!} />
                </Text>
                {index === 0 && (
                  <NewLabel>
                    <Text color="primary" type="button3">
                      New
                    </Text>
                  </NewLabel>
                )}
              </div>
            </div>
            <div style={{ fontSize: '15px', lineHeight: '1.5' }}>
              <Markdown>{changelog.content}</Markdown>
            </div>

            {index !== changelogs.length - 1 && (
              <ModalDivider style={{ margin: '32px 0px' }} />
            )}
          </div>
        ))}
      </ModalContent>
    </Modal>
  );
};

const NewLabel = styled.div`
  margin-top: 8px;
  padding: 4px 8px;
  display: inline-block;
  background: ${({ theme }) => theme.button.primary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;
