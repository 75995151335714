import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/data-access/graphql';
import {
  BlockStack,
  Button,
  Chip,
  FullPageDarkLoader,
  FullscreenModal,
  RadioButton,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { BenefitItem } from './BenefitItem';
import upgradeSneaker from './assets/upgrade_sneaker.png';

type UpgradeUrlBuilder = (period: 'monthly' | 'annual') => {
  url: string;
  target: '_blank' | '_self';
};

export const FreeToProModal = ({
  isOpen,
  onClose,
  upgradeUrlBuilder,
}: {
  isOpen: boolean;
  onClose: () => void;
  upgradeUrlBuilder: UpgradeUrlBuilder;
}) => {
  return (
    <FullscreenModal
      title="Create more with Professionnal"
      description="Get access to premium features"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ContentWrapper>
        <SneakerImg />
        <BenefitsWrapper>
          <BenefitItem
            title="Generate more and create faster"
            description="Access to priority rendering up to 4 generations at a time"
          />
          <BenefitItem
            title="Extend you workflows with 3D generation"
            description="Create high-quality 3D models and download as many as you need"
          />
          <BenefitItem
            title="Produce higher-quality images"
            description="Upscale your image exports to 4K resolution"
          />
          <BenefitItem
            title="Keep your designs entirely private"
            description="Professional plans benefit from full design privacy"
          />
          <PeriodPicker upgradeUrlBuilder={upgradeUrlBuilder} />
        </BenefitsWrapper>
      </ContentWrapper>
    </FullscreenModal>
  );
};

export const ConnectedFreeToProModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: organization, loading } = useSelectedOrganization();
  const isInWorkbench = useLocation().pathname.startsWith('/workbench/');

  const upgradeUrlBuilder = useCallback<UpgradeUrlBuilder>(
    (period: 'monthly' | 'annual') => ({
      url: paths.settings.organization.subscriptionUpdate(
        organization!.id,
        OrganizationSubscriptionPlan.Pro,
        period === 'monthly' ? 'month' : 'year'
      ),
      // If we are in a workbench, we open the link in a new tab
      // to avoid disrupting the work of the user
      target: isInWorkbench ? '_blank' : '_self',
    }),
    [organization, isInWorkbench]
  );

  if (loading) {
    return <FullPageDarkLoader />;
  }

  return (
    <FreeToProModal
      isOpen={isOpen}
      onClose={onClose}
      upgradeUrlBuilder={upgradeUrlBuilder}
    />
  );
};

const PeriodPicker = ({
  upgradeUrlBuilder,
}: {
  upgradeUrlBuilder: UpgradeUrlBuilder;
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<'monthly' | 'annual'>(
    'annual'
  );
  const { url, target } = useMemo(
    () => upgradeUrlBuilder(selectedPeriod),
    [upgradeUrlBuilder, selectedPeriod]
  );

  return (
    <PeriodPickerWrapper>
      <Period
        title="Monthly"
        pricing="$49 /month"
        selected={selectedPeriod === 'monthly'}
        onSelect={() => setSelectedPeriod('monthly')}
      />
      <Period
        title={
          <>
            Annual <SmallChip variant="lightPrimary">-20%</SmallChip>
          </>
        }
        pricing="$40 /month"
        details="Billed annually"
        selected={selectedPeriod === 'annual'}
        onSelect={() => setSelectedPeriod('annual')}
      />
      <Link to={url} target={target}>
        <Button variant="primary" $fullWidth>
          Upgrade to Professional
        </Button>
      </Link>
    </PeriodPickerWrapper>
  );
};

const Period = ({
  title,
  pricing,
  details,
  selected,
  onSelect,
}: {
  title: ReactNode;
  pricing: string;
  details?: string;
  selected: boolean;
  onSelect: () => void;
}) => {
  return (
    <PeriodWrapper onClick={onSelect} $selected={selected}>
      <BlockStack $gap={12}>
        <Text type="sh2">{title}</Text>
        <Text type="sh2">{pricing}</Text>
        {details && (
          <Text type="sh2" color="subtext">
            {details}
          </Text>
        )}
      </BlockStack>
      <PeriodRadioButton checked={selected} readOnly />
    </PeriodWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 100%;
`;

const SneakerImg = styled.div`
  flex: 1 0;
  margin-top: -55px;
  background-image: url(${upgradeSneaker});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  gap: 32px;
`;

const PeriodPickerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 28px;
  width: 0;
  padding: 18px 38px 0;

  > :nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  > :nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  > :nth-child(3) {
    grid-area: 2 / 1 / 3 / 3;
  }
`;

const SmallChip = styled(Chip)`
  padding: 4px;

  ${({ theme }) => theme.typography.button3};
`;

const PeriodWrapper = styled.div<{ $selected: boolean }>`
  position: relative;
  width: 162px;
  padding: 18px;

  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background-color: ${({ theme }) => theme.surface.page};

  color: ${({ theme }) => theme.surface.tertiary};

  outline: ${({ theme, $selected }) =>
    $selected ? `2px solid ${theme.border.action}` : 'none'};
  outline-offset: 3px;
`;

const PeriodRadioButton = styled(RadioButton)`
  position: absolute;
  top: 14px;
  right: 14px;
`;
