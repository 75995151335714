import { base62 } from 'mudder';

export type Sortable = {
  id: string;
  orderKey: string;
  parentId?: string | null;
};

export const sortByOrderKey = <T extends Sortable>(arr: T[]) => {
  const sorted = [...arr].sort((a, b) => (a.orderKey > b.orderKey ? 1 : -1));

  const result: T[] = [];

  const insertItemWithChildren = (item: T) => {
    result.push(item);
    const children = sorted.filter((child) => child.parentId === item.id);
    children.forEach(insertItemWithChildren);
  };

  sorted.forEach((item) => {
    if (!item.parentId) {
      insertItemWithChildren(item);
    }
  });

  return result;
};

export const sortSingleDepthByOrderKey = <T extends Sortable>(arr: T[]) => {
  return [...arr].sort((a, b) => (a.orderKey > b.orderKey ? 1 : -1));
};

export const genTopOrderKey = <T extends Sortable>(
  arr: T[],
  parentId?: string | null
) => {
  if (arr.length === 0) {
    return base62.mudder()[0];
  }

  const sorted = sortByOrderKey(arr);
  const parent = sorted.find((layer) => layer.id === parentId);
  let siblings: T[] = [];

  if (!parentId || !parent) {
    siblings = sorted.filter((layer) => !layer.parentId);
  } else {
    siblings = sorted.filter((layer) => layer.parentId === parentId);
  }

  if (siblings.length === 0) {
    return base62.mudder()[0];
  }

  return base62.mudder(undefined, siblings[0].orderKey)[0];
};

export const genBottomOrderKey = <T extends Sortable>(
  arr: T[],
  parentId?: string | null
) => {
  if (arr.length === 0) {
    return base62.mudder()[0];
  }

  const sorted = sortByOrderKey(arr);
  const parent = sorted.find((layer) => layer.id === parentId);
  let siblings: T[] = [];

  if (!parentId || !parent) {
    siblings = sorted.filter((layer) => !layer.parentId);
  } else {
    siblings = sorted.filter((layer) => layer.parentId === parentId);
  }

  if (siblings.length === 0) {
    return base62.mudder()[0];
  }

  return base62.mudder(siblings[siblings.length - 1].orderKey)[0];
};

export const genOrderKeys = (length: number) => {
  return base62.mudder(length);
};

export const getLayerOrderKeys = (
  layers: Sortable[],
  anchorId: string | null = null,
  count: number = 1
) => {
  if (layers.length === 0) {
    return base62.mudder();
  }

  let sorted = sortByOrderKey(layers);

  if (anchorId) {
    const anchorDepth = layers.find((layer) => layer.id === anchorId)?.parentId;
    sorted = sorted.filter((layer) => layer.parentId === anchorDepth || null);
  }

  if (sorted.length === 0) {
    return base62.mudder();
  }

  let insertIndex;
  if (anchorId === null) {
    insertIndex = 0;
  } else {
    insertIndex = sorted.findIndex((layer) => layer.id === anchorId);
  }

  let previousOrder, nextOrder;
  if (insertIndex < 1) {
    nextOrder = sorted[0].orderKey;
  } else if (insertIndex >= sorted.length) {
    previousOrder = sorted[sorted.length - 1].orderKey;
  } else {
    previousOrder = sorted[insertIndex].orderKey;
    nextOrder =
      insertIndex < sorted.length
        ? sorted[insertIndex - 1].orderKey
        : undefined;
  }

  return base62.mudder(previousOrder, nextOrder, count);
};
