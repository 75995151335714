import { useSubscription } from 'urql';

import { graphql } from '../gql';

export const drawingUpdatesSubscription = graphql(/* GraphQL */ `
  subscription drawingUpdates($input: DrawingUpdatesInput!) {
    drawingUpdates(input: $input) {
      id
      prompt {
        ...PromptData
      }
      upscale {
        ...UpscaleData
      }
      layer {
        ...LayerData
      }
      drawing {
        ...DrawingData
      }
    }
  }
`);

export const useDrawingUpdates = (drawingId: string | undefined) => {
  const [res] = useSubscription({
    query: drawingUpdatesSubscription,
    variables: {
      input: {
        drawingId: drawingId!,
      },
    },
    pause: !drawingId,
  });
  return res;
};
