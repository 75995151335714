import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

export default [
  BaseTrackingEventSchema.extend({
    type: z.enum(['PALETTE_RATED']),
    data: z.object({
      paletteId: z.string(),
      feedback: z.enum(['positive', 'negative']),
    }),
  }),
  BaseTrackingEventSchema.extend({
    type: z.enum(['PALETTE_FEEDBACK_SUBMITTED']),
    data: z.object({
      paletteId: z.string(),
      feedback: z.enum(['positive', 'negative']),
      feedbackText: z.string().optional(),
    }),
  }),
  BaseTrackingEventSchema.extend({
    type: z.enum([
      'ADD_IMAGES',
      'REMOVE_IMAGES',
      'TRAIN',
      'CANCEL_TRAINING',
      'PUBLISH_PALETTE_TO_PERSONAL_LIBRARY',
      'REMOVE_PUBLISHED_PALETTE_FROM_PERSONAL_LIBRARY',
      'EDIT_PUBLISHED_PALETTE',
    ]),
    data: z.object({
      paletteId: z.string(),
      imagesCount: z.number().optional(),
    }),
  }),
];
