import { useMutation } from 'urql';

import { graphql } from '../gql';

export const UpdateChangelogMutation = graphql(/* GraphQL */ `
  mutation UpdateChangelog($id: UUID!, $patch: ChangelogPatch!) {
    updateChangelog(input: { id: $id, patch: $patch }) {
      changelog {
        ...ChangelogData
      }
    }
  }
`);

export const useUpdateChangelog = () => useMutation(UpdateChangelogMutation);
