import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  OrganizationData,
  OrganizationSubscriptionData,
} from '@vizcom/shared/data-access/graphql';
import { Button, FormattedDate, Text } from '@vizcom/shared-ui-components';

import { EnterprisePlanPricing } from './EnterprisePlanPricing';
import { ProPlanPricing } from './ProPlanPricing';
import { StarterPlanPricing } from './StarterPlanPricing';

export const OrganizationPlansTable = (props: {
  organization: OrganizationData;
  subscription: OrganizationSubscriptionData;
}) => {
  const { organization, subscription } = props;

  return (
    <PlansContainer>
      <StarterPlanPricing
        organization={organization}
        subscriptionData={subscription}
      />

      <ProPlanPricing
        organization={organization}
        subscriptionData={subscription}
      />

      <EnterprisePlanPricing
        organization={organization}
        subscriptionData={subscription}
      />
    </PlansContainer>
  );
};

export const ContactSalesButton = () => (
  <Button
    variant="primary"
    as={Link}
    to="https://www.vizcom.ai/contact"
    target="_blank"
    size="M"
  >
    Contact sales
  </Button>
);

export const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
`;

export const PocExpirationMessage = ({
  poc,
  pocEndDate,
  expired,
}: {
  expired: boolean;
  poc?: boolean;
  pocEndDate?: string | null;
}) => {
  if (!poc || !pocEndDate) return null;

  return (
    <Text block color={expired ? 'error' : 'warning'} type="sh2">
      {expired ? 'Expired' : 'Expires'} <FormattedDate date={pocEndDate} />
    </Text>
  );
};

export const PocChip = ({ expired }: { expired: boolean }) => {
  const theme = useTheme();

  return (
    <Text
      style={{
        backgroundColor: expired
          ? theme.chip.danger.background
          : theme.chip.warning.background,
        borderRadius: theme.borderRadius.s,
        padding: '3px 5px',
      }}
    >
      POC
    </Text>
  );
};

export const PlanContainer = styled.div<{ $active: boolean }>`
  display: grid;
  grid-template-rows: 230px min-content auto;
  background-color: ${(p) =>
    p.$active ? p.theme.button.secondary : 'transparent'};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 24px;
  border: 1px solid ${(p) => p.theme.border.primary};
`;

export const PlanPriceText = styled.div`
  font-size: 28px;
  color: ${(p) => p.theme.text.body};
`;

export const PlanCta = styled.div`
  & > * {
    width: 100%;
  }
`;
