import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { DragHandleIcon } from '@vizcom/shared-ui-components';

interface Props {
  id: UniqueIdentifier;
  draggable?: boolean;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export function SortableItem({
  children,
  id,
  draggable,
}: PropsWithChildren<Props>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transition,
    isDragging,
  } = useSortable({
    id,
    animateLayoutChanges: () => true,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <SortableListItem
        ref={setNodeRef}
        style={style}
        {...(draggable ? listeners : {})}
      >
        {children}
      </SortableListItem>
    </SortableItemContext.Provider>
  );
}

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <StyledDragHandle {...attributes} {...listeners} ref={ref}>
      <DragHandleIcon $size="S" />
    </StyledDragHandle>
  );
}

const SortableListItem = styled.li`
  display: flex;
`;

const StyledDragHandle = styled.button`
  display: flex;
  width: 12px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: manipulation;
  cursor: var(--cursor, pointer);
  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:focus-visible {
    box-shadow: 0 0px 0px 2px ${({ theme }) => theme.border.action};
  }

  & svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: ${({ theme }) => theme.icon.secondary};
  }
`;
