import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Returns a ref that is updated with the latest value
 */
export function useLastValue<T>(value: T) {
  const ref = useRef<T>();
  ref.current = value;
  return ref as MutableRefObject<T>;
}

/**
 * Returns the prior (stale) value of a given value
 */
export const usePreviousValue = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
