import { FunctionComponent, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { CarretDownIcon, CheckIcon } from '../icons';

interface DropdownOption<T> {
  data: T;
  children?: DropdownOption<T>[];
}

interface DropdownProps<T> {
  value: string;
  setValue: (val: string) => void;
  options: DropdownOption<T>[];
  OptionComponent: FunctionComponent<{ option: T; selected: boolean }>;
  optionToValueMapper: (option: T) => string;
  buttonProps?: React.ComponentProps<typeof Button>;
  onOpenStateChange?: (open: boolean) => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

/**
 * @deprecated This component is deprecated and will be removed in favor of `Select`.
 * See Select Component: libs/shared/ui/components/src/lib/Select/index.tsx
 * Usage: FileSharingSelectComponent.tsx
 * Linear migration ticket: https://linear.app/vizcom/issue/VIZ-1309/use-the-new-select-component-for-current-selects-and-dropdowns
 */
export function Dropdown<T>(props: PropsWithChildren<DropdownProps<T>>) {
  const {
    value,
    setValue,
    options,
    OptionComponent,
    optionToValueMapper,
    disabled = false,
  } = props;

  // State to track if the dropdown is open
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the state when dropdown is opened/closed
  const handleOpenStateChange = (open: boolean) => {
    setIsOpen(open);
    if (props.onOpenStateChange) {
      props.onOpenStateChange(open);
    }
  };

  const items = (
    <div style={{ padding: '0 4px' }}>
      {options.map((option) => (
        <DropdownOptionWrapper
          value={value}
          setValue={setValue}
          option={option}
          OptionComponent={OptionComponent}
          optionToValueMapper={optionToValueMapper}
          key={optionToValueMapper(option.data)}
          variant={props.variant}
        />
      ))}
    </div>
  );

  return (
    <ContextMenu
      style={{
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
      onOpenStateChange={handleOpenStateChange}
      matchButtonWidth
      items={items}
      buttonProps={{
        disabled: disabled,
        variant: props.variant || 'secondary',
        ...props.buttonProps,
        style: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 8,
          ...props.buttonProps?.['style'],
        },
      }}
    >
      {props.children}
      <CarretDownIcon
        style={{
          transform: isOpen ? 'rotate(180deg)' : 'none',
          transition: 'transform 0.2s',
        }}
      />
    </ContextMenu>
  );
}

interface DropdownOptionWrapperProps<T> {
  value: string;
  setValue: (val: string) => void;
  option: DropdownOption<T>;
  OptionComponent: FunctionComponent<{ option: T; selected: boolean }>;
  optionToValueMapper: (option: T) => string;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

function DropdownOptionWrapper<T>(props: DropdownOptionWrapperProps<T>) {
  const { value, setValue, option, optionToValueMapper, OptionComponent } =
    props;

  const optionValue = optionToValueMapper(option.data);
  const selected = optionValue === value;
  return (
    <DropdownOptionWrapperContainer onClick={() => setValue(optionValue)}>
      <SelectedIndicator $active={selected}>
        <CheckIcon $color="action" />
      </SelectedIndicator>
      <OptionComponent option={option.data} selected={selected} />
    </DropdownOptionWrapperContainer>
  );
}

const DropdownOptionWrapperContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.surface.tertiary};
  }
`;

export const SelectedIndicator = styled.div<{
  $active: boolean;
}>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
`;
