import { useMutation } from 'urql';

import { graphql } from '../gql';

const UpdateTeamMutation = graphql(/* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        ...TeamData
      }
    }
  }
`);

export const useUpdateTeam = () => useMutation(UpdateTeamMutation);
