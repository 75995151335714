import { useMutation } from 'urql';

import { graphql } from '../../gql';

const CreateOrganizationMutation = graphql(/* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization {
        ...OrganizationFullData
      }
    }
  }
`);

export const useCreateOrganization = () =>
  useMutation(CreateOrganizationMutation);

const CreateManuallyManagedOrganizationMutation = graphql(/* GraphQL */ `
  mutation CreateManuallyManagedOrganization(
    $input: CreateManuallyManagedOrganizationInput!
  ) {
    createManuallyManagedOrganization(input: $input) {
      organizationId
    }
  }
`);

export const useCreateManuallyManagedOrganization = () =>
  useMutation(CreateManuallyManagedOrganizationMutation);
