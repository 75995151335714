import { useMutation } from 'urql';

import { graphql } from '../gql';

export const DeleteChangelogMutation = graphql(/* GraphQL */ `
  mutation DeleteChangelog($id: UUID!) {
    deleteChangelog(input: { id: $id }) {
      deletedChangelogNodeId
    }
  }
`);

export const useDeleteChangelog = () => useMutation(DeleteChangelogMutation);
