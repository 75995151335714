import { useMutation, useQuery } from 'urql';

import { graphql } from '../../gql';
import { PreviewSeatsChangeInput } from '../../gql/graphql';

const CreateOrganizationSubscriptionManagementPortal = graphql(/* GraphQL */ `
  mutation CreateOrganizationSubscriptionManagementPortal(
    $input: CreateOrganizationSubscriptionManagementPortalInput!
  ) {
    createOrganizationSubscriptionManagementPortal(input: $input) {
      url
    }
  }
`);

export const useCreateOrganizationSubscriptionManagementPortal = () =>
  useMutation(CreateOrganizationSubscriptionManagementPortal);

const SubscribeToPlan = graphql(/* GraphQL */ `
  mutation SubscribeToPlan($input: SubscribeToPlanInput!) {
    subscribeToPlan(input: $input) {
      url
      organization {
        ...OrganizationSeatsUsageData
        ...OrganizationSubscriptionData
      }
    }
  }
`);

export const useSubscribeToPlan = () => useMutation(SubscribeToPlan);

const PreviewSeatsChange = graphql(/* GraphQL */ `
  query PreviewSeatsChange($input: PreviewSeatsChangeInput!) {
    previewSeatsChange(input: $input) {
      subtotalInCents
      amountRemainingInCents
      startingBalanceInCents
    }
  }
`);

export const usePreviewSeatsChange = ({
  organizationId,
  paidSeatsCount,
  interval,
  plan,
}: PreviewSeatsChangeInput) => {
  const [res] = useQuery({
    query: PreviewSeatsChange,
    variables: {
      input: {
        organizationId,
        paidSeatsCount,
        interval,
        plan,
      },
    },
    pause: !organizationId || !paidSeatsCount,
  });

  return { ...res, data: res.data?.previewSeatsChange };
};
