import styled from 'styled-components';

import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { UpgradeIcon } from '../icons';

interface UpgradeWidgetProps {
  variant?: 'row' | 'column';
  title: string;
  description?: string;
  cta: { text: string; action: () => void };
  $width?: string;
}

export const UpgradeWidget = ({
  variant = 'row',
  title,
  description,
  cta,
  $width = '100%',
}: UpgradeWidgetProps) => {
  return (
    <Gradient variant={variant} $width={$width}>
      <Wrapper variant={variant}>
        <UpgradeIcon />
        <TextWrapper variant={variant}>
          <Text type="sh1">{title}</Text>
          {description && (
            <Text type="b1" color="subtext">
              {description}
            </Text>
          )}
        </TextWrapper>
        <Button
          variant="primary"
          onClick={cta.action}
          style={{ marginLeft: variant === 'column' ? 0 : 'auto' }}
        >
          {cta.text}
        </Button>
      </Wrapper>
    </Gradient>
  );
};

const Gradient = styled.div<{ variant: 'row' | 'column'; $width: string }>`
  padding: 1px;
  width: ${({ $width }) => $width};

  background: linear-gradient(
    -45deg,
    ${({ theme }) => theme.border.highlight} 0%,
    transparent ${({ variant }) => (variant === 'column' ? '40%' : '12%')},
    transparent ${({ variant }) => (variant === 'column' ? '60%' : '88%')},
    ${({ theme }) => theme.border.highlight} 100%
  );
  border-radius: ${({ theme }) => `calc(${theme.borderRadius.m} + 1px)`};
`;

const Wrapper = styled.div<{ variant: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ variant }) => (variant === 'column' ? 'column' : 'row')};
  align-items: center;

  gap: 16px;
  width: auto;
  height: ${({ variant }) => (variant === 'column' ? 'auto' : '56px')};
  padding: ${({ variant }) => (variant === 'column' ? '16px 8px' : '0 24px')};

  background-color: ${({ theme }) => theme.surface.primary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const TextWrapper = styled.div<{ variant: 'row' | 'column' }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline: ${({ variant }) => (variant === 'column' ? '16px' : 'auto')};

  text-align: ${({ variant }) => (variant === 'column' ? 'center' : 'left')};
`;
