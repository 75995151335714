import { useMutation } from 'urql';

import { graphql } from '../../gql';

const AcceptOrganizationInviteMutation = graphql(/* GraphQL */ `
  mutation AcceptOrganizationInviteMutation(
    $input: AcceptOrganizationInviteInput!
  ) {
    acceptOrganizationInvite(input: $input) {
      query {
        currentUser {
          ...CurrentUserData
        }
        pendingInvitedOrganizations {
          nodes {
            id
            logoPath
            name
          }
        }
      }
      organization {
        id
        globalTeam {
          id
        }
      }
    }
  }
`);

export const useAcceptOrganizationInvite = () =>
  useMutation(AcceptOrganizationInviteMutation);
