import { useMutation } from 'urql';

import { graphql } from '../../gql';

const JoinTeam = graphql(/* GraphQL */ `
  mutation JoinTeam($input: JoinTeamInput!) {
    joinTeam(input: $input) {
      team {
        ...TeamBasicData
        ...TeamMembers
      }
      organization {
        id
        ...OrganizationFullData
        ...OrganizationAllTeamsData
        invitedToTeams {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

export const useJoinTeam = () => {
  return useMutation(JoinTeam);
};

const DeleteUsersOnTeam = graphql(/* GraphQL */ `
  mutation DeleteUsersOnTeam($input: DeleteUsersOnTeamInput!) {
    deleteUsersOnTeam(input: $input) {
      team {
        ...TeamBasicData
        ...TeamMembers
        organization {
          id
          joinedTeams {
            nodes {
              ...TeamBasicData
            }
          }
        }
      }
    }
  }
`);

export const useDeleteUsersOnTeam = () => {
  return useMutation(DeleteUsersOnTeam);
};
