import styled from 'styled-components';

import { Clickable } from './Clickable/Clickable';
import { Text } from './Text/Text';

type ExportActionButtonProps = {
  text: string | React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  size?: string;
  style?: React.CSSProperties;
};

export const ExportActionButton = ({
  text,
  size,
  style,
  onClick,
  disabled,
}: ExportActionButtonProps) => {
  return (
    <InsertActionButton onClick={onClick} disabled={disabled} style={style}>
      {text}
      {size && <Size type="b2">{size}</Size>}
    </InsertActionButton>
  );
};

const InsertActionButton = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  line-height: 16px;
`;

const Size = styled(Text)`
  margin-left: auto;
  color: ${({ theme }) => theme.text.subtext};
  text-align: right;
  font-feature-settings: 'calt';
  font-variant-numeric: tabular-nums;
`;
