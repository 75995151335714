import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const CustomModelFullDataFragment = graphql(/* GraphQL */ `
  fragment CustomModelFullData on CustomModel {
    id
    name
    promptTemplate
    negativePromptTemplate
    baseModel
    thumbnailPath
    clipSkip
    strength
    firstPassCfg
    firstPassSteps
    firstPassSampler
    firstPassScheduler
    secondPassCfg
    secondPassSteps
    secondPassSampler
    secondPassScheduler
    secondPassDenoise
    organization {
      id
      name
    }
    createdAt
  }
`);

export type CustomModelFullData = ResultOf<typeof CustomModelFullDataFragment>;
