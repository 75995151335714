import { useMutation } from 'urql';

import { graphql } from '../gql';

const CreateFolderMutation = graphql(/* GraphQL */ `
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      folder {
        id
      }
      parent {
        ...FolderData
      }
    }
  }
`);

export const useCreateFolder = () => useMutation(CreateFolderMutation);
