import styled, { DefaultTheme } from 'styled-components';

import { useStringHashNumber } from '../hooks/useStringHashNumber';

interface UserAvatarProps {
  userId: string;
  name: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
}

export function getColorFromHash(theme: DefaultTheme, hash: number = 0) {
  const avatarColors = Object.values(theme.avatar);
  return avatarColors[hash % avatarColors.length];
}

const UserAvatarContainer = styled.div<{
  color: string;
  $userHash: number;
  $size: UserAvatarProps['size'];
}>`
  border-radius: 50%;
  height: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;
  width: ${(p) =>
    p.$size === 'large' ? 40 : p.$size === 'medium' ? 24 : 14}px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.surface.primary};
  background-color: ${({ theme, color, $userHash }) =>
    color || getColorFromHash(theme, $userHash)};
  text-transform: uppercase;
`;

export const UserAvatar = (props: UserAvatarProps) => {
  const hash = useStringHashNumber(props.userId);

  return (
    <UserAvatarContainer
      color={props.color!}
      $size={props.size ?? 'medium'}
      $userHash={hash || 0}
    >
      {props.name[0]}
    </UserAvatarContainer>
  );
};
