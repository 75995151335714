import React from 'react';
import styled from 'styled-components';

import { hsvaToHslString } from '../utils/convert';
import { HsvaColor } from '../utils/types';
import { clamp, round } from '../utils/utils';
import { Interactive, Interaction, Pointer } from './Interactive';

interface SaturationProps {
  hsva: HsvaColor;
  onChange: (newColor: { s: number; v: number }) => void;
  className?: string;
}

const SaturationBase = ({ hsva, onChange, className }: SaturationProps) => {
  const handleMove = (interaction: Interaction) => {
    onChange({
      s: interaction.left * 100,
      v: 100 - interaction.top * 100,
    });
  };

  const handleKey = (offset: Interaction) => {
    // Saturation and brightness always fit into [0, 100] range
    onChange({
      s: clamp(hsva.s + offset.left * 100, 0, 100),
      v: clamp(hsva.v - offset.top * 100, 0, 100),
    });
  };

  const containerStyle = {
    backgroundColor: hsvaToHslString({ h: hsva.h, s: 100, v: 100, a: 1 }),
  };

  return (
    <Container style={containerStyle} className={className}>
      <Interactive
        onMove={handleMove}
        onKey={handleKey}
        aria-label="Color"
        aria-valuetext={`Saturation ${round(hsva.s)}%, Brightness ${round(
          hsva.v
        )}%`}
      >
        <Pointer
          top={1 - hsva.v / 100}
          left={hsva.s / 100}
          color={hsvaToHslString(hsva)}
        />
      </Interactive>
    </Container>
  );
};

export const Saturation = React.memo(SaturationBase);

const Container = styled.div`
  position: relative;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0));

  & .pointer {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;
