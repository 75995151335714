import { OrganizationSubscriptionData } from '@vizcom/shared/data-access/graphql';
import { stripeTimestampToDate } from '@vizcom/shared/js-utils';
import { billingIntervalWording } from '@vizcom/shared/plans-limit';
import { FormattedDate, Text } from '@vizcom/shared-ui-components';

export const IncomingPlanChangesMessage = ({
  organizationSubscription,
}: {
  organizationSubscription: OrganizationSubscriptionData;
}) => {
  const { subscription } = organizationSubscription;
  const { nextPhaseSchedule } = subscription;

  const isIntervalChanging =
    subscription.billingInterval &&
    nextPhaseSchedule?.interval &&
    subscription.billingInterval !== nextPhaseSchedule.interval;

  if (!nextPhaseSchedule || !isIntervalChanging) return null;

  const date = new Date(nextPhaseSchedule.startDate * 1000).toISOString();

  return (
    <Text color="subtext">
      {billingIntervalWording[nextPhaseSchedule.interval]} billing starting{' '}
      <FormattedDate date={date} /> including {nextPhaseSchedule?.quantity}{' '}
      seats
    </Text>
  );
};

export const shouldShowSeatsSettings = (
  organization: OrganizationSubscriptionData
) => {
  const ONE_SEAT_MIGRATION_START_DATE = new Date('2025-01-29');

  // This is the date when we will run a script to schedule seat downgrades. After this date, adding or removing seats will not be possible.
  const ONE_SEAT_MIGRATION_END_DATE = new Date('2026-01-01');

  if (!organization.subscription?.startDate) return false;

  const subscriptionStartDate = stripeTimestampToDate(
    organization.subscription.startDate
  );

  if (subscriptionStartDate > ONE_SEAT_MIGRATION_START_DATE) return false;

  return new Date() < ONE_SEAT_MIGRATION_END_DATE;
};
