import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes, useTheme } from 'styled-components';

import { FloatingPanel } from '../FloatingPanel/FloatingPanel';
import { LoadingLogo } from '../Logo/LoadingLogo';
import { useKeyboardShortcut } from '../hooks/useKeyboardShortcut';
import { ModalContextProvider } from './ModalContext';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: (val: boolean) => any;
  style?: CSSProperties;
  loading?: boolean;
  initialFocus?: boolean;
  placement?: CSSProperties['justifyContent'];
}

/**
 * A modal component that displays a backdrop with a modal container.
 * @param initialFocus - If true, the first element with `[data-autofocus="true"]` inside the modal will be focused when the modal is opened.
 */
export const Modal = (props: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const previousStateRef = useRef<boolean>(false);
  const theme = useTheme();

  const isOpen = props.isOpen || isClosing;

  // detect when closing the modal and set isClosing to true during the animation
  // to keep it in the DOM and prevent instant closing
  useEffect(() => {
    if (props.isOpen !== previousStateRef.current) {
      previousStateRef.current = props.isOpen;
      if (!props.isOpen) {
        setIsClosing(true);
        setTimeout(() => {
          setIsClosing(false);
        }, parseFloat(theme.animation.duration));
      }
    }
  }, [props.isOpen, theme.animation.duration]);

  useKeyboardShortcut(
    'escape',
    () => {
      if (props.isOpen) props.setIsOpen(false);
    },
    { capture: props.isOpen }
  );

  useEffect(() => {
    if (props.isOpen && props.initialFocus) {
      const defaultButton = containerRef.current?.querySelector(
        '[data-autofocus="true"]'
      ) as HTMLElement;
      defaultButton?.focus();
    }
  }, [props.isOpen, props.initialFocus]);

  const backdropRef = useRef<HTMLDivElement | null>(null);

  const handleContainerClick: MouseEventHandler = (e) => {
    if (e.target instanceof Element && e.target.closest('[data-close-modal]')) {
      props.setIsOpen(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleBackdropClick: MouseEventHandler = (e) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as any)
    ) {
      props.setIsOpen(false);
    }
  };

  return createPortal(
    <Backdrop
      onClick={handleBackdropClick}
      $closing={isClosing}
      ref={backdropRef}
      $placement={props.placement}
    >
      {props.loading && <LoadingLogo />}
      <ModalContextProvider value={{ backdropElement: backdropRef }}>
        <ModalContainer>
          <Container
            onClick={handleContainerClick}
            ref={containerRef as any}
            $closing={isClosing}
            $loading={props.loading}
            style={props.style}
          >
            {props.children}
          </Container>
        </ModalContainer>
      </ModalContextProvider>
    </Backdrop>,
    document.body
  );
};

const Backdrop = styled.div<{
  $closing?: boolean;
  $placement?: CSSProperties['justifyContent'];
}>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  animation: ${({ theme }) => theme.animation.duration} ease
    ${({ theme, $closing }) =>
      theme.animation[$closing ? 'backgroundFadeOut' : 'backgroundFadeIn']};
  animation-fill-mode: both;
  ${(p) => p.$closing && 'pointer-events: none;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $placement }) => $placement || 'center'};
`;

// This container is required to make sure modals are verticaly centered when smaller than the screen
// but can still be scrolled when bigger than the screen
const ModalContainer = styled.div`
  padding: 4em 1em;
  overflow-y: auto;
  ${({ theme }) => theme.scrollbar.light}
`;

const modalEnter = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95) translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`;
const modalExit = keyframes`
  from {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: scale(0.95) translate3d(0, -10px, 0);
  }
`;

const Container = styled(FloatingPanel)<{
  $closing?: boolean;
  $loading?: boolean;
}>`
  max-width: 600px;
  min-width: 350px;
  padding: 26px 24px;

  color: ${({ theme }) => theme.text.body};

  animation: ${({ theme }) => theme.animation.duration} ease
    ${(p) => (p.$closing ? modalExit : modalEnter)};
  animation-fill-mode: both;
  ${(p) => (p.$loading ? 'filter: blur(2px);' : '')}
`;
