import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const EnhanceEventTypeZodEnum = z.enum(['ENHANCE_FEEDBACK']);

export const EnhanceEventName = EnhanceEventTypeZodEnum.Enum;

export const EnhanceTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: EnhanceEventTypeZodEnum,
  data: z.object({
    drawingId: z.string(),
    feedback: z.enum(['approve', 'retry']),
  }),
});
