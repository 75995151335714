import { isValid } from 'date-fns';
import {
  OrganizationData,
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  CheckIcon,
  BlockStack,
  InlineFlex,
  Text,
  Divider,
} from '@vizcom/shared-ui-components';

import {
  PlanContainer,
  PlanCta,
  ContactSalesButton,
  PlanPriceText,
  PocChip,
  PocExpirationMessage,
} from './OrganizationPlansTable';

export const EnterprisePlanPricing = (props: {
  organization: OrganizationData;
  subscriptionData: OrganizationSubscriptionData;
}) => {
  const { organization } = props;
  const { subscriptionPlan, poc } = organization;

  const active = subscriptionPlan === OrganizationSubscriptionPlan.Enterprise;
  const pocEndDate = new Date(organization.pocEndDate as string);
  const isPocExpired = isValid(pocEndDate) && new Date() > pocEndDate;

  return (
    <PlanContainer $active={active}>
      <BlockStack $justifyContent="space-between">
        <BlockStack $gap={20}>
          <InlineFlex $gap={10}>
            <Text type="h2">Enterprise</Text>
            {active && poc && <PocChip expired={isPocExpired} />}
          </InlineFlex>
          <PlanPriceText>Let's talk</PlanPriceText>
          <Text type="b2">
            Made for organizations looking to design at scale
          </Text>
        </BlockStack>
        {active && (
          <PocExpirationMessage
            poc={poc}
            pocEndDate={organization.pocEndDate}
            expired={isPocExpired}
          />
        )}

        <PlanCta>
          {active ? (
            <Button disabled size="M" variant="secondary">
              Current plan
            </Button>
          ) : (
            <ContactSalesButton />
          )}
        </PlanCta>
      </BlockStack>

      <Divider style={{ marginBlock: 25 }} />

      <BlockStack $gap={15}>
        <Text type="sh2">Everything in Pro, plus:</Text>

        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Unlimited teams</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Single Sign-On (SSO)</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Custom AI models</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Batch generating</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Dedicated onboarding</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Custom billing</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Custom security & privacy</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Audit and access logs</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Private and public teams</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Team management</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Priority support</Text>
        </InlineFlex>
      </BlockStack>
    </PlanContainer>
  );
};
