import { useMutation } from 'urql';

import { graphql } from '../gql';

export const UpdateVideoMutation = graphql(/* GraphQL */ `
  mutation UpdateVideo($id: UUID!, $patch: VideoPatch!) {
    updateVideo(input: { id: $id, patch: $patch }) {
      video {
        ...VideoData
      }
    }
  }
`);

export const useUpdateVideo = () => {
  return useMutation(UpdateVideoMutation);
};
