import { FC, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  useCurrentUser,
  OrganizationBasic,
  usePendingInvitedOrganizations,
  removeAccessToken,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import { Divider } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ContextMenuItem } from '../ContextMenu/ContextMenuItem';
import { OrganizationAvatar } from '../OrganizationAvatar';
import { TeamAvatar } from '../TeamAvatar';
import { CheckIcon, DropdownIcon } from '../icons';

interface OrganizationSwitcherProps {
  selectedOrganization: OrganizationBasic;
}

const DoubleArrowIcon: React.FC = () => (
  <IconWrapper>
    <DropdownIcon
      style={{ transform: 'rotate(180deg)', marginBottom: '-2px' }}
    />
    <DropdownIcon style={{ marginTop: '-2px' }} />
  </IconWrapper>
);

export const OrganizationSwitcher: FC<OrganizationSwitcherProps> = ({
  selectedOrganization,
}) => {
  const { data: user } = useCurrentUser();
  const referenceElementRef = useRef<HTMLDivElement>(null);
  const [hasOpenSwitcher, setHasOpenSwitcher] = useState(false);
  // only load pending invite if the user has open the switcher at least once
  const { data: pendingInvitedOrganizations } = usePendingInvitedOrganizations({
    pause: !hasOpenSwitcher,
  });

  const organizationComponents = user?.organizations?.edges.map(({ node }) => (
    <OrganizationItemComponent
      key={node.id}
      organization={node}
      selected={node.id === selectedOrganization?.id}
    />
  ));

  const pendingInvitedOrganizationComponents = pendingInvitedOrganizations?.map(
    (organization) => (
      <OrganizationItem
        as={NavLink}
        to={paths.organization.acceptInvite(organization.id)}
        key={organization.id}
      >
        <TeamAvatar
          name={organization.name}
          teamId={organization.id}
          size="small"
          style={{ marginRight: 12 }}
        />
        <TextContainer>
          <OrganizationName>{organization.name}</OrganizationName>
          <p
            style={{
              fontSize: 10,
              opacity: 0.6,
              marginTop: 2,
            }}
          >
            (Invited)
          </p>
        </TextContainer>
      </OrganizationItem>
    )
  );

  const handleLogout = () => {
    removeAccessToken();
  };

  return (
    <ContextMenu
      style={{ padding: '15px 10px', maxWidth: 230 }}
      items={
        <>
          <ScrollableContainer>
            <UserInfo>{user?.email}</UserInfo>
            {organizationComponents}
            {pendingInvitedOrganizationComponents}
          </ScrollableContainer>

          <Divider style={{ marginBottom: 10 }} />

          {user?.canCreateOrganization && (
            <ContextMenuItem as={NavLink} to={paths.organization.create()}>
              Create a new workspace
            </ContextMenuItem>
          )}
          <ContextMenuItem onClick={handleLogout}>Sign out</ContextMenuItem>
        </>
      }
      buttonProps={{
        variant: 'bare',
        as: StyledButton,
      }}
      referenceElementRef={referenceElementRef}
      onOpenStateChange={(open) => {
        if (open) {
          setHasOpenSwitcher(true);
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '12px 12px 12px 12px',
          width: '100%',
          height: '100%',
        }}
        ref={referenceElementRef}
      >
        <OrganizationAvatar
          organization={selectedOrganization}
          size="medium"
          style={{ marginRight: 12 }}
        />
        <NameContainer>
          <OverflowText>{selectedOrganization.name}</OverflowText>
          <PricePlanText>
            {capitalize(selectedOrganization.subscriptionPlan)} plan
          </PricePlanText>
        </NameContainer>
        <DropdownIconContainer>
          <DoubleArrowIcon />
        </DropdownIconContainer>
      </div>
    </ContextMenu>
  );
};

const DropdownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  z-index: 0;
  background-color: ${({ theme }) => theme.surface.tertiary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.surface.tertiary};
  }
`;

const StyledButton = styled.button`
  justify-content: space-between;
  width: 100%;

  color: ${({ theme }) => theme.text.subtext};
  line-height: 1.2;

  &:hover {
    background-color: ${({ theme }) => theme.surface.secondary};
    color: ${({ theme }) => theme.text.subtext};
  }

  &:focus {
    outline: none;
  }
`;

const ScrollableContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  max-width: 400px;
  ${({ theme }) => theme.scrollbar.light}
`;
const OverflowText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 14px;
  color: ${({ theme }) => theme.text.body};
`;

const PricePlanText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  margin-top: 2px;
  color: ${({ theme }) => theme.text.subtext};
`;

const UserInfo = styled.p`
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  padding: 8px 0 4px 8px;
  color: #fff;
  opacity: 0.5;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NameContainer = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  max-width: 90%;
`;

const OrganizationItem = styled(ContextMenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const OrganizationName = styled.p`
  font-size: 12;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface OrganizationItemProps {
  organization: OrganizationBasic;
  selected: boolean;
}

export const OrganizationItemComponent: React.FC<OrganizationItemProps> = ({
  organization,
  selected,
}) => {
  return (
    <OrganizationItem
      as={NavLink}
      to={paths.files.organizationRecent(organization.id)}
      key={organization.id}
    >
      <OrganizationAvatar
        organization={organization}
        size="small"
        style={{ marginRight: 12 }}
      />
      <TextContainer>
        <OrganizationName>{organization.name}</OrganizationName>
        <p
          style={{
            fontSize: 10,
            opacity: 0.6,
            marginTop: 2,
          }}
        >
          <span>{capitalize(organization.subscriptionPlan)} plan</span>
        </p>
      </TextContainer>
      {selected && <CheckIcon $color="primary" />}
    </OrganizationItem>
  );
};
