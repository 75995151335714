import { useMutation } from 'urql';

import { graphql } from '../gql';

const CreateDrawingStyleReferenceMutation = graphql(/* GraphQL */ `
  mutation CreateStyleReference($input: CreateStyleReferenceInput!) {
    createStyleReference(input: $input) {
      workbench {
        ...WorkbenchStyleReferences
      }
      styleReference {
        id
      }
    }
  }
`);

export const useCreateDrawingStyleReference = () =>
  useMutation(CreateDrawingStyleReferenceMutation);

const CreateDrawingStyleReferencesMutation = graphql(/* GraphQL */ `
  mutation CreateStyleReferences($input: CreateStyleReferencesInput!) {
    createStyleReferences(input: $input) {
      workbench {
        ...WorkbenchStyleReferences
      }
      styleReferences {
        id
      }
    }
  }
`);
export const useCreateDrawingStyleReferences = () =>
  useMutation(CreateDrawingStyleReferencesMutation);

const RemoveStyleReferencesMutation = graphql(/* GraphQL */ `
  mutation RemoveStyleReferences($input: RemoveStyleReferencesInput!) {
    removeStyleReferences(input: $input) {
      status
    }
  }
`);

export const useRemoveStyleReferences = () =>
  useMutation(RemoveStyleReferencesMutation);
