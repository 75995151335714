import { graphql } from '../gql';

export const UserPaletteDataFragment = graphql(/* GraphQL */ `
  fragment UserPaletteData on UserPalette {
    id
    name
    tags
    thumbnailPath
    sourcePaletteId
    usageCount
  }
`);
