import styled, { css } from 'styled-components';

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: auto;
`;

const Dot = styled.button<{ onClick?: (index: number) => void }>`
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border: none;
  padding: 0;

  &.active {
    background: white;
  }

  &:focus {
    outline: none;
  }

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
          &:hover {
            background: white;
          }
        `
      : `cursor: default;`}
`;

type DotsPaginationProps = {
  changeStep?: (index: number) => void;
  currentStepIndex: number;
  stepsCount: number;
};

export const DotsPagination = ({
  changeStep,
  currentStepIndex,
  stepsCount,
}: DotsPaginationProps) => {
  const positions = Array.from(new Array(stepsCount));

  return (
    <DotsContainer>
      {positions.map((_, position) => (
        <Dot
          key={position}
          onClick={changeStep && (() => changeStep(position))}
          className={currentStepIndex === position ? 'active' : ''}
        />
      ))}
    </DotsContainer>
  );
};
