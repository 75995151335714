import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const UserBasicDataFragment = graphql(/* GraphQL */ `
  fragment UserBasicData on User {
    id
    email
    name
  }
`);
export type UserBasicData = ResultOf<typeof UserBasicDataFragment>;

export const CurrentUserFragment = graphql(/* GraphQL */ `
  fragment CurrentUserData on User {
    id
    email
    name
    isAdmin
    isEduAdmin
    occupation
    description
    createdAt
    featureFlags
    crispIdentityToken
    lastChangelogSeenAt
    canCreateOrganization
    isProfileCompleted
    appRootUrl
    organizations {
      edges {
        node {
          ...OrganizationData
        }
      }
    }
    ...AllUserClientState
  }
`);
