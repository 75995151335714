import { useMutation } from 'urql';

import { graphql } from '../gql';

const CreateTeam = graphql(/* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        ...TeamData
        organization {
          ...OrganizationAllTeamsData
          ...OrganizationFullData
        }
      }
    }
  }
`);

export const useCreateTeam = () => {
  return useMutation(CreateTeam);
};
