import { ResultOf } from '@graphql-typed-document-node/core';
import { useQuery, UseQueryArgs } from 'urql';

import { graphql } from '../gql';
import { EducationRequestStatus, OrganizationCondition } from '../gql/graphql';

/**
 * Organizations
 */
const organizations = graphql(/* GraphQL */ `
  query organizations($query: OrganizationCondition) {
    organizations(condition: $query) {
      nodes {
        ...OrganizationData
        azureTenantId
        restrictToInvitee
        manualSubscription
        paidSeatsCount
        maxPaletteStorage
        customSubdomain
        members {
          totalCount
          edges {
            role
            node {
              id
              email
            }
          }
        }
        organizationInvites {
          totalCount
        }
        teams {
          totalCount
        }
      }
    }
  }
`);

export const useOrganizations = (query?: OrganizationCondition) => {
  const [res, execute] = useQuery({
    query: organizations,
    variables: {
      query,
    },
  });

  return { ...res, data: res.data?.organizations?.nodes || [], execute };
};

type UseOrganizationsReturn = ReturnType<typeof useOrganizations>;
export type UseOrganizationsReturnData = UseOrganizationsReturn['data'];
export type UseOrganizationsReturnOrgData = UseOrganizationsReturnData[0];

/**
 * Organization by ID
 */
export const organization = graphql(/* GraphQL */ `
  query organization($id: UUID!) {
    organization(id: $id) {
      ...OrganizationFullData
      invitedToTeams {
        nodes {
          id
          name
        }
      }
      members {
        totalCount
      }
    }
  }
`);

export const useOrganization = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: organization,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

type UseOrganizationReturn = ReturnType<typeof useOrganization>;
export type UseOrganizationReturnData = UseOrganizationReturn['data'];

/**
 * Organization Teams
 */
const organizationTeams = graphql(/* GraphQL */ `
  query organizationTeams($id: UUID!) {
    organization(id: $id) {
      ...OrganizationAllTeamsData
      invitedToTeams {
        nodes {
          id
          name
        }
      }
    }
  }
`);

export const useOrganizationTeams = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationTeams,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Organization Members
 */
const organizationMembers = graphql(/* GraphQL */ `
  query organizationMembers($id: UUID!) {
    organization(id: $id) {
      id
      azureTenantId
      ...OrganizationMembersData
      ...OrganizationInvitesData
    }
  }
`);

export const useOrganizationMembers = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationMembers,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Organization Access Logs
 */
const organizationAccessLogs = graphql(/* GraphQL */ `
  query organizationAccessLogs($id: UUID!, $offset: Int!, $email: String!) {
    organization(id: $id) {
      id
      userAccessLogs(
        first: 10
        offset: $offset
        condition: { userEmailContains: $email }
        orderBy: EVENT_DATE_DESC
      ) {
        nodes {
          userEmail
          ipAddress
          eventDate
          deviceInfo
        }
        totalCount
      }
    }
  }
`);

export const useOrganizationAccessLogs = (
  id: string | undefined,
  offset: number,
  email: string
) => {
  const [res] = useQuery({
    query: organizationAccessLogs,
    variables: {
      id: id!,
      offset,
      email,
    },
    pause: !id,
  });

  return {
    ...res,
    count: res.data?.organization?.userAccessLogs?.totalCount || 0,
    data: res.data?.organization?.userAccessLogs?.nodes || [],
  };
};

/**
 * Organization Activity Logs
 */
const organizationActivityLogs = graphql(/* GraphQL */ `
  query organizationActivityLogs($id: UUID!, $offset: Int!) {
    organization(id: $id) {
      id
      userActionLogs(first: 10, offset: $offset, orderBy: EVENT_DATE_DESC) {
        nodes {
          ...UserActionLogsData
        }
        totalCount
      }
    }
  }
`);

export const useOrganizationActivityLogs = (
  id: string | undefined,
  offset: number
) => {
  const [res] = useQuery({
    query: organizationActivityLogs,
    variables: {
      id: id!,
      offset,
    },
    pause: !id,
  });

  return {
    ...res,
    count: res.data?.organization?.userActionLogs?.totalCount || 0,
    data: res.data?.organization?.userActionLogs?.nodes || [],
  };
};

/**
 * Pending Invited Organizations
 */
const pendingInvitedOrganizations = graphql(/* GraphQL */ `
  query pendingInvitedOrganizations {
    pendingInvitedOrganizations {
      nodes {
        id
        logoPath
        name
      }
    }
  }
`);

export const usePendingInvitedOrganizations = (
  args: Partial<UseQueryArgs<any>> = {}
) => {
  const [res] = useQuery({
    ...args,
    query: pendingInvitedOrganizations,
  });

  return {
    ...res,
    data: res.data?.pendingInvitedOrganizations?.nodes,
  };
};

/**
 * Organization Subscription
 */
const organizationSubscription = graphql(/* GraphQL */ `
  query organizationSubscription($id: UUID!) {
    organization(id: $id) {
      ...OrganizationSubscriptionData
    }
  }
`);

export const useOrganizationSubscription = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationSubscription,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Organization Teams Users
 */
const organizationTeamsUsers = graphql(/* GraphQL */ `
  query organizationTeamsUsers($id: UUID!) {
    organization(id: $id) {
      id
      name
      teams {
        nodes {
          ...TeamBasicData
          globalInOrganization
          invites {
            nodes {
              email
            }
          }
          members {
            nodes {
              email
              id
            }
          }
        }
      }
    }
  }
`);

export type OrganizationTeamsUsers = ResultOf<
  typeof organizationTeamsUsers
>['organization'];

export const useOrganizationTeamsUsers = (id: string | undefined) => {
  const [res] = useQuery({
    query: organizationTeamsUsers,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Organization Seats Usage
 */
const organizationSeatsUsageQuery = graphql(/* GraphQL */ `
  query organizationSeatsUsage($id: UUID!) {
    organization(id: $id) {
      id
      ...OrganizationSeatsUsageData
    }
  }
`);

export type OrganizationSeatsUsageData = NonNullable<
  ResultOf<typeof organizationSeatsUsageQuery>['organization']
>['seatsUsage'];

export const useOrganizationSeatsUsageQuery = (id: string) => {
  const [res] = useQuery({
    query: organizationSeatsUsageQuery,
    variables: {
      id,
    },
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Palette Usage
 */
const organizationPaletteUsageQuery = graphql(/* GraphQL */ `
  query organizationPaletteUsage($id: UUID!) {
    organization(id: $id) {
      id
      uniqueTrainedPaletteCount
      maxPaletteStorage
    }
  }
`);

export const useOrganizationPaletteUsage = (orgId: string | undefined) => {
  const [res] = useQuery({
    query: organizationPaletteUsageQuery,
    variables: {
      id: orgId!,
    },
    pause: !orgId,
    requestPolicy: 'cache-and-network',
  });

  return { ...res, data: res.data?.organization };
};

/**
 * Education Organizations
 */
const educationOrganizationsQuery = graphql(/* GraphQL */ `
  query getEducationOrganizations(
    $offset: Int
    $first: Int
    $searchText: String
  ) {
    getEducationOrganizations(
      offset: $offset
      first: $first
      searchText: $searchText
    ) {
      nodes {
        id
        name
        subscriptionPlan
        createdAt
        userOrganizations {
          nodes {
            user {
              id
              email
            }
            userId
          }
        }
        organizationInvites {
          totalCount
        }
        members {
          totalCount
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`);

export const useOrganizationsEducation = (
  pageNumber: number,
  searchText: string,
  first: number = 10
) => {
  const offset = pageNumber * first;

  const [res] = useQuery({
    requestPolicy: 'network-only',
    query: educationOrganizationsQuery,
    variables: {
      offset,
      searchText,
      first,
    },
  });

  const data = res.data?.getEducationOrganizations;

  return {
    ...res,
    hasNextPage: data?.pageInfo.hasNextPage || false,
    data: data?.nodes || [],
  };
};

/**
 * Education Organizations Requests
 */
const educationPlanRequestsQuery = graphql(/* GraphQL */ `
  query educationPlanRequests(
    $first: Int
    $offset: Int
    $status: EducationRequestStatus
  ) {
    educationPlanRequests(
      first: $first
      offset: $offset
      condition: { status: $status }
    ) {
      nodes {
        ...EducationPlanRequestData
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`);

export const useEducationOrganizationsRequests = (
  pageNumber: number,
  pageSize: number = 10,
  status: EducationRequestStatus
) => {
  const offset = pageNumber * pageSize;

  const [res] = useQuery({
    query: educationPlanRequestsQuery,
    variables: {
      first: pageSize,
      offset,
      status,
    },
  });

  const data = res.data?.educationPlanRequests;

  return {
    ...res,
    hasNextPage: data?.pageInfo.hasNextPage || false,
    data: data?.nodes || [],
  };
};

export const OrganizationEducationPlanRequestQuery = graphql(/* GraphQL */ `
  query OrganizationEducationPlanRequest($organizationId: UUID!) {
    educationPlanRequest(organizationId: $organizationId) {
      ...EducationPlanRequestData
    }
  }
`);

export const useOrganizationEducationPlanRequest = (organizationId: string) => {
  const [res] = useQuery({
    query: OrganizationEducationPlanRequestQuery,
    variables: {
      organizationId,
    },
    pause: !organizationId,
  });

  return { ...res, data: res.data?.educationPlanRequest };
};
