import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

export default [
  BaseTrackingEventSchema.extend({
    type: z.enum(['WORKBENCH_CREATED']),
    data: z.object({
      workbenchId: z.string(),
      startInStudio: z.boolean(),
      navigatedFrom: z.string().optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['WORKBENCH_DELETED', 'WORKBENCH_MOVED']),
    data: z.object({
      workbenchIds: z.array(z.string()),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['ZIP_EXPORT']),
    data: z.object({
      workbenchId: z.string(),
      countFiles: z.number(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['WORKBENCH_DUPLICATED', 'CREATE_MULTI_VIEW']),
    data: z.object({
      workbenchId: z.string(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['MIX_IMAGES']),
    data: z.object({
      workbenchId: z.string(),
      sourceDrawingIds: z.array(z.string()),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['DELETE_ELEMENTS']),
    data: z.object({
      workbenchIds: z.array(z.string()),
      elementIds: z.array(z.string()).optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['UPDATE_ELEMENTS', 'CREATE_ELEMENTS', 'DUPLICATE_ELEMENTS']),
    data: z.object({
      workbenchId: z.string(),
      elementIds: z.array(z.string()).optional(),
    }),
  }),
];
