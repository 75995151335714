import styled, { useTheme } from 'styled-components';
import {
  OrganizationData,
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
  useOrganization,
  useOrganizationSubscription,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  FullPageDarkLoader,
  OrganizationPlansTable,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import { Modal } from '../Modal/Modal';
import { ModalCloseButton } from '../Modal/ModalItems';
import { Text } from '../Text/Text';

const Link = styled.a`
  color: ${({ theme }) => theme.text.link};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const UnderlinedLink = styled.a`
  color: ${({ theme }) => theme.text.subtext};
  text-decoration: underline;
`;

type PaywallModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orgId?: string;
};

export const PaywallModal = (props: PaywallModalProps) => {
  const { data: organization, fetching: loading } = useOrganization(
    props.orgId
  );
  const { data: organizationSubscription, fetching } =
    useOrganizationSubscription(props.orgId);

  if (loading || fetching) {
    return <FullPageDarkLoader />;
  }

  return (
    <LegacyPaywallModal
      {...props}
      organization={organization}
      organizationSubscription={organizationSubscription}
    />
  );
};

export const ConnectedLegacyPaywallModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: organization, loading } = useSelectedOrganization();
  const { data: organizationSubscription, fetching } =
    useOrganizationSubscription(organization?.id);

  if (loading || fetching) {
    return <FullPageDarkLoader />;
  }

  return (
    <LegacyPaywallModal
      isOpen={isOpen}
      onClose={onClose}
      organization={organization}
      organizationSubscription={organizationSubscription}
    />
  );
};

export const LegacyPaywallModal = ({
  organization,
  organizationSubscription,
  isOpen,
  onClose,
}: PaywallModalProps & {
  organization: OrganizationData | null | undefined;
  organizationSubscription: OrganizationSubscriptionData | null | undefined;
}) => {
  const theme = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => onClose()}
      style={{
        padding: '32px 28px',
        borderRadius: theme.borderRadius.l,
        overflow: 'hidden',
        maxWidth: 880,
        width: '90%',
      }}
    >
      <ModalCloseButton style={{ position: 'absolute', right: 28, top: 28 }} />
      {!organization ||
      !organizationSubscription ||
      !organizationSubscription.subscription ? (
        <Text block style={{ textAlign: 'center', padding: '60px 20px' }}>
          There was an error. Please refresh the page and retry.
        </Text>
      ) : (
        <>
          <ModalHeader>
            <Text type="h1" block style={{ marginBottom: '8px' }}>
              Plans
            </Text>
            <Text type="sh1" color="subtext">
              You are currently on the{' '}
              <strong>
                {organization.subscriptionPlan ===
                OrganizationSubscriptionPlan.Free
                  ? 'Starter'
                  : capitalize(organization.subscriptionPlan)}
              </strong>{' '}
              plan. To access advanced features, upgrade now.
            </Text>
          </ModalHeader>
          <PlansTableContainer>
            <OrganizationPlansTable
              organization={organization}
              subscription={organizationSubscription}
            />
          </PlansTableContainer>
          <BottomSection>
            <Text>
              Need to speak to our sales team?{' '}
              <StyledLink target="_blank" href="https://www.vizcom.ai/contact">
                Please reach out.
              </StyledLink>
            </Text>
            <UnderlinedLink
              target="_blank"
              href="https://www.vizcom.ai/pricing"
            >
              View all features
            </UnderlinedLink>
          </BottomSection>
        </>
      )}
    </Modal>
  );
};

const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const PlansTableContainer = styled.div`
  margin-bottom: 24px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.text.link};
  &:hover {
    text-decoration: underline;
  }
`;
