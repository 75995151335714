import { create } from 'zustand';

type PaywallModalVariant = 'legacy' | 'freeToPro' | 'proToEnterprise';

export const usePaywallModalState = create<{
  isOpen: boolean;
  variant: PaywallModalVariant;
  trigger: (variant?: PaywallModalVariant) => void;
  close: () => void;
}>((set) => ({
  isOpen: false,
  variant: 'legacy',
  trigger: (variant = 'legacy') => {
    set({
      isOpen: true,
      variant,
    });
  },
  close: () => {
    set({
      isOpen: false,
    });
  },
}));
