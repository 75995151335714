import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  OrganizationSubscriptionPlan,
  TeamRole,
} from '@vizcom/shared/data-access/graphql';
import {
  HomeIcon,
  SharedHelpCenter,
  Text,
  useSelectedOrganization,
  FileIcon,
  SettingsIcon,
  HelpIcon,
  usePaywallModalState,
  UpgradeWidget,
  BlockStack,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { SIDEBAR_WIDTH } from '../constants';
import { OrganizationSwitcher } from './OrganizationSwitcher';
import { OrganizationTeamSwitcher } from './OrganizationTeamSwitcher';
import { AccountSettings } from './SidebarAccountSettings';
import { useSidebarOpenStateSync } from './state';

export const Sidebar = (props: { hideTeam?: boolean }) => {
  const { data: selectedOrganization } = useSelectedOrganization();
  const showUpgradeBanner =
    !props.hideTeam &&
    selectedOrganization?.subscriptionPlan ===
      OrganizationSubscriptionPlan.Free &&
    selectedOrganization?.currentUserRole === TeamRole.Admin;

  const [isSidebarOpen, setIsSidebarOpen] = useSidebarOpenStateSync();

  const handleLinkClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <SidebarWrapper isOpen={isSidebarOpen}>
      {!props.hideTeam && (
        <>
          {selectedOrganization && (
            <OrganizationSwitcher selectedOrganization={selectedOrganization} />
          )}

          {selectedOrganization && (
            <ItemsWrapper>
              <SidebarItem
                to={paths.files.organizationRecent(selectedOrganization.id)}
                onClick={handleLinkClick}
              >
                <HomeIcon />
                <Text type="sh1">Home</Text>
              </SidebarItem>

              <SidebarItem
                to={paths.files.organizationDraftFolder(
                  selectedOrganization.id
                )}
                onClick={handleLinkClick}
              >
                <FileIcon />
                <Text type="sh1">My Files</Text>
              </SidebarItem>

              <SidebarItem
                to={paths.settings.organization.general(
                  selectedOrganization?.id
                )}
                onClick={handleLinkClick}
              >
                <SettingsIcon />
                <Text type="sh1">Settings</Text>
              </SidebarItem>
            </ItemsWrapper>
          )}
        </>
      )}

      {!props.hideTeam && selectedOrganization && (
        <OrganizationTeamSwitcher organizationId={selectedOrganization.id} />
      )}

      <LowerContainer>
        {showUpgradeBanner && (
          <UpgradeWidget
            variant="column"
            title="Upgrade to unlock more features"
            cta={{
              text: 'Upgrade',
              action: () => usePaywallModalState.getState().trigger(),
            }}
          />
        )}

        <SharedHelpCenter buttonProps={{ variant: 'bare' }}>
          <SidebarItem to="/" onClick={(e) => e.preventDefault()}>
            <HelpIcon />
            <Text type="sh1">Help &amp; feedback</Text>
          </SidebarItem>
        </SharedHelpCenter>
        <AccountSettings />
      </LowerContainer>
    </SidebarWrapper>
  );
};

export const SidebarWrapper = styled.div<{ isOpen: boolean }>`
  z-index: 2;
  background-color: ${({ theme }) => theme.surface.pageInverted};

  grid-area: sidebar;
  width: ${SIDEBAR_WIDTH}px;

  @media (max-width: 645px) {
    transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '-100%')});
    transition: 0.2s transform ease;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 16px;
  height: 100vh; // fallback for older browsers
  // Using dvh instead of vh to prevent problem on ios where the sidebar is bigger than the screen if the address bar is visible
  height: 100dvh;

  box-sizing: border-box;
  overflow-x: hidden;
`;

const ItemsWrapper = styled(BlockStack)`
  gap: 8px;
  margin-top: 54px;
`;

export const SidebarItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  padding: 13px 16px;

  border-radius: ${({ theme }) => theme.borderRadius.m};
  color: ${(p) => p.theme.text.subtext};
  cursor: pointer;

  transition: background-color 0.3s ease, color 0.3s ease;

  :hover {
    background-color: ${(p) => p.theme.surface.secondary};
  }

  :active,
  &[aria-current] {
    background-color: ${(p) => p.theme.surface.secondary};
    color: ${(p) => p.theme.text.body};
  }
`;

const LowerContainer = styled(BlockStack)`
  gap: 8px;
  margin-top: auto;
`;
