import { useMutation } from 'urql';

import { graphql } from '../gql';

const DeleteTeamMutation = graphql(/* GraphQL */ `
  mutation DeleteTeam($id: UUID!) {
    deleteTeam(input: { id: $id }) {
      organization {
        ...OrganizationAllTeamsData
        joinedTeams {
          nodes {
            ...TeamBasicData
          }
        }
      }
    }
  }
`);

export const useDeleteTeam = () => useMutation(DeleteTeamMutation);
