// Clamps a value between an upper and lower bound.
// We use ternary operators because it makes the minified code
// 2 times shorter then `Math.min(Math.max(a,b),c)`
export const clamp = (number: number, min = 0, max = 1): number => {
  return number > max ? max : number < min ? min : number;
};

import { hexToRgba } from './convert';
import { ObjectColor } from './types';

export const equalColorObjects = (
  first: ObjectColor,
  second: ObjectColor
): boolean => {
  if (first === second) return true;

  for (const prop in first) {
    // The following allows for a type-safe calling of this function (first & second have to be HSL, HSV, or RGB)
    // with type-unsafe iterating over object keys. TS does not allow this without an index (`[key: string]: number`)
    // on an object to define how iteration is normally done. To ensure extra keys are not allowed on our types,
    // we must cast our object to unknown (as RGB demands `r` be a key, while `Record<string, x>` does not care if
    // there is or not), and then as a type TS can iterate over.
    if (
      (first as unknown as Record<string, number>)[prop] !==
      (second as unknown as Record<string, number>)[prop]
    )
      return false;
  }

  return true;
};

export const equalColorString = (first: string, second: string): boolean => {
  return first.replace(/\s/g, '') === second.replace(/\s/g, '');
};

export const equalHex = (first: string, second: string): boolean => {
  if (first.toLowerCase() === second.toLowerCase()) return true;

  // To compare colors like `#FFF` and `ffffff` we convert them into RGB objects
  return equalColorObjects(hexToRgba(first), hexToRgba(second));
};

export const formatClassName = (names: unknown[]): string =>
  names.filter(Boolean).join(' ');

declare const __webpack_nonce__: string | undefined;
let nonce: string | undefined;

/**
 * Returns a nonce hash included by Webpack or the one defined manually by developer.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce
 * https://webpack.js.org/guides/csp/
 */
export const getNonce = (): string | undefined => {
  if (nonce) return nonce;
  if (typeof __webpack_nonce__ !== 'undefined') return __webpack_nonce__;
  return undefined;
};

/**
 * Signs the style tag with a base64-encoded string (nonce) to conforms to Content Security Policies.
 * This function has to be invoked before any picker is rendered if you aren't using Webpack for CSP.
 */
export const setNonce = (hash: string): void => {
  nonce = hash;
};

export const round = (
  number: number,
  digits = 0,
  base = Math.pow(10, digits)
): number => {
  return Math.round(base * number) / base;
};

const matcher = /^#?([0-9A-F]{3,8})$/i;

export const validHex = (value: string, alpha?: boolean): boolean => {
  const match = matcher.exec(value);
  const length = match ? match[1].length : 0;

  return (
    length === 3 || // '#rgb' format
    length === 6 || // '#rrggbb' format
    (!!alpha && length === 4) || // '#rgba' format
    (!!alpha && length === 8) // '#rrggbbaa' format
  );
};

const isNumberInRange = (v: string, min: number, max: number): boolean => {
  const num = Number(v);
  return !isNaN(num) && num >= min && num <= max;
};

export const validateRGB = (rgb: string[]): boolean => {
  return rgb.every((v) => isNumberInRange(v, 0, 255));
};

export const validateHSL = ([h, s, l]: string[]): boolean => {
  return (
    isNumberInRange(h, 0, 360) &&
    isNumberInRange(s, 0, 100) &&
    isNumberInRange(l, 0, 100)
  );
};
