import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const MagicEraserEventTypeZodEnum = z.enum([
  'MAGIC_ERASER_TRIGGERED',
  'MAGIC_ERASER_SUCCESS',
  'MAGIC_ERASER_ERROR',
]);

export const MagicEraserEventName = MagicEraserEventTypeZodEnum.Enum;

export const MagicEraserTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: MagicEraserEventTypeZodEnum,
  data: z.object({
    latency: z.number().optional(),
  }),
});
