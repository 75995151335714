import { v4 as uuidv4 } from 'uuid';

import { graphql } from '../../gql';
import { urqlClient } from '../../lib/graphql';
import { waitForEphemeralJobResult } from '../../subscriptions';
import { ephemeralJobMailboxId } from '../../subscriptions/ephemeralJobs/ephemeralJobMailboxId';

export const MagicEraseMutation = graphql(/* GraphQL */ `
  mutation MagicErase($input: MagicEraseInput!) {
    magicErase(input: $input) {
      jobId
    }
  }
`);

export const magicEraseImage = async (image: Blob, mask: Blob) => {
  const jobId = uuidv4();
  const res = await urqlClient.mutation(MagicEraseMutation, {
    input: { image, mask, jobId, mailboxId: ephemeralJobMailboxId },
  });
  const jobOutput = await waitForEphemeralJobResult<'magicErase'>(jobId, 20000);
  return jobOutput;
};
