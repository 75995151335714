import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Button } from '@vizcom/shared-ui-components';

interface TableProps {
  cols: number;
  currentPage?: number;
  totalPages?: number;
  total?: number;
  filters?: React.ReactNode;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  tableStyle?: CSSProperties;
  addMenuColumn?: boolean;
  addSelectionColumn?: boolean;
}

const StyledTable = styled.div<{
  cols: number;
  addMenuColumn: boolean;
  addSelectionColumn: boolean;
}>`
  display: grid;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  overflow: hidden;
  border: 1px solid ${(p) => p.theme.surface.secondary};
  grid-template-columns:
    [start]
    ${(p) => (p.addSelectionColumn ? 'auto' : '')}
    repeat(${(p) => p.cols}, minmax(0, 1fr))
    ${(p) => (p.addMenuColumn ? 'auto' : '')}
    [end];

  > div:first-of-type {
    > div {
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(p) => p.theme.text.body};

  & > button {
    width: 45px;
    font-family: monospace;
    padding: 15px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;

  & input {
    background-color: ${(p) => p.theme.surface.primary};
    height: 32px;
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & > div {
    height: 32px;
  }
`;

const Table = ({
  onNextPage,
  onPrevPage,
  filters,
  currentPage,
  totalPages,
  cols,
  children,
  tableStyle,
  addMenuColumn = true,
  addSelectionColumn = false,
}: PropsWithChildren<TableProps>) => {
  const enablePagination =
    onNextPage && onPrevPage && totalPages && totalPages > 0;

  return (
    <div>
      {!!(filters || enablePagination) && (
        <Controls>
          <Filters>{filters}</Filters>
          {enablePagination && (
            <Pagination>
              <Button
                variant="primary"
                onClick={onPrevPage}
                disabled={currentPage === 1}
                size="S"
              >
                {'<'}
              </Button>
              <div>
                {currentPage} / {totalPages}
              </div>
              <Button
                variant="primary"
                onClick={onNextPage}
                disabled={currentPage === totalPages}
                size="S"
              >
                {'>'}
              </Button>
            </Pagination>
          )}
        </Controls>
      )}

      <StyledTable
        cols={cols}
        style={tableStyle}
        addMenuColumn={addMenuColumn}
        addSelectionColumn={addSelectionColumn}
      >
        {children}
      </StyledTable>
    </div>
  );
};

const StyledTableHeader = styled.div<{ paddingX?: number }>`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: start / end;
  background-color: ${(p) => p.theme.surface.secondary};
  color: ${(p) => p.theme.text.subtext};
  align-items: center;

  > div {
    padding: 9px ${(p) => p.paddingX || 24}px;
    min-height: 45px;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: start / end;
`;

const StyledCell = styled.div<{ size?: number; paddingX?: number }>`
  grid-column: span ${(p) => p.size || 1} / span ${(p) => p.size || 1};
  color: ${(p) => p.theme.text.body};
  padding: 18px ${(p) => p.paddingX || 24}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
`;

const StyledSelectionCell = styled(StyledCell)`
  padding: 18px 4px 18px 15px !important;
`;

const StyledActionsCell = styled(StyledCell)`
  padding: 18px 15px 18px 4px !important;
`;

export {
  Table,
  StyledTableHeader as TableHeader,
  StyledRow as TableRow,
  StyledCell as TableCell,
  StyledSelectionCell as SelectionCell,
  StyledActionsCell as ActionsCell,
};
