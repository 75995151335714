const files = {
  dashboard: () => `/files`,
  organizationRecent: (organizationId: string) =>
    `/files/${organizationId}/recent`,
  folder: (folderId: string) => `/files/folder/${folderId}`,
  organizationDraftFolder: (organizationId: string) =>
    `/files/organization/${organizationId}/drafts`,
  team: (id: string) => `/files/team/${id}`,
};

const auth = {
  home: () => `/auth`,
  signIn: () => `/auth?step=signIn`,
  signUp: () => `/auth?step=signUp`,
  onBoarding: () => `/auth?step=onBoarding`,
  registerWithToken: (token: string) => `/auth/register/token#token=${token}`,
};

const admin = {
  dashboard: () => '/admin',
  featureFlags: () => '/admin/flags',
  organizations: () => '/admin/orgs',
  customModels: () => '/admin/custommodels',
  users: () => '/admin/users',
  changelog: () => '/admin/changelog',
};

const eduAdmin = {
  organizations: () => '/admin/education/organizations',
};

const workbench = {
  file: (id: string) => `/workbench/${id}`,
  new: (folderId: string) => `/workbench/folder/${folderId}/new`,
  compositeSceneEditor: (workbenchId: string, compositeSceneId: string) =>
    `/workbench/${workbenchId}/3d/${compositeSceneId}`,
  drawing: (workbenchId: string, drawingId: string) =>
    `/workbench/${workbenchId}/drawing/${drawingId}`,
  newPalette: (workbenchId: string) => `/workbench/${workbenchId}/palette/new`,
};

const settings = {
  account: {
    profile: () => `/settings/account/profile`,
  },
  organization: {
    general: (id: string) => `/settings/organization/${id}/general`,
    members: (id: string) => `/settings/organization/${id}/members`,
    membersInvite: (id: string) =>
      `/settings/organization/${id}/members/invite`,
    membersSubscriptionUpdate: (id: string) =>
      `/settings/organization/${id}/members/subscription/update`,
    teams: (id: string) => `/settings/organization/${id}/teams`,
    createTeam: (id: string) => `/settings/organization/${id}/teams/create`,
    teamSettings: (orgId: string, teamId: string) =>
      `/settings/organization/${orgId}/teams/${teamId}/settings`,
    teamInvite: (orgId: string, teamId: string) =>
      `/settings/organization/${orgId}/teams/${teamId}/invite`,
    subscription: (id: string) => `/settings/organization/${id}/subscription`,
    subscriptionUpdate: (
      id: string,
      plan?: 'PRO' | 'ENTERPRISE' | 'FREE' | 'EDU',
      interval?: 'month' | 'year'
    ) =>
      `/settings/organization/${id}/subscription/update?plan=${plan}&interval=${interval}`,
    security: (id: string) => `/settings/organization/${id}/security`,
    accessLog: (id: string) => `/settings/organization/${id}/data/access`,
    activityLog: (id: string) => `/settings/organization/${id}/data/activity`,
    data: (id: string) => `/settings/organization/${id}/data`,
    palette: (id: string) => `/settings/organization/${id}/palette`,
  },
  app: {
    changelog: () => `/settings/app/changelog`,
  },
};

const organization = {
  acceptInvite: (organizationId: string) =>
    `/organization/${organizationId}/acceptinvite`,
  create: () => `/organization/create`,
  create_name: () => `/organization/create/name`,
};

const onboarding = {
  profile: () => `/onboarding/profile`,
};

const mobileWorkbench = {
  uploadToWorkbench: (workbenchId: string) =>
    `/mobile-workbench/${workbenchId}/upload`,
  uploadToStudio: (workbenchId: string, drawingId: string) =>
    `/mobile-workbench/${workbenchId}/drawing/${drawingId}/upload`,
};

export const paths = {
  files,
  auth,
  admin,
  settings,
  workbench,
  mobileWorkbench,
  organization,
  onboarding,
  eduAdmin,
};
