import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const PaywallEventTypeZodEnum = z.enum(['IMG2IMG_PAYWALL_DISPLAYED']);

export const Img2ImgPaywallEventName = PaywallEventTypeZodEnum.Enum;

export const Img2ImgPaywallTrackingEventSchema = BaseTrackingEventSchema.extend(
  {
    type: PaywallEventTypeZodEnum,
    data: z.object({
      location: z.enum(['img2img-prompt']),
      action: z.enum(['prompt-count']),
    }),
  }
);
