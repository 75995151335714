import { useMutation } from 'urql';

import { graphql } from '../../gql';

const UpdateOrganizationInviteMutation = graphql(/* GraphQL */ `
  mutation UpdateOrganizationInvite($input: UpdateOrganizationInviteInput!) {
    updateOrganizationInvite(input: $input) {
      organization {
        id
        ...OrganizationInvitesData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useUpdateOrganizationInvite = () =>
  useMutation(UpdateOrganizationInviteMutation);
