import { v4 as uuidv4 } from 'uuid';

import { graphql } from '../gql';
import { CreatePromptInput } from '../gql/graphql';
import { urqlClient } from '../lib/graphql';

const CreatePrompt = graphql(/* GraphQL */ `
  mutation CreatePrompt($input: CreatePromptInput!) {
    createPrompt(input: $input) {
      prompt {
        ...PromptData
      }
    }
  }
`);

export const createPrompt = async (input: Omit<CreatePromptInput, 'id'>) => {
  const promptId = uuidv4();
  const res = await urqlClient
    .mutation(CreatePrompt, {
      input: {
        ...input,
        id: promptId,
      },
    })
    .toPromise();
  if (res.error || !res.data?.createPrompt?.prompt) {
    throw res.error || new Error('Unknown error');
  }

  return res.data.createPrompt.prompt;
};
