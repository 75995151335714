import { Placement } from '@floating-ui/react';
import { omit } from 'lodash';
import { ReactNode, useEffect, memo } from 'react';
import { create } from 'zustand';

import {
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
} from './RichTooltip/RichTooltip';

export enum TooltipNotificationName {
  ExitInferenceTray = 'exit-inference-tray',
}

interface TooltipNotificationState {
  tooltipsDisplayState: { [key in TooltipNotificationName]?: boolean };
  tooltipsDismissedState: { [key in TooltipNotificationName]?: boolean };
  setDisplay: (name: TooltipNotificationName, show: boolean) => void;
  setDismissed: (name: TooltipNotificationName) => void;
  unregisterTooltip: (name: TooltipNotificationName) => void;
}

export const useTooltipNotificationState = create<TooltipNotificationState>(
  (set) => ({
    tooltipsDisplayState: {},
    tooltipsDismissedState: {},
    setDisplay: (name, show) => {
      set((state) => ({
        tooltipsDisplayState: {
          ...state.tooltipsDisplayState,
          [name]: show,
        },
      }));
    },
    setDismissed: (name) => {
      set((state) => ({
        tooltipsDismissedState: {
          ...state.tooltipsDismissedState,
          [name]: true,
        },
      }));
    },
    unregisterTooltip: (name) => {
      set((state) => ({
        tooltipsDisplayState: omit(state.tooltipsDisplayState, name),
        tooltipsDismissedState: omit(state.tooltipsDismissedState, name),
      }));
    },
  })
);

interface TooltipNotificationProps {
  children: ReactNode;
  message: string;
  placement?: Placement;
  name: TooltipNotificationName;
  durationVisible?: number;
  autoTriggerAfter?: number;
  startTimerCondition?: boolean;
}

/**
 * TooltipNotification
 *
 * A component that shows a tooltip after a delay and for a specific duration.
 * Used for showing non-critical guidance to users, like explaining UI features
 * or suggesting next steps.
 *
 * Common use cases:
 * - Showing "click X to exit" after user completes an action
 * - Teaching new features after user encounters them
 * - Providing contextual help after a delay
 *
 * Behavior:
 * 1. Waits for startTimerCondition to be true (e.g., images loaded)
 * 2. Delays showing tooltip by autoTriggerAfter milliseconds
 * 3. Shows tooltip for durationVisible milliseconds
 * 4. Remembers when user dismisses tooltip to prevent reshowing
 *
 * Example:
 * ```tsx
 * <TooltipNotification
 *   name={TooltipNotificationName.ExitInferenceTray}
 *   message="Click to exit generation preview"
 *   autoTriggerAfter={30_000}
 *   startTimerCondition={imagesLoaded}
 * >
 *   <Button><CloseIcon /></Button>
 * </TooltipNotification>
 * ```
 */
const TooltipNotificationComponent = ({
  children,
  message,
  name,
  autoTriggerAfter,
  placement = 'top',
  durationVisible = 10_000,
  startTimerCondition = true,
}: TooltipNotificationProps) => {
  const setDisplay = useTooltipNotificationState((s) => s.setDisplay);
  const isDismissed = useTooltipNotificationState((s) =>
    Boolean(s.tooltipsDismissedState[name])
  );
  const isVisible =
    useTooltipNotificationState((s) => Boolean(s.tooltipsDisplayState[name])) &&
    !isDismissed;
  const unregisterTooltip = useTooltipNotificationState(
    (s) => s.unregisterTooltip
  );

  // Main timer effect that handles both showing and hiding the tooltip
  useEffect(() => {
    // Don't start timers if conditions aren't met or tooltip was dismissed
    if (!startTimerCondition || isDismissed) {
      return;
    }

    // Timer to show the tooltip after delay
    const showTimer = setTimeout(() => {
      setDisplay(name, true);
    }, autoTriggerAfter);

    // Timer to hide the tooltip after it's been visible for durationVisible
    const hideTimer = setTimeout(() => {
      setDisplay(name, false);
    }, (autoTriggerAfter ?? 0) + durationVisible);

    // Cleanup timers when component unmounts or conditions change
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [startTimerCondition, isDismissed]);

  // Cleanup global state on unmount
  useEffect(() => () => unregisterTooltip(name), []);

  return (
    <RichTooltip placement={placement} manualOpen={isVisible}>
      <RichTooltipTrigger>{children}</RichTooltipTrigger>
      <RichTooltipContent style={{ color: 'white' }} $background="primary">
        {message}
      </RichTooltipContent>
    </RichTooltip>
  );
};

export const TooltipNotification = memo(TooltipNotificationComponent);
