import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

export default [
  BaseTrackingEventSchema.extend({
    type: z.enum(['PLAN_CANCELLATION_INTENT']),
    data: z.object({
      organizationId: z.string(),
      effectiveDate: z.date(),
      downgradeFeedback: z.object({
        reasons: z.array(z.string()),
        comment: z.string().optional(),
      }),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['PLAN_CANCELLATION_SCHEDULED', 'PLAN_CANCELLED']),
    data: z.object({
      organizationId: z.string(),
      effectiveDate: z.date(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['PRO_SUBSCRIPTION_INTENT', 'SUBSCRIBE_TO_PRO']),
    data: z.object({
      organizationId: z.string(),
      priceId: z.string(),
      seats: z.number(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum([
      'BILLING_INTERVAL_UPDATED',
      'BILLING_INTERVAL_UPDATE_SCHEDULED',
    ]),
    data: z.object({
      organizationId: z.string(),
      interval: z.enum(['month', 'year']),
      effectiveDate: z.date().optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['SEATS_ADDED', 'SEAT_REMOVAL_SCHEDULED', 'SEATS_REMOVED']),
    data: z.object({
      organizationId: z.string(),
      prevSeatsCount: z.number(),
      newSeatsCount: z.number(),
      effectiveDate: z.date().optional(),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['USERS_DOWNGRADED_TO_VIEWER']),
    data: z.object({
      organizationId: z.string(),
      userIds: z.array(z.string()),
    }),
  }),

  BaseTrackingEventSchema.extend({
    type: z.enum(['INVITES_DOWNGRADED_TO_VIEWER']),
    data: z.object({
      organizationId: z.string(),
      inviteEmails: z.array(z.string()),
    }),
  }),
];
