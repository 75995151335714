import { Link } from 'react-router-dom';
import {
  OrganizationData,
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  InlineFlex,
  BlockStack,
  Divider,
  CheckIcon,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  PlanContainer,
  PlanPriceText,
  PlanCta,
  ContactSalesButton,
} from './OrganizationPlansTable';

export const StarterPlanPricing = (props: {
  organization: OrganizationData;
  subscriptionData: OrganizationSubscriptionData;
}) => {
  const { organization, subscriptionData } = props;
  const { subscriptionPlan: currentPlan, id: organizationId } = organization;

  const active = currentPlan === OrganizationSubscriptionPlan.Free;
  const downgradeUrl = `${paths.settings.organization.subscriptionUpdate(
    organizationId,
    OrganizationSubscriptionPlan.Free
  )}`;

  return (
    <PlanContainer $active={active}>
      <BlockStack $justifyContent="space-between">
        <BlockStack $gap={20}>
          <Text type="h2">Starter</Text>
          <InlineFlex $alignItems="baseline">
            <PlanPriceText>$0</PlanPriceText>
            <Text type="b1" color="subtext">
              /month
            </Text>
          </InlineFlex>
          <Text type="b2">
            Great for hobbyists and individuals working solo
          </Text>
        </BlockStack>

        <PlanCta>
          {active ? (
            <Button variant="secondary" disabled>
              Current plan
            </Button>
          ) : currentPlan === OrganizationSubscriptionPlan.Enterprise ? (
            <ContactSalesButton />
          ) : subscriptionData.subscription.cancelAtPeriodEnd ? (
            <Button variant="secondary" disabled>
              Downgrade
            </Button>
          ) : currentPlan === OrganizationSubscriptionPlan.Edu ? (
            <Button variant="secondary" disabled>
              Downgrade
            </Button>
          ) : (
            <Button variant="secondary" as={Link} to={downgradeUrl}>
              Downgrade
            </Button>
          )}
        </PlanCta>
      </BlockStack>

      <Divider style={{ marginBlock: 25 }} />

      <BlockStack $gap={15}>
        <Text type="sh2">Starter includes:</Text>

        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">1 seat max</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Unlimited personal files</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Unlimited link sharing</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Unlimited file viewers</Text>
        </InlineFlex>
      </BlockStack>
    </PlanContainer>
  );
};
