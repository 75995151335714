import { useMutation } from 'urql';

import { graphql } from '../gql';

export const UpdateLayerMutation = graphql(/* GraphQL */ `
  mutation UpdateLayer($id: UUID!, $patch: LayerPatch!) {
    updateLayer(input: { id: $id, patch: $patch }) {
      layer {
        ...LayerData
      }
    }
  }
`);

export const useUpdateLayer = () => {
  return useMutation(UpdateLayerMutation);
};
