import React, { HTMLAttributes, ReactNode, useState, useEffect } from 'react';
import { styled } from '@vizcom/web/common/theme';

export const useTabsTitles = (props: { children: ReactNode }) => {
  const allChildrenProps = React.Children.toArray(props.children).map(
    (child) => {
      if (React.isValidElement(child)) {
        if (!child.key) {
          throw new Error('Tab must have a key prop');
        }
        return {
          key: child.key,
          title: child.props.title,
          node: child,
        };
      }
      return null;
    }
  );

  const allValidTabs = allChildrenProps.filter((child) => child !== null);

  return {
    defaultSelectedKey: allValidTabs[0]?.key.toString() ?? null,
    tabs: allValidTabs,
  };
};

export const useTabs = (props: { children: ReactNode[] }) => {
  const { tabs, defaultSelectedKey } = useTabsTitles(props);
  const [selected, setSelected] = useState<string | null>(defaultSelectedKey);

  useEffect(() => {
    setSelected(defaultSelectedKey);
  }, [defaultSelectedKey]);

  return {
    tabs,
    selectedKey: selected,
    setSelected,
    selectedNode: tabs.find((tab) => tab?.key === selected) ?? null,
  };
};

/**
 * @example
 * <Tabs>
 *    <Tab key="rome" title="Founding of Rome">
        Arma virumque cano, Troiae qui primus ab oris.
      </Tab>
      <Tab key="republic" title="Monarchy and Republic">
        Senatus Populusque Romanus.
      </Tab>
      <Tab key="empire" title="Empire">
        Alea jacta est.
      </Tab>
</Tabs>
 */
export function Tabs({
  children,
  activeKey,
  onChange,
  ...divProps
}: {
  children: ReactNode[];
  activeKey?: string;
  onChange?: (key: string) => void;
} & HTMLAttributes<HTMLDivElement>) {
  const { tabs, selectedKey, setSelected, selectedNode } = useTabs({
    children,
  });

  useEffect(() => {
    // If activeKey prop is provided, update the selected tab
    if (activeKey) {
      setSelected(activeKey);
    }
  }, [activeKey]);

  const handleTabClick = (key: string) => {
    // Update the selected tab when a tab header is clicked
    setSelected(key);
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <TabsWrapper {...divProps}>
      <TabHeaderList>
        {tabs.map((tab) =>
          tab ? (
            <TabHeader
              key={tab.key}
              onClick={() => handleTabClick(tab.key?.toString())}
              isActive={tab.key === selectedKey}
            >
              {tab.title}
            </TabHeader>
          ) : null
        )}
      </TabHeaderList>
      {selectedNode?.node}
    </TabsWrapper>
  );
}

export type TabProps = {
  key: string;
  title: string;
  children: ReactNode;
};

export const Tab = styled.div<TabProps>`
  flex: 1;
  display: flex;
  overflow-y: auto;
  ${({ theme }) => theme.scrollbar.light}
`;

// internal components

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
const TabHeaderList = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.surface.tertiary};
`;

// a tab header
const TabHeader = styled.div<{ isActive: boolean }>`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.deprecated.white};
  cursor: pointer;
  border-bottom: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.deprecated.white : 'transparent'};

  &:hover {
    color: ${({ theme }) => theme.text.subtext};
  }
`;
