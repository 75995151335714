import React from 'react';
import styled from 'styled-components';

import { hsvaToHslString } from '../utils/convert';
import { clamp, round } from '../utils/utils';
import { Interactive, Interaction, Pointer } from './Interactive';

interface HueProps {
  className?: string;
  hue: number;
  onChange: (newHue: { h: number }) => void;
}

const HueBase = ({ hue, onChange, className }: HueProps) => {
  const handleMove = (interaction: Interaction) => {
    onChange({ h: 360 * interaction.left });
  };

  const handleKey = (offset: Interaction) => {
    // Hue measured in degrees of the color circle ranging from 0 to 360
    onChange({
      h: clamp(hue + offset.left * 360, 0, 360),
    });
  };

  return (
    <Container className={className}>
      <Interactive
        onMove={handleMove}
        onKey={handleKey}
        aria-label="Hue"
        aria-valuenow={round(hue)}
        aria-valuemax="360"
        aria-valuemin="0"
      >
        <Pointer
          top={0.5}
          left={hue / 360}
          color={hsvaToHslString({ h: hue, s: 100, v: 100, a: 1 })}
        />
      </Interactive>
    </Container>
  );
};

export const Hue = React.memo(HueBase);

const Container = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );

  & .pointer {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;
