import { useQuery } from 'urql';

import { graphql } from '../gql';

export const teamById = graphql(/* GraphQL */ `
  query team($id: UUID!) {
    team(id: $id) {
      ...TeamData
    }
  }
`);

export const useTeam = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamById,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};

const teamByIdMembers = graphql(/* GraphQL */ `
  query teamMembers($id: UUID!) {
    team(id: $id) {
      ...TeamData
      ...TeamMembers
    }
  }
`);
export const useTeamMembers = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamByIdMembers,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};

const teamMemberCount = graphql(/* GraphQL */ `
  query teamMemberCount($id: UUID!) {
    team(id: $id) {
      id
      name
      globalInOrganization
      members {
        totalCount
      }
    }
  }
`);

export const useTeamMemberCount = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: teamMemberCount,
    variables: { id: id! },
    pause: !id,
  });

  return { ...res, data: res.data?.team };
};
