import { useMutation } from 'urql';

import { graphql } from '../../gql';

const CreateWorkbenchMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbench($input: CreateWorkbenchInput!) {
    createWorkbench(input: $input) {
      workbench {
        ...WorkbenchBasicData
      }
    }
  }
`);

export const useCreateWorkbench = () => useMutation(CreateWorkbenchMutation);
