import styled, { DefaultTheme, css } from 'styled-components';
import { InlineFlex } from '@vizcom/shared-ui-components';

interface InputStylesProps {
  $background?: keyof DefaultTheme['surface'];
  $hasIcon?: boolean;
}

export const textInputStyle = css<InputStylesProps>`
  width: 100%;
  padding: 9px 15px; // 10px 16px with border
  padding-left: ${({ $hasIcon }) => ($hasIcon ? '28px' : '15px')};

  border: 1px solid
    ${({ theme, $background }) => theme.surface[$background || 'secondary']};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  outline: 0;
  background-color: ${({ theme, $background }) =>
    theme.surface[$background || 'secondary']};

  color: ${({ theme }) => theme.text.body};
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;

  ::placeholder {
    color: ${({ theme }) => theme.text.subtext};
  }

  :focus-within {
    // use focus-within and not focus to reuse this style in EmailMultiInput
    border-color: ${({ theme }) => theme.border.action};
  }

  :hover:not(:focus-within) {
    background-color: ${({ theme }) => theme.surface.tertiary};
    border-color: ${({ theme }) => theme.surface.tertiary};
  }

  transition: 250ms border-color ease, 250ms background-color ease;
  color-scheme: dark;
`;

export const TextInput = styled.input<InputStylesProps>`
  ${textInputStyle}

  height: 32px;
`;

const IconContainer = styled.span`
  position: absolute;
  left: 10px;
  color: ${({ theme }) => theme.text.subtext};
`;

type TextInputIconProps = InputStylesProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    icon: React.ReactNode;
    style?: React.CSSProperties;
  };

export const TextInputIcon = ({ icon, ...props }: TextInputIconProps) => {
  return (
    <InlineFlex style={{ position: 'relative' }}>
      <IconContainer>{icon}</IconContainer>
      <TextInput $hasIcon={!!icon} {...props} />
    </InlineFlex>
  );
};
