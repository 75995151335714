import { Cache, DataFields } from '@urql/exchange-graphcache';

import { paginatedDrawingPromptsById } from '../queries/drawings';

export const updateLastPaginatedPromptQuery = (
  drawingId: string,
  res: DataFields,
  cache: Cache
) => {
  cache.updateQuery(
    {
      query: paginatedDrawingPromptsById,
      variables: { id: drawingId, cursor: null, count: 20 },
    },
    (data) => {
      if (!data || !data.drawing) {
        return data;
      }

      data.drawing.prompts.nodes = [
        (res.createPrompt as any)?.prompt,
        ...data.drawing.prompts.nodes,
      ];
      return data;
    }
  );
};
