import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const MeshFragment = graphql(/* GraphQL */ `
  fragment MeshData on Mesh {
    id
    name
    thumbnailPath
    path
  }
`);

export type Mesh = ResultOf<typeof MeshFragment>;
