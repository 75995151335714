import React, { useCallback, useState, useEffect } from 'react';

import { NumberInput } from '../../NumberInput';
import { TextInput } from '../../TextInput/TextInput';
import { useStableCallback } from '../../hooks/useStableCallback';
import { ColorInputHTMLAttributes, ColorInputBaseProps } from '../utils/types';
import { validHex } from '../utils/utils';

interface Props extends ColorInputBaseProps {
  /** Blocks typing invalid characters and limits string length */
  escape: (value: string) => string;
  /** Checks that value is valid color string */
  validate: (value: string) => boolean;
  /** Processes value before displaying it in the input */
  format?: (value: string) => string;
  /** Processes value before sending it in `onChange` */
  process?: (value: string) => string;
}

export const ColorStringInput = ({
  color = '',
  onChange,
  onBlur,
  escape,
  validate,
  format,
  process,
  ...rest
}: Props): JSX.Element => {
  const [value, setValue] = useState(() => escape(color));
  const onChangeCallback = useStableCallback(onChange!);
  const onBlurCallback = useStableCallback(onBlur!);

  // Trigger `onChange` handler only if the input value is a valid color
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = escape(e.target.value);
      setValue(inputValue);
      if (validate(inputValue))
        onChangeCallback(process ? process(inputValue) : inputValue);
    },
    [escape, process, validate, onChangeCallback]
  );

  // Take the color from props if the last typed color (in local state) is not valid
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!validate(e.target.value)) setValue(escape(color));
      onBlurCallback(e);
    },
    [color, escape, validate, onBlurCallback]
  );

  // Update the local state when `color` property value is changed
  useEffect(() => {
    setValue(escape(color));
  }, [color, escape]);

  return (
    <TextInput
      {...rest}
      value={format ? format(value) : value}
      spellCheck="false" // the element should not be checked for spelling errors
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(e) => e.target.select()}
    />
  );
};

export const HexColorInput = (props: ColorInputBaseProps): JSX.Element => {
  /** Escapes all non-hexadecimal characters including "#" */
  const escape = useCallback(
    (value: string) => value.replace(/([^0-9A-F]+)/gi, '').substring(0, 6),
    []
  );
  const validate = useCallback((value: string) => validHex(value), []);

  return <ColorStringInput {...props} escape={escape} validate={validate} />;
};

export const ColorComponentsInput = ({
  values,
  config,
  onChange,
  ...rest
}: {
  values: number[];
  config: { min: number; max: number; unit: string }[];
  onChange: (value: number[]) => void;
} & ColorInputHTMLAttributes) => {
  const handleOnChange = (componentValue: number, componentIndex: number) => {
    const newValues = [...values];
    newValues[componentIndex] = componentValue;
    onChange(newValues);
  };
  return (
    <div {...rest}>
      {values.map((value, i) => (
        <NumberInput
          key={i}
          dragArrows={false}
          step={1}
          min={config[i].min}
          max={config[i].max}
          unit={config[i].unit}
          value={Number(value)}
          setValue={(value) => handleOnChange(value, i)}
        />
      ))}
    </div>
  );
};
