import { useQuery } from 'urql';

import { graphql } from '../gql';

export const ASSETS_LIBRARY_PAGE_SIZE = 10;

export enum LibraryType {
  Workbench = 'workbench',
  Personal = 'personal',
  Organization = 'organization',
}

/*
 * Workbench Colors
 */
export const WorkbenchAssetColors = graphql(/* GraphQL */ `
  query workbenchAssetColors($workbenchId: UUID!, $first: Int, $after: Cursor) {
    workbench(id: $workbenchId) {
      id
      workbenchAssetColors(
        first: $first
        after: $after
        orderBy: CREATED_AT_DESC
      ) {
        nodes {
          ...WorkbenchAssetColorData
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

export const useWorkbenchAssetsColors = (
  workbenchId: string,
  cursor: string | null,
  pause: boolean = false
) => {
  const [res] = useQuery({
    query: WorkbenchAssetColors,
    variables: {
      workbenchId,
      first: ASSETS_LIBRARY_PAGE_SIZE,
      after: cursor,
    },
    pause: !workbenchId || pause,
  });

  const workbenchAssetColors = res?.data?.workbench?.workbenchAssetColors;

  return {
    ...res,
    data: workbenchAssetColors?.nodes || [],
    pageInfo: workbenchAssetColors?.pageInfo,
  };
};

/*
 * Personal Assets
 */
export const PersonalAssetColors = graphql(/* GraphQL */ `
  query personalAssetColors($workbenchId: UUID!, $first: Int, $after: Cursor) {
    workbench(id: $workbenchId) {
      id
      folder {
        id
        organization {
          id
          currentUserDraftFolder {
            id
            folderAssetColors(first: $first, after: $after) {
              pageInfo {
                hasNextPage
                endCursor
              }
              nodes {
                ...FolderAssetColorData
              }
            }
          }
        }
      }
    }
  }
`);

export const usePersonalAssetColors = (
  workbenchId: string,
  cursor: string | null,
  pause: boolean = false
) => {
  const [res] = useQuery({
    query: PersonalAssetColors,
    variables: {
      workbenchId,
      first: ASSETS_LIBRARY_PAGE_SIZE,
      after: cursor,
    },
    pause: !workbenchId || pause,
  });

  const org = res?.data?.workbench?.folder?.organization;
  const userFolder = org?.currentUserDraftFolder;
  const folderAssetColors = userFolder?.folderAssetColors;

  return {
    ...res,
    data: folderAssetColors?.nodes || [],
    pageInfo: folderAssetColors?.pageInfo,
  };
};

/*
 * Organization Assets
 */
export const OrganizationAssetColors = graphql(/* GraphQL */ `
  query organizationAssetColors(
    $workbenchId: UUID!
    $first: Int
    $after: Cursor
  ) {
    workbench(id: $workbenchId) {
      id
      folder {
        id
        organization {
          id
          globalTeam {
            id
            rootFolder {
              id
              folderAssetColors(first: $first, after: $after) {
                pageInfo {
                  hasNextPage
                  endCursor
                }
                nodes {
                  ...FolderAssetColorData
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const useOrganizationAssetColors = (
  workbenchId: string,
  cursor: string | null,
  pause: boolean = false
) => {
  const [res] = useQuery({
    query: OrganizationAssetColors,
    variables: {
      workbenchId,
      first: ASSETS_LIBRARY_PAGE_SIZE,
      after: cursor,
    },
    pause: !workbenchId || pause,
  });

  const org = res?.data?.workbench?.folder?.organization;
  const orgFolder = org?.globalTeam?.rootFolder;
  const folderAssetColors = orgFolder?.folderAssetColors;

  return {
    ...res,
    data: folderAssetColors?.nodes || [],
    pageInfo: folderAssetColors?.pageInfo,
  };
};

/*
 * Workbench Asset Color
 */
export const WorkbenchAssetColor = graphql(/* GraphQL */ `
  query workbenchAssetColor($id: UUID!) {
    workbenchAssetColor(id: $id) {
      ...WorkbenchAssetColorData
      createdAt
      updatedAt
      userByCreatedBy {
        id
        name
        email
      }
    }
  }
`);

export const useWorkbenchAssetColor = (id: string, pause: boolean = false) => {
  const [res] = useQuery({
    query: WorkbenchAssetColor,
    variables: { id },
    pause: pause || !id,
  });

  const assetColor = res.data?.workbenchAssetColor;

  return {
    ...res,
    data: assetColor,
  };
};

/*
 * Folder Asset Color
 */
export const FolderAssetColor = graphql(/* GraphQL */ `
  query folderAssetColor($id: UUID!) {
    folderAssetColor(id: $id) {
      ...FolderAssetColorData
      createdAt
      updatedAt
      userByCreatedBy {
        id
        name
        email
      }
    }
  }
`);

export const useFolderAssetColor = (id: string, pause: boolean = false) => {
  const [res] = useQuery({
    query: FolderAssetColor,
    variables: { id },
    pause: pause || !id,
  });

  const assetColor = res.data?.folderAssetColor;

  return {
    ...res,
    data: assetColor,
  };
};

/*
 * Helpers Hooks
 */
export const useAssetColors = (
  type: LibraryType,
  workbenchId: string,
  cursor: string | null
) => {
  const workbenchAssetsColors = useWorkbenchAssetsColors(
    workbenchId,
    cursor,
    type !== LibraryType.Workbench
  );

  const personalAssetColors = usePersonalAssetColors(
    workbenchId,
    cursor,
    type !== LibraryType.Personal
  );

  const organizationAssetColors = useOrganizationAssetColors(
    workbenchId,
    cursor,
    type !== LibraryType.Organization
  );

  return {
    [LibraryType.Workbench]: workbenchAssetsColors,
    [LibraryType.Personal]: personalAssetColors,
    [LibraryType.Organization]: organizationAssetColors,
  }[type];
};

export const useAssetColor = (type: LibraryType, id: string) => {
  const typeIsWorkbench = type === LibraryType.Workbench;
  const workbenchAssetColor = useWorkbenchAssetColor(id, !typeIsWorkbench);
  const folderAssetColor = useFolderAssetColor(id, typeIsWorkbench);

  return {
    [LibraryType.Workbench]: workbenchAssetColor,
    [LibraryType.Personal]: folderAssetColor,
    [LibraryType.Organization]: folderAssetColor,
  }[type];
};
