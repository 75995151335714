import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow,
  safePolygon,
  useTransitionStyles,
  FloatingArrow,
  useClick,
} from '@floating-ui/react';
import type { Middleware, Placement } from '@floating-ui/react';
import * as React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { useControlledState } from '@vizcom/shared-utils-hooks';

import { FloatingPanel } from '../FloatingPanel/FloatingPanel';
import { useModalContext } from '../Modal/ModalContext';

export const ARROW_SIZE = 10;

export interface TooltipOptions {
  placement?: Placement;
  trigger?: 'hover' | 'click' | 'none';
  padding?: number;
  manualOpen?: boolean;
  delay?: number | { open: number; close: number };
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  customMiddlewares?: Middleware[];
  displayArrow?: boolean;
  controlledOpenState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
}

const noop = () => {};

function useTooltip({
  placement = 'top',
  trigger = 'hover',
  padding,
  manualOpen,
  delay = 0,
  isOpen,
  onOpenChange,
  disabled = false,
  customMiddlewares = [],
  displayArrow = true,
  controlledOpenState,
}: TooltipOptions = {}) {
  const [internalOpen, setInternalOpen] = useControlledState(
    false,
    controlledOpenState
  );
  const open = isOpen ?? internalOpen;
  const setOpen = isOpen !== undefined ? onOpenChange ?? noop : setInternalOpen;

  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open: disabled ? false : manualOpen ?? open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      ...customMiddlewares,
      offset((displayArrow ? ARROW_SIZE : 0) + (padding ?? 0)),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 14,
      }),
      shift({ padding: 14 }),
      displayArrow
        ? arrow({
            element: arrowRef,
          })
        : undefined,
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    handleClose: safePolygon(),
    enabled: trigger === 'hover',
    delay,
  });

  const click = useClick(context, {
    enabled: trigger === 'click',
  });

  const focus = useFocus(context, {});
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([click, hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      displayArrow,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, displayArrow]
  );
}

type ContextType =
  | (ReturnType<typeof useTooltip> & {
      parentsContext: ReturnType<typeof useTooltip>[];
    })
  | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export function RichTooltip({
  children,
  ...options
}: {
  children: React.ReactNode;
  noParentIntegration?: boolean;
} & TooltipOptions) {
  const parent = React.useContext(TooltipContext);
  const parentsContext =
    parent && !options.noParentIntegration
      ? parent.parentsContext.concat(parent)
      : [];

  // when nesting tooltips, if the parent is open (for example after clicking on the menu), disable the child tooltip
  const parentIsOpen = parentsContext.some((parent) => parent.open);
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({
    ...options,
    isOpen: parentIsOpen ? false : options.isOpen,
  });

  return (
    <TooltipContext.Provider
      value={{
        ...tooltip,
        parentsContext,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
}

export const RichTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>(function TooltipTrigger({ children }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([
    ...context.parentsContext.map(
      (parentContext) => parentContext.refs.setReference
    ),
    context.refs.setReference,
    propRef,
    childrenRef,
  ]);

  if (!React.isValidElement(children)) {
    throw new Error(
      'RichTooltipTrigger children is not a valid element, it should only contain a single element'
    );
  }

  let childProps = context.getReferenceProps({
    ref,
    ...children.props,
    'data-state': context.open ? 'open' : 'closed',
  });
  for (const parentContext of context.parentsContext) {
    // allow nesting multiple tooltips on a single TooltipTrigger, for example to have a tooltip on hover and also a menu on click
    childProps = parentContext.getReferenceProps({
      ref,
      ...children.props,
      ...childProps,
      'data-state': context.open ? 'open' : 'closed',
    });
  }

  // `asChild` allows the user to pass any element as the anchor
  return React.cloneElement(children, childProps);
});

const RichTooltipContainer = styled(FloatingPanel)<{
  $backgroundColor: string;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 8px;
`;

const RichTooltipArrow = (props: { backgroundColor: string }) => {
  const context = useTooltipContext();

  // Auto position the arrow at the center of the tooltip, except when positioning it at the start or end of a side.
  const staticOffset =
    context.placement.includes('start') || context.placement.includes('end')
      ? 15
      : null;

  return (
    <FloatingArrow
      ref={context.arrowRef}
      context={context.context}
      width={ARROW_SIZE * 1.5}
      height={ARROW_SIZE}
      style={{
        fill: props.backgroundColor,
      }}
      tipRadius={1}
      staticOffset={staticOffset}
    />
  );
};

export const RichTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    $background?: keyof DefaultTheme['surface'] | 'primary';
    closeOnClick?: boolean;
    renderArrow?: () => React.ReactNode;
    renderContainer?: (
      props: React.HTMLProps<HTMLDivElement>,
      tooltipOptions: { close: () => void }
    ) => React.ReactNode;
  }
>(function TooltipContent(
  { $background, renderArrow, renderContainer, closeOnClick, ...rest },
  propRef
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const theme = useTheme();

  const arrowX = context.middlewareData.arrow?.x ?? 0;
  const arrowY = context.middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_SIZE / 2;
  const transformY = arrowY + ARROW_SIZE;

  const backgroundColor =
    $background === 'primary'
      ? theme.deprecated.primary.default
      : theme.surface[$background ?? 'primary'];

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    context.context,
    {
      initial: {
        opacity: '0',
        transform: 'scale(0.9)',
      },
      duration: 200,
      common: ({ side }) => ({
        transformOrigin: {
          top: `${transformX}px calc(100% + ${ARROW_SIZE}px)`,
          bottom: `${transformX}px ${-ARROW_SIZE}px`,
          left: `calc(100% + ${ARROW_SIZE}px) ${transformY}px`,
          right: `${-ARROW_SIZE}px ${transformY}px`,
        }[side],
      }),
    }
  );

  const modalContext = useModalContext();

  if (!isMounted) {
    return null;
  }

  return (
    <FloatingPortal
      // when a tooltip is displayed inside a modal, we need to add the floating portal to the modal root element instead of <body>
      // this way we keep zIndex stacking order correct
      root={modalContext?.backdropElement}
    >
      <div
        style={{ ...context.floatingStyles, zIndex: 10 }}
        {...context.getFloatingProps()}
        ref={ref}
        onClick={() => {
          if (closeOnClick) {
            context.setOpen(false);
          }
        }}
      >
        <div style={transitionStyles}>
          {context.displayArrow &&
            (renderArrow?.() ?? (
              <RichTooltipArrow backgroundColor={backgroundColor} />
            ))}
          {renderContainer?.(rest, {
            close: () => context.setOpen(false),
          }) ?? (
            <RichTooltipContainer
              {...(rest as any)}
              $backgroundColor={backgroundColor}
            />
          )}
        </div>
      </div>
    </FloatingPortal>
  );
});
