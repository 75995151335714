import { UseQueryArgs, useQuery } from 'urql';

import { graphql } from '../gql';

export const drawingsByIds = graphql(/* GraphQL */ `
  query drawingsByIds($ids: [UUID!]!) {
    drawings(filter: { id: { in: $ids } }) {
      nodes {
        ...DrawingData
      }
    }
  }
`);

export const drawingById = graphql(/* GraphQL */ `
  query drawingById($id: UUID!) {
    drawing(id: $id) {
      ...DrawingData
    }
  }
`);

export const useDrawing = (
  id: string | undefined | null,
  options: Omit<UseQueryArgs, 'query' | 'variables'> = {}
) => {
  const [res] = useQuery({
    query: drawingById,
    variables: {
      id: id!,
    },
    pause: !id,
    ...options,
  });

  return { ...res, data: res.data?.drawing };
};

export const drawingPromptsById = graphql(/* GraphQL */ `
  query drawingPromptsById($id: UUID!) {
    drawing(id: $id) {
      ...DrawingPrompts
    }
  }
`);

export const useDrawingPrompts = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: drawingPromptsById,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.drawing?.prompts?.nodes };
};

export const paginatedDrawingPromptsById = graphql(/* GraphQL */ `
  query paginatedDrawingPromptsById($id: UUID!, $cursor: Cursor, $count: Int) {
    drawing(id: $id) {
      id
      prompts(orderBy: CREATED_AT_DESC, first: $count, after: $cursor) {
        nodes {
          ...PromptData
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

export const usePaginatedDrawingPrompts = (
  id: string | undefined | null,
  cursor?: string | null,
  count?: number
) => {
  const [res] = useQuery({
    query: paginatedDrawingPromptsById,
    variables: {
      id: id!,
      cursor: cursor ?? null,
      count,
    },
    pause: !id,
  });

  return {
    ...res,
    data: res.data?.drawing?.prompts?.nodes,
    pageInfo: res.data?.drawing?.prompts?.pageInfo,
  };
};

export const layerCountByDrawingIds = graphql(/* GraphQL */ `
  query layerCountByDrawingIds($ids: [UUID!]!) {
    drawings(filter: { id: { in: $ids } }) {
      nodes {
        id
        layers {
          totalCount
        }
      }
    }
  }
`);

export const useLayerCountByDrawingIds = (ids?: string[]) => {
  const [res] = useQuery({
    query: layerCountByDrawingIds,
    variables: {
      ids: ids!,
    },
    pause: !ids || !ids.length,
  });

  return { ...res, data: res.data?.drawings?.nodes };
};

export const drawingMetadataById = graphql(/* GraphQL */ `
  query drawingMetadataById($id: UUID!) {
    drawing(id: $id) {
      id
      createdAt
      prompts(orderBy: CREATED_AT_DESC, first: 1) {
        nodes {
          ...PromptData
          creator {
            id
            name
          }
        }
      }
    }
  }
`);

export const useDrawingMetadata = (id: string | undefined | null) => {
  const [res] = useQuery({
    query: drawingMetadataById,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return { ...res, data: res.data?.drawing };
};
