import { useMutation } from 'urql';

import { graphql } from '../../gql';

const UpdateUserOrganizationMutation = graphql(/* GraphQL */ `
  mutation UpdateUserOrganization($input: UpdateUserOrganizationInput!) {
    updateUserOrganization(input: $input) {
      organization {
        ...OrganizationMembersData
        ...OrganizationSeatsUsageData
      }
    }
  }
`);

export const useUpdateUserOrganization = () =>
  useMutation(UpdateUserOrganizationMutation);
