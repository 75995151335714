import * as z from 'zod';

import { AutoPromptTrackingEventSchema } from './AutoPromptTrackingEventSchema';
import { DrawingTrackingEventSchema } from './DrawingTrackingEventSchema';
import { EnhanceTrackingEventSchema } from './EnhanceTrackingEventSchema';
import { FolderTrackingEventSchema } from './FolderTrackingEventSchema';
import { Img2ImgPaywallTrackingEventSchema } from './Img2ImgPaywallTrackingEventSchema';
import { Inference3dTrackingEventSchema } from './Inference3dTrackingEventSchema';
import InferenceTrackingEventSchema from './InferenceTrackingEventSchema';
import { MagicEraserTrackingEventSchema } from './MagicEraserTrackingEventSchema';
import { NewUserTrackingEventSchema } from './NewUserTrackingEventSchema';
import PaletteTrackingEventSchema from './PaletteTrackingEventSchema';
import { PaywallTrackingEventSchema } from './PaywallTrackingEventSchema';
import { RemoveBackgroundTrackingEventSchema } from './RemoveBackgroundTrackingEventSchema';
import { StudioTrackingEventSchema } from './StudioTrackingEventSchema';
import { SubscriptionErrorsEventSchema } from './SubscriptionErrorsEventSchema';
import SubscriptionPlanEventSchema from './SubscriptionPlanEventSchema';
import { TeamTrackingEventSchema } from './TeamTrackingEventSchema';
import { UserTrackingEventSchema } from './UserTrackingEventSchema';
import { WorkbenchSharingEventSchema } from './WorkbenchSharingEventSchema';
import WorkbenchTrackingEventSchema from './WorkbenchTrackingEventSchema';

export const TrackingEventSchema = z.discriminatedUnion('type', [
  DrawingTrackingEventSchema,
  WorkbenchSharingEventSchema,
  UserTrackingEventSchema,
  TeamTrackingEventSchema,
  FolderTrackingEventSchema,
  StudioTrackingEventSchema,
  PaywallTrackingEventSchema,
  Img2ImgPaywallTrackingEventSchema,
  ...PaletteTrackingEventSchema,
  Inference3dTrackingEventSchema,
  AutoPromptTrackingEventSchema,
  MagicEraserTrackingEventSchema,
  SubscriptionErrorsEventSchema,
  NewUserTrackingEventSchema,
  ...SubscriptionPlanEventSchema,
  ...WorkbenchTrackingEventSchema,
  ...InferenceTrackingEventSchema,
  EnhanceTrackingEventSchema,
  RemoveBackgroundTrackingEventSchema,
]);

export type TrackingEvent = z.infer<typeof TrackingEventSchema>;
