import { z } from 'zod';

import { BaseTrackingEventSchema } from './BaseTrackingEventSchema';

const AutoPromptEventTypeZodEnum = z.enum([
  'AUTOPROMPT_TRIGGERED',
  'AUTOPROMPT_SUCCESS',
]);

export const AutoPromptEventName = AutoPromptEventTypeZodEnum.Enum;

export const AutoPromptTrackingEventSchema = BaseTrackingEventSchema.extend({
  type: AutoPromptEventTypeZodEnum,
  data: z.object({
    prompt: z.string().optional(),
  }),
});
