import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const FolderBasicDataFragment = graphql(/* GraphQL */ `
  fragment FolderBasicData on Folder {
    id
    name: formattedName
    parentId
    updatedAt
    isEditable
    rootFolderOfDraftOrganizationId
    rootFolderOfTeamId
  }
`);

export type BasicFolder = ResultOf<typeof FolderBasicDataFragment>;

export const FolderDataFragment = graphql(/* GraphQL */ `
  fragment FolderData on Folder {
    ...FolderBasicData
    organization {
      id
    }
    rootTeam {
      id
      name
    }
    breadcrumbs {
      nodes {
        ...FolderBasicData
      }
    }
    workbenches {
      totalCount
    }
    childFolders {
      totalCount
      nodes {
        ...FolderBasicData
        workbenches {
          totalCount
        }
      }
    }
  }
`);

export type Folder = ResultOf<typeof FolderDataFragment>;
