import {
  autoUpdate,
  flip,
  useFloating,
  useInteractions,
  useListNavigation,
  useTypeahead,
  useClick,
  useDismiss,
  useRole,
  FloatingFocusManager,
  FloatingList,
  Placement,
  offset,
  size,
  useListItem,
} from '@floating-ui/react';
import { noop } from 'lodash';
import React, {
  useContext,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  CheckIcon,
  FloatingPanel,
  InlineFlex,
  SelectedIndicator,
  Text,
} from '@vizcom/shared-ui-components';

interface SelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number) => void;
}

const SelectContext = createContext<SelectContextValue | null>(null);

export const useSelectContext = () => {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error('Select must be used within SelectContext provider');
  }

  return context;
};

export type CustomSelectTriggerContentProps = {
  selectedIndex: number | null;
  isOpen: boolean;
};

type SelectProps = {
  children: ReactNode;
  onSelectElement: (value: string | null, index: number) => void;
  placement?: Placement;
  selectedOptionIndex?: number | null;
  defaultLabel?: string;
  customSelectedTriggerContent?: React.FC<CustomSelectTriggerContentProps>;
  disabled?: boolean;
  style?: React.CSSProperties;
};

export const Select = ({
  children,
  selectedOptionIndex,
  placement = 'bottom-end',
  onSelectElement,
  defaultLabel = 'Select an option...',
  customSelectedTriggerContent,
  style,
  disabled,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    selectedOptionIndex ?? null
  );
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: disabled ? noop : setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      offset(10),
      /*
       * Set the floating element's width to be at least as wide as the reference element
       * Solution taken from: https://floating-ui.com/docs/size#match-reference-width
       */
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string>>([]);

  const handleSelect = useCallback(
    (index: number) => {
      const selectedOptionValue =
        elementsRef.current[index]?.getAttribute('value') || null;

      setSelectedIndex(index);
      setIsOpen(false);
      setSelectedLabel(labelsRef.current[index]);
      onSelectElement(selectedOptionValue, index);
    },
    [onSelectElement]
  );

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });

  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: (index) => {
      if (isOpen) setActiveIndex(index);
      else handleSelect(index);
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNav, typeahead, click, dismiss, role]
  );

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect]
  );

  return (
    <SelectContainer style={style} disabled={disabled}>
      <SelectTriggerContainer
        $isOpen={isOpen}
        ref={refs.setReference}
        tabIndex={0}
        disabled={disabled}
        {...getReferenceProps()}
      >
        {customSelectedTriggerContent ? (
          customSelectedTriggerContent({ selectedIndex, isOpen })
        ) : (
          <div
            style={{
              padding: '10px 16px 10px 16px',
            }}
          >
            {selectedLabel ?? defaultLabel}
          </div>
        )}
      </SelectTriggerContainer>

      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                <OptionsContainer>{children}</OptionsContainer>
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{ disabled?: boolean }>`
  flex: 1;
  z-index: 100;

  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none !important' : 'auto')};
`;

const SelectTriggerContainer = styled.div<{
  $isOpen: boolean;
  disabled?: boolean;
}>`
  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: ${(p) =>
    p.$isOpen
      ? `2px solid ${p.theme.deprecated.primary.default}`
      : '2px solid transparent'};
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.surface.secondary : theme.surface.tertiary};
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.subtext : theme.text.body};

  :hover {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

const OptionsContainer = styled(FloatingPanel)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 4px;
  background-color: ${(p) => p.theme.surface.secondary};
`;

export const OptionButtonContainer = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 8px;
  cursor: pointer;
  text-align: left;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${(p) =>
    p.$active ? p.theme.surface.tertiary : 'transparent'};
`;

export const OptionComponentBase = ({
  value,
  label,
  children,
  disabled,
}: {
  value: string;
  label: string;
  disabled?: boolean;
  children:
    | React.ReactNode
    | ((props: { isSelected: boolean; label: string }) => React.ReactNode);
}) => {
  const { activeIndex, getItemProps, selectedIndex, handleSelect } =
    useSelectContext();

  const { ref, index } = useListItem({ label: label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <OptionButtonContainer
      ref={ref}
      role="option"
      value={String(value)}
      tabIndex={isActive ? 0 : -1}
      $active={isActive}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
      disabled={disabled}
    >
      {typeof children === 'function'
        ? children({ isSelected, label })
        : children}
    </OptionButtonContainer>
  );
};

export const DefaultOptionComponent = ({
  value,
  label,
  disabled,
}: {
  value: string;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <OptionComponentBase value={value} label={label} disabled={disabled}>
      {({ isSelected }) => (
        <InlineFlex $gap={8} style={{ width: 'fit-content' }}>
          <SelectedIndicator $active={isSelected}>
            <CheckIcon $color="action" />
          </SelectedIndicator>
          <Text>{label}</Text>
        </InlineFlex>
      )}
    </OptionComponentBase>
  );
};
