import { useMemo, MouseEventHandler, useRef, ChangeEventHandler } from 'react';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { boundNumber } from '@vizcom/shared/js-utils';

type RangeInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onMouseUp?: MouseEventHandler<HTMLInputElement>;
  onMouseLeave?: MouseEventHandler<HTMLInputElement>;
  onMouseDown?: MouseEventHandler<HTMLInputElement>;
  onPointerMove?: MouseEventHandler<HTMLInputElement>;
  value: number;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  variant?: 'default' | 'secondary' | 'tertiary' | 'primary' | 'transparent';
  thickness?: 'default' | 'medium-thick' | 'thick'; // height: 'thick' = 24px; 'medium-thick' = 16px; 'default' = 3px track, 16px thumb
  snapCenter?: boolean;
  relativeCenter?: number;
};

export const RangeInput = (props: RangeInputProps) => {
  const {
    onChange,
    onMouseUp,
    onMouseLeave,
    onMouseDown,
    onPointerMove,
    value,
    className,
    min = 0,
    max = 100,
    step = 1,
    disabled = false,
    variant,
    relativeCenter,
    thickness,
    snapCenter,
  } = props;
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);

  const style = useMemo(() => {
    if (variant === 'transparent') {
      return {
        '--track': `0%`,
      };
    }

    const progress = ((boundNumber(min, value, max) - min) / (max - min)) * 100;
    const centerPosition =
      relativeCenter === undefined
        ? 0
        : ((boundNumber(min, relativeCenter, max) - min) / (max - min)) * 100;

    // offset the progress bar to prevent it overtaking the thumb when
    // the range slider is thick
    let remappedProgress = progress;
    let remappedCenter = centerPosition;
    if (thickness === 'thick') {
      remappedProgress =
        progress < max / 4
          ? progress + 2
          : progress > max * 0.75
          ? progress - 2
          : progress;
    } else if (thickness === 'medium-thick') {
      const offsetFromEdge = 10;
      const newMin = offsetFromEdge;
      const newMax = 100 - offsetFromEdge;
      remappedProgress = newMin + (progress / 100) * (newMax - newMin);
      remappedCenter =
        centerPosition === 0 || centerPosition === 100
          ? centerPosition
          : newMin + (centerPosition / 100) * (newMax - newMin);
    }

    const width = Math.abs(remappedProgress - remappedCenter);
    const leftBound = Math.min(remappedCenter, remappedProgress);
    const rightBound = Math.max(remappedCenter, remappedProgress);
    //We need to solve lerp(leftBound, rightBound, x) = lerp(0, 100, x)
    //because of how background position works with percentage values:
    const position = (leftBound / (100 + leftBound - rightBound)) * 100;

    return {
      '--track': `${width}%`,
      '--relativeCenter': `${position}%`,
    };
  }, [min, max, value, thickness, variant]);
  const id = useMemo(() => uuidv4(), []);

  return (
    <div style={{ position: 'relative' }}>
      <Range
        ref={ref}
        $variant={variant || 'default'}
        $thickness={thickness || 'default'}
        $disabled={disabled}
        type="range"
        list={id}
        className={className}
        value={value}
        min={min}
        max={max}
        step={step}
        onInput={onChange}
        onChange={onChange}
        onMouseUp={(e) => {
          ref.current?.blur();
          onMouseUp?.(e);
        }}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onPointerMove={onPointerMove}
        disabled={disabled}
        style={style as React.CSSProperties}
      />
      {snapCenter && !disabled && (
        <>
          <datalist id={id}>
            <option value={String(max / 2)} />
          </datalist>
          <Dot
            style={{
              backgroundColor:
                value >= max / 2
                  ? theme.button.primary
                  : theme.deprecated.secondary.disabled,
            }}
          />
        </>
      )}
    </div>
  );
};

const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 6px;
  left: calc(50% - 6px);
  z-index: 1;
`;

export const Range = styled.input<{
  $variant: 'default' | 'secondary' | 'tertiary' | 'primary' | 'transparent';
  $thickness: 'default' | 'medium-thick' | 'thick';
  $disabled?: boolean;
}>`
  display: block;
  width: 100%;
  height: ${({ $thickness }) =>
    $thickness === 'thick'
      ? '24px'
      : $thickness === 'medium-thick'
      ? '16px'
      : '3px'};
  appearance: none;
  margin: 0;
  background: transparent;
  background-image: ${({ $variant, $disabled, $thickness, theme }) => {
    let color1;

    if ($disabled) {
      color1 = theme.surface.tertiary;
      return `linear-gradient(${color1},${color1})`;
    }

    if ($variant === 'transparent') {
      return 'none';
    }

    switch ($variant) {
      case 'tertiary':
        color1 = theme.deprecated.tertiary.default;
        break;
      default:
        color1 = theme.deprecated.primary.default;
        break;
    }
    let color2;
    switch ($variant) {
      default:
        color2 =
          $thickness === 'medium-thick'
            ? theme.surface.tertiary
            : theme.surface.secondary;
        break;
      case 'secondary':
        color2 = theme.surface.tertiary;
        break;
    }
    return `linear-gradient(${color1},${color1}), linear-gradient(${color2},${color2})`;
  }};
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 50%, 0% 50%;
  border: none;
  background-size: ${({ $thickness, $variant }) => {
    if ($variant === 'transparent') {
      return 'auto';
    }

    const thickness =
      $thickness === 'thick' ? 24 : $thickness === 'medium-thick' ? 16 : 3;
    return `var(--track) ${thickness}px, 100% ${thickness}px;`;
  }};
  background-position: var(--relativeCenter) 0%;

  border-radius: ${({ $thickness, theme }) =>
    $thickness === 'thick'
      ? theme.borderRadius.l
      : $thickness === 'medium-thick'
      ? theme.borderRadius.m
      : '0'};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.25;
  }

  // For touch devices
  @media (hover: none) {
    background-size: var(--track) 5px, 100% 5px;
    background-position: 0 var(--relativeCenter);
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: ${({ $variant, $disabled, theme }) => {
      if ($disabled) {
        return theme.text.bodyDisabled;
      }

      switch ($variant) {
        case 'transparent':
          return 'transparent';
        case 'tertiary':
          return theme.deprecated.tertiary.default;
        case 'primary':
          return theme.deprecated.white;
        default:
          return theme.deprecated.primary.default;
      }
    }};
    border: ${({ $variant, $thickness, theme }) => {
      if ($variant === 'transparent') {
        return `3px solid ${theme.deprecated.white}`;
      }
      if ($thickness === 'thick' || $thickness === 'medium-thick') {
        return `none`;
      }
      switch ($variant) {
        case 'secondary':
          return `1px solid ${theme.border.secondary}`;
        default:
          return `1px solid ${theme.border.primary}`;
      }
    }};
    box-shadow: ${({ $thickness }) =>
      $thickness === 'thick' ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25);' : 'none'};

    height: ${({ $thickness }) =>
      $thickness === 'thick'
        ? '24px'
        : $thickness === 'medium-thick'
        ? '16px'
        : '1rem'};
    width: ${({ $thickness }) =>
      $thickness === 'thick'
        ? '24px'
        : $thickness === 'medium-thick'
        ? '16px'
        : '1rem'};
    border-radius: ${({ $thickness, theme }) =>
      $thickness === 'thick' ? theme.borderRadius.l : theme.borderRadius.m};

    position: relative;
    z-index: 2;

    // For touch devices
    @media (hover: none) {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: ${({ theme }) => theme.borderRadius.l};
    }
  }

  &::-webkit-slider-runnable-track {
    appearance: none;
    background: transparent;
    border: none;
    box-shadow: none;
  }
`;
