import { isValid } from 'date-fns';
import { EducationRequestStatus } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  OrganizationData,
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
  BillingInterval,
  useCreateEducationPlanRequest,
  useOrganizationEducationPlanRequest,
} from '@vizcom/shared/data-access/graphql';
import {
  billingIntervalWording,
  PLAN_PRICES_PER_EDITOR_PER_MONTH_MONTHLY,
  PLAN_PRICES_PER_EDITOR_PER_MONTH_ANNUALLY,
} from '@vizcom/shared/plans-limit';
import {
  Button,
  CheckIcon,
  InlineFlex,
  BlockStack,
  Text,
  RichTooltipTrigger,
  RichTooltip,
  RichTooltipContent,
  HorizontalSwitcher,
  Divider,
  addToast,
  ConfirmationModalComponent,
  formatErrorMessage,
  getCombinedErrorCode,
  triggerConfirmModal,
  useCrisp,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  PlanContainer,
  PlanPriceText,
  PlanCta,
  ContactSalesButton,
  PocChip,
  PocExpirationMessage,
} from './OrganizationPlansTable';
import {
  IncomingPlanChangesMessage,
  shouldShowSeatsSettings,
} from './organizationSubscription.helpers';

export const ProPlanPricing = (props: {
  organization: OrganizationData;
  subscriptionData: OrganizationSubscriptionData;
}) => {
  const theme = useTheme();
  const crisp = useCrisp();

  const { organization, subscriptionData } = props;
  const { subscriptionPlan, poc } = organization;
  const { subscription } = subscriptionData;
  const { nextPhaseSchedule, billingInterval } = subscription;

  const [selectedInterval, setSelectedInterval] = useState(
    subscription.billingInterval || BillingInterval.Year
  );

  const [, createEducationPlanRequest] = useCreateEducationPlanRequest();
  const { data: educationPlanRequest } = useOrganizationEducationPlanRequest(
    organization.id
  );

  const active = subscriptionPlan === OrganizationSubscriptionPlan.Pro;
  const yearlyIntervalSelected = selectedInterval === BillingInterval.Year;
  const wantChangeInterval = billingInterval !== selectedInterval;
  const intervalWillChange =
    nextPhaseSchedule?.interval &&
    nextPhaseSchedule?.interval !== billingInterval;
  const pocEndDate = new Date(organization.pocEndDate as string);
  const isPocExpired = isValid(pocEndDate) && new Date() > pocEndDate;

  const upgradeUrl = `${paths.settings.organization.subscriptionUpdate(
    organization.id,
    OrganizationSubscriptionPlan.Pro,
    selectedInterval
  )}`;

  const handleAskEduAccess = async () => {
    if (educationPlanRequest?.status === EducationRequestStatus.Pending) {
      return addToast('Your EDU Plan request has already been submitted.', {
        secondaryText: 'Please wait while our team reviews it.',
        type: 'warning',
      });
    }

    if (educationPlanRequest?.status === EducationRequestStatus.Rejected) {
      return addToast(
        "Your request for access to Vizcom's EDU Plan was previously submitted and declined, ",
        {
          secondaryText:
            ' as your organization does not meet the established education criteria.',
          type: 'danger',
          duration: 70000,
          cta: { text: 'Contact us', action: () => crisp.openChat() },
        }
      );
    }

    try {
      await triggerConfirmModal({
        body: (onConfirm, onCancel) => (
          <ConfirmationModalComponent
            onConfirm={onConfirm}
            onCancel={onCancel}
            variant="primary"
            title="Ask for a free education access?"
            description="You confirm that you are a student or educator requesting free educational access. Our team will manually review your request."
          />
        ),
      });
    } catch {
      // User clicked cancel
      return;
    }

    const res = await createEducationPlanRequest({
      input: {
        educationPlanRequest: {
          organizationId: organization.id,
        },
      },
    });

    if (res.error) {
      const code = getCombinedErrorCode(res.error);

      if (code === 'NUNIQ') {
        return addToast('Your EDU Plan request has already been submitted.', {
          secondaryText: 'Please wait while our team reviews it.',
          type: 'warning',
        });
      }

      return addToast(
        'Your EDU Plan request could not be created due to an error.',
        {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        }
      );
    }

    return addToast(
      'Your EDU Plan request has been submitted and will be reviewed manually by our team.',
      {
        secondaryText:
          'You will receive an email notification once it has been approved.',
      }
    );
  };

  return (
    <PlanContainer
      $active={active}
      style={{
        gridTemplateRows: '230px min-content auto min-content',
        padding: `${theme.spacing.l} 0 0 0`,
      }}
    >
      <BlockStack
        $justifyContent="space-between"
        style={{ paddingInline: theme.spacing.l }}
      >
        <BlockStack $gap={20}>
          <InlineFlex $gap={10} style={{ height: 20 }}>
            <Text type="h2">Professional</Text>
            {yearlyIntervalSelected && (
              <Text
                style={{
                  backgroundColor: theme.deprecated.primary.default,
                  borderRadius: theme.borderRadius.s,
                  padding: '3px 5px',
                }}
              >
                -20%
              </Text>
            )}
            {active && poc && <PocChip expired={isPocExpired} />}
          </InlineFlex>

          <InlineFlex
            $alignItems={yearlyIntervalSelected ? 'center' : 'baseline'}
          >
            <PlanPriceText>
              $
              {yearlyIntervalSelected
                ? PLAN_PRICES_PER_EDITOR_PER_MONTH_ANNUALLY['PRO']
                : PLAN_PRICES_PER_EDITOR_PER_MONTH_MONTHLY.PRO}
            </PlanPriceText>

            <BlockStack $gap={1}>
              <Text color="subtext">
                per {shouldShowSeatsSettings(subscriptionData) ? 'seat' : ''}
                /month
              </Text>
              {yearlyIntervalSelected && (
                <Text color="subtext">billed annually</Text>
              )}
            </BlockStack>
          </InlineFlex>

          <Text type="b2">Perfect for professionals working solo</Text>

          {active && (
            <PocExpirationMessage
              poc={poc}
              pocEndDate={organization.pocEndDate}
              expired={isPocExpired}
            />
          )}

          <HorizontalSwitcher
            value={selectedInterval}
            onChange={(val) => setSelectedInterval(val as BillingInterval)}
            options={[
              { value: BillingInterval.Month, label: 'Monthly' },
              { value: BillingInterval.Year, label: 'Annually' },
            ]}
          />
        </BlockStack>

        <PlanCta>
          {/* Disabled Current Plan Button */}
          {active && !wantChangeInterval && !intervalWillChange && (
            <Button variant="secondary" size="M" disabled>
              Current plan
            </Button>
          )}

          {/* Disabled Changes Scheduled Button */}
          {active && wantChangeInterval && intervalWillChange && (
            <RichTooltip trigger="hover" displayArrow placement="bottom">
              <RichTooltipTrigger>
                <Button variant="secondary" size="M" disabled>
                  Scheduled
                </Button>
              </RichTooltipTrigger>
              <RichTooltipContent $background="tertiary">
                <IncomingPlanChangesMessage
                  organizationSubscription={subscriptionData}
                />
              </RichTooltipContent>
            </RichTooltip>
          )}

          {/* Revert to annual Button */}
          {active && !wantChangeInterval && intervalWillChange && (
            <Button variant="primary" size="M" as={Link} to={upgradeUrl}>
              Revert to annual
            </Button>
          )}

          {/* Change to Button */}
          {active && wantChangeInterval && !intervalWillChange && (
            <Button variant="secondary" size="M" as={Link} to={upgradeUrl}>
              Change to {billingIntervalWording[selectedInterval]}
            </Button>
          )}

          {/* Upgrade Button */}
          {subscriptionPlan === OrganizationSubscriptionPlan.Free && (
            <Button variant="primary" size="M" as={Link} to={upgradeUrl}>
              Upgrade
            </Button>
          )}

          {/* Contact Sales Button */}
          {(subscriptionPlan === OrganizationSubscriptionPlan.Enterprise ||
            subscriptionPlan === OrganizationSubscriptionPlan.Edu) && (
            <ContactSalesButton />
          )}
        </PlanCta>
      </BlockStack>

      <div style={{ paddingInline: theme.spacing.l }}>
        <Divider style={{ marginBlock: 25 }} />
      </div>

      <BlockStack $gap={15} style={{ paddingInline: theme.spacing.l }}>
        <Text type="sh2">Everything in Starter, plus:</Text>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">1 seat max</Text>
        </InlineFlex>

        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">1 team</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Priority render queue</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Multi-image generation</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">4k image export</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Advanced 3D generation</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Unlimited 3D exports</Text>
        </InlineFlex>
        <InlineFlex>
          <CheckIcon color="white" />
          <Text type="b2">Full design privacy</Text>
        </InlineFlex>
      </BlockStack>

      {subscriptionPlan !== OrganizationSubscriptionPlan.Edu &&
        educationPlanRequest?.status !== EducationRequestStatus.Approved && (
          <InlineFlex
            $justifyContent="space-between"
            style={{
              padding: 15,
              borderTop: `1px solid ${theme.border.primary}`,
            }}
          >
            <Text type="sh2">Free for education</Text>
            <Text
              as={Link}
              to="#"
              color="link"
              type="sh2"
              onClick={handleAskEduAccess}
            >
              Request access
            </Text>
          </InlineFlex>
        )}
    </PlanContainer>
  );
};
