import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  WorkbenchAssetColors,
  ASSETS_LIBRARY_PAGE_SIZE,
  PersonalAssetColors,
  OrganizationAssetColors,
} from '../../queries/assetsLibrary';

type MutationCacheEffectors = {
  [key: string]: UpdateResolver;
};

export const assetsLibraryMutationCacheEffectors: MutationCacheEffectors = {
  createWorkbenchAssetColor: (res, args, cache) => {
    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'workbench')
      .forEach((field) => {
        cache.updateQuery(
          {
            query: WorkbenchAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const colors = data?.workbench?.workbenchAssetColors;
            const newColor = (res.createWorkbenchAssetColor as any)
              ?.workbenchAssetColor;

            if (!colors || !newColor) return data;

            colors.nodes = [newColor, ...colors.nodes];

            return data;
          }
        );
      });
  },
  createFolderAssetColor: (res, args, cache) => {
    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'workbench')
      .forEach((field) => {
        cache.updateQuery(
          {
            query: PersonalAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const newColor = (res.createFolderAssetColor as any)
              ?.folderAssetColor;

            const colors =
              data?.workbench?.folder?.organization?.currentUserDraftFolder
                ?.folderAssetColors;

            if (!colors || !newColor) return data;

            colors.nodes = [newColor, ...colors.nodes];

            return data;
          }
        );

        cache.updateQuery(
          {
            query: OrganizationAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const newColor = (res.createFolderAssetColor as any)
              ?.folderAssetColor;

            const cachedColors =
              data?.workbench?.folder?.organization?.globalTeam?.rootFolder
                ?.folderAssetColors;

            if (!cachedColors || !newColor) return data;

            cachedColors.nodes = [newColor, ...cachedColors.nodes];

            return data;
          }
        );
      });
  },
  deleteWorkbenchAssetColor: (res, args, cache) => {
    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'workbench')
      .forEach((field) => {
        cache.updateQuery(
          {
            query: WorkbenchAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const colors = data?.workbench?.workbenchAssetColors;

            if (!colors) return data;

            const deletedColor = (res.deleteWorkbenchAssetColor as any)
              ?.workbenchAssetColor;

            if (!deletedColor) return data;

            colors.nodes = colors.nodes.filter(
              (color) => color.id !== deletedColor.id
            );

            return data;
          }
        );
      });
  },
  deleteFolderAssetColor: (res, args, cache) => {
    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'workbench')
      .forEach((field) => {
        cache.updateQuery(
          {
            query: PersonalAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const colors =
              data?.workbench?.folder?.organization?.currentUserDraftFolder
                ?.folderAssetColors;

            if (!colors) return data;

            const deletedColor = (res.deleteFolderAssetColor as any)
              ?.folderAssetColor;

            if (!deletedColor) return data;

            colors.nodes = colors.nodes.filter(
              (color) => color.id !== deletedColor.id
            );

            return data;
          }
        );
      });

    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'workbench')
      .forEach((field) => {
        cache.updateQuery(
          {
            query: OrganizationAssetColors,
            variables: {
              workbenchId: field.arguments?.id as string,
              first: ASSETS_LIBRARY_PAGE_SIZE,
            },
          },
          (data) => {
            const colors =
              data?.workbench?.folder?.organization?.globalTeam?.rootFolder
                ?.folderAssetColors;

            if (!colors) return data;

            const deletedColor = (res.deleteFolderAssetColor as any)
              ?.folderAssetColor;

            if (!deletedColor) return data;

            colors.nodes = colors.nodes.filter(
              (color) => color.id !== deletedColor.id
            );

            return data;
          }
        );
      });
  },
};
