/**
 * ⚠️ This component needs to be a tsx file instead of an svg file
 * to allow using instance-specific runtime generated ids.
 * Otherwise, id collisions result in the icon not being rendered
 * after one of the instances has been removed from the DOM.
 */
import { useId } from 'react';

export default function UpgradeSvg() {
  const gradientId = useId();
  const filterId = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g filter={`url(#${filterId})`} transform="translate(.5)">
        <path
          fill={`url(#${gradientId})`}
          d="M12.435 4.833a.344.344 0 0 0-.296-.166H9.346L11.482.5c.066-.1.033-.233 0-.333S11.318 0 11.22 0H7.276a.344.344 0 0 0-.295.167L3.037 7.833c-.065.1-.032.234 0 .334.066.1.165.166.296.166H6.85l-1.873 7.234c-.033.166.033.333.197.4.033.033.099.033.132.033.098 0 .197-.067.263-.167l6.834-10.666a.26.26 0 0 0 .033-.334z"
        />
      </g>
      <linearGradient
        id={gradientId}
        x1="7.74"
        x2="7.74"
        y1="0"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C4CEF" />
        <stop offset="1" stopColor="#DDDDFA" />
      </linearGradient>
      <filter
        id={filterId}
        width="14.222"
        height="20.741"
        x=".629"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2.37" />
        <feGaussianBlur stdDeviation="1.185" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13450_13884"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_13450_13884"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1.185" />
        <feGaussianBlur stdDeviation=".593" />
        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.873674 0 0 0 0 0.983364 0 0 0 0 0.0431664 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect2_innerShadow_13450_13884" />
      </filter>
    </svg>
  );
}
