import { useMutation } from 'urql';

import { graphql } from '../gql';

export const UpdateDrawingMutation = graphql(/* GraphQL */ `
  mutation UpdateDrawing($id: UUID!, $patch: DrawingPatch!) {
    updateDrawing(input: { id: $id, patch: $patch }) {
      drawing {
        ...DrawingData
      }
    }
  }
`);

export const useUpdateDrawing = () => {
  return useMutation(UpdateDrawingMutation);
};
