import styled from 'styled-components';

import { Text } from './Text/Text';
import { InlineFlex } from './helpers';
import { MinusIcon, PlusIcon } from './icons';

export const NumberButtonInput = ({
  value,
  setValue,
  min,
  max,
}: {
  value: number;
  setValue: (value: number) => void;
  min?: number;
  max?: number;
}) => {
  return (
    <NumberInputContainer>
      <InlineFlex $gap={10}>
        <Button onClick={() => setValue(value - 1)} disabled={value === min}>
          <MinusIcon width={15} height={15} />
        </Button>
        <Text>{value}</Text>
        <Button onClick={() => setValue(value + 1)} disabled={value === max}>
          <PlusIcon width={12} height={12} />
        </Button>
      </InlineFlex>
    </NumberInputContainer>
  );
};

const NumberInputContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 4px;

  background-color: ${({ theme }) => theme.surface.secondary};
`;

const Button = styled.button`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;
  color: ${({ theme }) => theme.deprecated.primary.default};
  background-color: transparent;
  height: 24px;
  width: 24px;

  :hover {
    background-color: ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;
