import { createClient as createWSClient } from 'graphql-ws';

import { getAccessToken } from './auth';

let timedOut: any;

const apiBaseUrl =
  import.meta.env.VITE_API_BASE_URL_OVERIDE ?? `${location.origin}/api/v1`;

const wsUrl = `${apiBaseUrl.replace('http', 'ws')}/graphql`;

export const wsClient = createWSClient({
  url: wsUrl,
  connectionParams: () => {
    const accessToken = getAccessToken();
    return { authorization: accessToken ? `Bearer ${accessToken}` : '' };
  },
  retryAttempts: Infinity,
  retryWait: async function waitForServerHealthyBeforeRetry() {
    // wait for a second + random 1-4s timeout (avoid DDoSing ourself) and try connecting again
    await new Promise((resolve) =>
      setTimeout(resolve, 1000 + Math.random() * 3000)
    );
  },
  shouldRetry: () => true,
  keepAlive: 10_000, // ping server every 10 seconds
  on: {
    ping: (received) => {
      if (!received /* sent */) {
        timedOut = setTimeout(() => {
          // a close event `4499: Terminated` is issued to the current WebSocket and an
          // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
          // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
          //
          // calling terminate is not considered fatal and a connection retry will occur as expected
          //
          // see: https://github.com/enisdenjo/graphql-ws/discussions/290
          wsClient.terminate();
        }, 5_000);
      }
    },
    pong: (received) => {
      if (received) {
        clearTimeout(timedOut);
      }
    },
  },
});
