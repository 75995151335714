import React, { ForwardedRef, forwardRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';

import { Button } from '../Button/Button';

export enum NewFeatureName {
  Exit2DStudioToWorkbench = 'exit-2d-studio-to-workbench',
}

const useNewFeature = (featureName: string) => {
  const newFeatures =
    useCurrentUserClientStateByKey(UserClientStateKeys.NewFeatures) || {};
  const [isNewFeature, setIsNewFeature] = useState<boolean>(
    newFeatures[featureName] === undefined ? true : false
  );
  const [, updateState] = useSetCurrentUserClientState();
  const handleUpdateState = async (name: string) => {
    if (newFeatures[name] === false) return;

    await updateState({
      input: {
        key: UserClientStateKeys.NewFeatures,
        value: {
          ...newFeatures,
          [name]: false,
        },
      },
    });
  };

  const handleSetIsNotNewFeature = () => {
    setIsNewFeature(false);
    handleUpdateState(featureName);
  };

  return { isNewFeature, setIsNotNewFeature: handleSetIsNotNewFeature };
};

type NewFeatureButtonProps = {
  featureName: string;
  children: React.ReactNode;
} & React.ComponentProps<typeof Button>;

export const NewFeatureButton = forwardRef(
  (
    { featureName, children, ...props }: NewFeatureButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { isNewFeature, setIsNotNewFeature } = useNewFeature(featureName);
    const theme = useTheme();

    const handleOnClick = () => {
      setIsNotNewFeature();
      props['onClick'] && props['onClick']();
    };

    return (
      <Button
        {...props}
        onClick={handleOnClick}
        style={{ position: 'relative', ...props['style'] }}
        ref={ref}
      >
        {children}
        {isNewFeature && <DotIndicator theme={theme} />}
      </Button>
    );
  }
);

const DotIndicator = styled.div`
  width: 4px;
  height: 4px;
  background: ${(props) => props.theme.surface.danger};
  border-radius: 100px;
  position: absolute;
  top: 4px;
  right: 4px;
`;
