import { FC } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { BaseCta, CTA } from '../Banner/Banner';
import { Button } from '../Button/Button';
import { Spinner } from '../Loader/Loader.styled';
import { Text } from '../Text/Text';
import { CloseIcon, ErrorIcon, WarningIcon, CheckIcon } from '../icons';

type ToastVariant = 'default' | 'loading' | 'success' | 'danger' | 'warning';

interface ButtonCta extends BaseCta {
  Button: FC<React.ComponentProps<typeof Button> & { onDismiss?: () => void }>;
}

export interface ToastProps {
  variant?: ToastVariant;
  icon?: React.ReactNode;
  text: string | JSX.Element;
  secondaryText?: string;
  onDismiss?: () => void;
  cta?: CTA | ButtonCta;
}

export const Toast = ({
  variant = 'default',
  icon: iconProp,
  text,
  secondaryText,
  cta,
  onDismiss,
}: ToastProps) => {
  const theme = useTheme();
  const icon = iconProp ? (
    iconProp
  ) : variant === 'loading' ? (
    <Spinner size={{ width: 16, height: 16 }} />
  ) : variant === 'success' ? (
    <CheckIcon />
  ) : variant === 'danger' ? (
    <ErrorIcon />
  ) : variant === 'warning' ? (
    <WarningIcon />
  ) : null;
  const textColor =
    variant === 'danger'
      ? theme.icon.error
      : variant === 'success'
      ? theme.icon.success
      : theme.text[
          variant === 'default' || variant === 'loading' ? 'body' : variant
        ];
  const canCloseManually =
    onDismiss &&
    (variant === 'warning' || variant === 'danger' || variant === 'loading');

  return (
    <Wrapper style={{ paddingRight: canCloseManually ? '10px' : '18px' }}>
      {icon && <IconWrapper $variant={variant}>{icon}</IconWrapper>}
      <TextWrapper
        style={{
          marginLeft: icon ? 0 : '8px',
          marginRight: cta ? '8px' : 0,
        }}
      >
        {typeof text === 'string' ? (
          <Title type="sh1" $color={textColor}>
            {text}
          </Title>
        ) : (
          text
        )}
        {secondaryText && (
          <Text type="b2" color="subtext">
            {secondaryText}
          </Text>
        )}
      </TextWrapper>
      {cta?.Button ? (
        <cta.Button variant="secondary" onDismiss={onDismiss} />
      ) : cta?.action ? (
        <StyledButton
          variant="secondary"
          onClick={() => {
            cta.action?.();
            onDismiss?.();
          }}
        >
          {cta.text}
        </StyledButton>
      ) : cta?.url ? (
        <Link to={cta.url} target={cta.target || '_self'} onClick={onDismiss}>
          <StyledButton variant="secondary">{cta.text}</StyledButton>
        </Link>
      ) : (
        ''
      )}
      {canCloseManually && (
        <Button variant="tertiary" size="icon" onClick={onDismiss}>
          <CloseIcon />
        </Button>
      )}
    </Wrapper>
  );
};

export const addToast = (
  message: string | JSX.Element,
  options: { id?: string; duration?: number; type?: ToastVariant } & Omit<
    ToastProps,
    'text' | 'onDismiss' | 'variant'
  > = {}
) => {
  return toast(
    (t) => (
      <Toast
        text={message}
        onDismiss={() => toast.dismiss(t.id)}
        variant={options.type}
        {...options}
      />
    ),
    {
      id: options.id ?? uuid(),
      duration:
        options.duration ??
        ((options.type === undefined ||
          options.type === 'default' ||
          options.type === 'success') &&
          options.cta == null)
          ? 5000
          : Infinity,
    }
  );
};

export const dismissToast = (id: string) => {
  toast.dismiss(id);
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
  width: auto;
  height: 56px;
  padding: 10px;

  background-color: ${({ theme }) => theme.surface.primary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  box-shadow: ${({ theme }) => theme.boxShadow.level1};

  color: ${({ theme }) => theme.text.body};
`;

const IconWrapper = styled.div<{ $variant?: ToastVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;
  margin-left: -24px;

  border-radius: ${({ theme }) => theme.borderRadius.round};
  background-color: ${({ theme, $variant }) =>
    $variant === 'success'
      ? theme.icon.success
      : $variant === 'danger'
      ? theme.icon.error
      : $variant === 'warning'
      ? theme.icon.warning
      : theme.surface.primary};
  box-shadow: ${({ theme }) => theme.boxShadow.level1};

  color: ${({ theme, $variant }) =>
    $variant === 'warning' ? theme.toast.warning.ctaColor : 'inherit'};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled(Text)<{ $color?: string }>`
  color: ${({ $color }) => $color};
`;

const StyledButton = styled(Button)`
  ${({ theme, variant }) =>
    variant === 'secondary' && `background-color: ${theme.icon.disabled}`}
`;
