import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  removeAccessToken,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { paths } from '@vizcom/shared-utils-paths';

import { ContextMenu } from '../ContextMenu/ContextMenu';
import {
  ContextMenuDivider,
  ContextMenuItem,
} from '../ContextMenu/ContextMenuItem';
import { Text } from '../Text/Text';
import { UserAvatar } from '../UserAvatar/UserAvatar';

const AccountSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 12px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  width: 100%;
  &:hover {
    background-color: ${(p) => p.theme.surface.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.m};
  }
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 16px;
  line-height: 24px;
  overflow: hidden;
`;

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

export const AccountSettings = () => {
  const { data: user } = useCurrentUser();
  const referenceElementRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  if (!user) {
    return null;
  }

  const handleLogout = () => {
    removeAccessToken();
  };

  return (
    <ContextMenu
      referenceElementRef={referenceElementRef}
      items={
        <>
          <ContextMenuItem as={NavLink} to={paths.settings.account.profile()}>
            Account Settings
          </ContextMenuItem>
          {user?.isAdmin && (
            <>
              <ContextMenuDivider />
              <ContextMenuItem as={NavLink} to={paths.admin.featureFlags()}>
                Feature flags
              </ContextMenuItem>
              <ContextMenuItem as={NavLink} to={paths.admin.organizations()}>
                Manually managed workspaces
              </ContextMenuItem>
              <ContextMenuItem as={NavLink} to={paths.admin.customModels()}>
                Custom models
              </ContextMenuItem>
              <ContextMenuItem as={NavLink} to={paths.admin.users()}>
                Users admin
              </ContextMenuItem>
              <ContextMenuItem as={NavLink} to={paths.admin.changelog()}>
                Changelog
              </ContextMenuItem>
            </>
          )}
          <ContextMenuDivider />
          {user?.isEduAdmin && (
            <>
              <ContextMenuItem as={NavLink} to={paths.eduAdmin.organizations()}>
                Education
              </ContextMenuItem>
              <ContextMenuDivider />
            </>
          )}
          <ContextMenuItem onClick={handleLogout}>Log out</ContextMenuItem>
        </>
      }
      buttonProps={{
        variant: 'bare',
        style: {
          height: 'auto',
          padding: 0,
          width: '100%',
        },
      }}
    >
      <AccountSettingsContainer ref={referenceElementRef}>
        <UserAvatar userId={user.id} name={user.name ?? user.email} />
        <AccountInfo>
          <EllipsisText type="b1" color="body" style={{ marginBottom: 4 }}>
            {user.name ?? user.email}
          </EllipsisText>
          {user.name && (
            <EllipsisText type="b2" color="subtext">
              {user.email}
            </EllipsisText>
          )}
        </AccountInfo>
      </AccountSettingsContainer>
    </ContextMenu>
  );
};
