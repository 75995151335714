import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const WorkbenchBasicDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchBasicData on Workbench {
    id
    name
    folderId
    thumbnail
    sharingSecret
    publicSharingEnabled
    thumbnailDrawingId
    createdAt
    updatedAt
    isEditable
    lastUpdatedBy {
      id
      name
      email
    }
    drawings {
      nodes {
        id
        thumbnailPath
      }
    }
    creator {
      id
      name
      email
    }
    team {
      id
      name
    }
    folder {
      id
      organization {
        id
        publicSharing
      }
    }
  }
`);

export type WorkbenchBasicData = ResultOf<typeof WorkbenchBasicDataFragment>;

export const WorkbenchElementPlaceholderDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementPlaceholderData on WorkbenchElementPlaceholder {
    __typename
    id
    height
    width
    x
    y
    zIndex
    updatedAt
    createdAt
    type
    failureReason
    meshOutputPath
    loadingImagePath
  }
`);
export type WorkbenchElementPlaceholderData = ResultOf<
  typeof WorkbenchElementPlaceholderDataFragment
>;

export const WorkbenchElementImg2ImgDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementImg2ImgData on WorkbenchElementImg2Img {
    __typename
    id
    x
    y
    width
    height
    zIndex
    updatedAt
    prompt
    legacyType
    sourceDrawingId
    imageInferenceType
    publicPaletteId
    customModelId
    workbenchPaletteId
    userPaletteId
    sourceImageInfluences
  }
`);
export type WorkbenchElementImg2ImgData = ResultOf<
  typeof WorkbenchElementImg2ImgDataFragment
>;

export const WorkbenchElementAnimateDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementAnimateData on WorkbenchElementAnimate {
    __typename
    id
    x
    y
    width
    height
    zIndex
    updatedAt
    prompt
    sourceDrawingId
  }
`);
export type WorkbenchElementAnimateData = ResultOf<
  typeof WorkbenchElementAnimateDataFragment
>;

export const WorkbenchElementPaletteDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementPaletteData on WorkbenchElementPalette {
    __typename
    id
    x
    y
    width
    height
    zIndex
    status
    name
    tags
    failureReason
    thumbnailPath
    usageCount
    createdBy
    estimatedTrainingEndTimestamp
  }
`);
export type WorkbenchElementPaletteData = ResultOf<
  typeof WorkbenchElementPaletteDataFragment
>;

export const WorkbenchElementPaletteWithSourceImagesDataFragment =
  graphql(/* GraphQL */ `
    fragment WorkbenchElementPaletteWithSourceImagesData on WorkbenchElementPalette {
      ...WorkbenchElementPaletteData
      sourceImages {
        nodes {
          ...WorkbenchElementPaletteSourceImageData
        }
      }
    }
  `);
export type WorkbenchElementPaletteWithSourceImagesData = ResultOf<
  typeof WorkbenchElementPaletteWithSourceImagesDataFragment
>;

export const WorkbenchElementDrawingDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementDrawingData on Drawing {
    __typename
    id
    name
    drawingHeight: height
    drawingWidth: width
    workbenchSizeRatio
    x
    y
    zIndex
    updatedAt
    thumbnailPath
    contentLayersCount
    source
  }
`);
export type WorkbenchElementDrawingData = ResultOf<
  typeof WorkbenchElementDrawingDataFragment
>;

export const WorkbenchElementVideoDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementVideoData on Video {
    __typename
    id
    x
    y
    width
    height
    zIndex
    updatedAt
    videoPath
    name
    prompt
    sourceImagePath
  }
`);
export type WorkbenchElementVideoData = ResultOf<
  typeof WorkbenchElementVideoDataFragment
>;

export const WorkbenchElementTextDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementTextData on WorkbenchElementText {
    __typename
    id
    contentHeight
    height
    lockWidth
    width
    x
    y
    angle
    zIndex
    updatedAt
    content
    style
    link
  }
`);
export type WorkbenchElementTextData = ResultOf<
  typeof WorkbenchElementTextDataFragment
>;

export const WorkbenchElementMixDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementMixData on WorkbenchElementMix {
    __typename
    id
    sourceDrawings
    height
    width
    x
    y
    zIndex
    updatedAt
  }
`);
export type WorkbenchElementMixData = ResultOf<
  typeof WorkbenchElementMixDataFragment
>;

export const WorkbenchElementSectionDataFragment = graphql(/* GraphQL */ `
  fragment WorkbenchElementSectionData on WorkbenchElementSection {
    __typename
    id
    height
    width
    x
    y
    zIndex
    title
    color
    updatedAt
  }
`);
export type WorkbenchElementSectionData = ResultOf<
  typeof WorkbenchElementSectionDataFragment
>;

export const WorkbenchElementPaletteSourceImageDataFragment =
  graphql(/* GraphQL */ `
    fragment WorkbenchElementPaletteSourceImageData on WorkbenchElementPaletteSourceImage {
      __typename
      id
      imagePath
      workbenchElementPaletteId
    }
  `);
export type WorkbenchElementPaletteSourceImageData = ResultOf<
  typeof WorkbenchElementPaletteSourceImageDataFragment
>;

export const WorkbenchContentFragment = graphql(/* GraphQL */ `
  fragment WorkbenchContent on Workbench {
    ...WorkbenchBasicData
    drawings {
      nodes {
        ...WorkbenchElementDrawingData
      }
    }
    videos {
      nodes {
        ...WorkbenchElementVideoData
      }
    }
    img2imgs {
      nodes {
        ...WorkbenchElementImg2ImgData
      }
    }
    animates {
      nodes {
        ...WorkbenchElementAnimateData
      }
    }
    palettes {
      nodes {
        ...WorkbenchElementPaletteWithSourceImagesData
      }
    }
    placeholders {
      nodes {
        ...WorkbenchElementPlaceholderData
      }
    }
    textElements {
      nodes {
        ...WorkbenchElementTextData
      }
    }
    mixElements {
      nodes {
        ...WorkbenchElementMixData
      }
    }
    sectionElements {
      nodes {
        ...WorkbenchElementSectionData
      }
    }
    compositeScenes {
      nodes {
        ...CompositeSceneData
      }
    }
    folder {
      id
      name: formattedName
      organization {
        id
        subscriptionPlan
        publicSharing
      }
    }
  }
`);
export type WorkbenchContent = ResultOf<typeof WorkbenchContentFragment>;
