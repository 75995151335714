import styled, { DefaultTheme } from 'styled-components';

type ChipVariant = keyof DefaultTheme['chip'];

export const Chip = styled.div<{ variant?: ChipVariant }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;

  background-color: ${({ theme, variant }) =>
    theme.chip[variant ?? 'primary'].background};
  border-radius: ${({ theme }) => theme.borderRadius.s};

  color: ${({ theme, variant }) => theme.chip[variant ?? 'primary'].text};
  white-space: nowrap;

  ${({ theme }) => theme.typography.b2};
`;
