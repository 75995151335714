import { useMutation } from 'urql';

import { AssetColorData } from '../fragments/assetLibraryFragment';
import { graphql } from '../gql';
import { urqlClient } from '../lib/graphql';
import { LibraryType } from '../queries/assetsLibrary';
import { useWorkbenchFoldersIds } from '../queries/workbench';

const CreateWorkbenchColor = graphql(/* GraphQL */ `
  mutation CreateWorkbenchColor($input: CreateWorkbenchAssetColorInput!) {
    createWorkbenchAssetColor(input: $input) {
      workbenchAssetColor {
        ...WorkbenchAssetColorData
      }
    }
  }
`);

const CreateFolderColor = graphql(/* GraphQL */ `
  mutation CreateFolderColor($input: CreateFolderAssetColorInput!) {
    createFolderAssetColor(input: $input) {
      folderAssetColor {
        ...FolderAssetColorData
      }
    }
  }
`);

const DeleteWorkbenchColor = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchColor($input: DeleteWorkbenchAssetColorInput!) {
    deleteWorkbenchAssetColor(input: $input) {
      workbenchAssetColor {
        id
      }
    }
  }
`);

const DeleteFolderColor = graphql(/* GraphQL */ `
  mutation DeleteFolderColor($input: DeleteFolderAssetColorInput!) {
    deleteFolderAssetColor(input: $input) {
      folderAssetColor {
        id
      }
    }
  }
`);

const UpdateWorkbenchColor = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchColor($input: UpdateWorkbenchAssetColorInput!) {
    updateWorkbenchAssetColor(input: $input) {
      workbenchAssetColor {
        ...WorkbenchAssetColorData
      }
    }
  }
`);

const UpdateFolderColor = graphql(/* GraphQL */ `
  mutation UpdateFolderColor($input: UpdateFolderAssetColorInput!) {
    updateFolderAssetColor(input: $input) {
      folderAssetColor {
        ...FolderAssetColorData
      }
    }
  }
`);

export const useCreateWorkbenchColor = () => useMutation(CreateWorkbenchColor);
export const useCreateFolderColor = () => useMutation(CreateFolderColor);
export const useDeleteWorkbenchColor = () => useMutation(DeleteWorkbenchColor);
export const useDeleteFolderColor = () => useMutation(DeleteFolderColor);
export const useUpdateWorkbenchColor = () => useMutation(UpdateWorkbenchColor);
export const useUpdateFolderColor = () => useMutation(UpdateFolderColor);

export const useCreateAssetColor = (type: LibraryType, workbenchId: string) => {
  const [, createWorkbenchColor] = useCreateWorkbenchColor();
  const [, createFolderColor] = useCreateFolderColor();
  const { data: workbench } = useWorkbenchFoldersIds(
    workbenchId,
    type === LibraryType.Workbench
  );

  const folderId =
    type === LibraryType.Personal
      ? workbench.userFolderId!
      : workbench.orgFolderId!;

  return async (color: Omit<AssetColorData, 'id'>) => {
    if (type === LibraryType.Workbench && workbenchId) {
      return await createWorkbenchColor({
        input: {
          workbenchAssetColor: { ...color, workbenchId },
        },
      });
    }

    return await createFolderColor({
      input: {
        folderAssetColor: { ...color, folderId },
      },
    });
  };
};

export const updateAssetColor = async (
  type: LibraryType,
  color: AssetColorData
) => {
  const { id, ...patch } = color;
  const payload = { input: { id, patch } };

  return type === LibraryType.Workbench
    ? await urqlClient.mutation(UpdateWorkbenchColor, payload)
    : await urqlClient.mutation(UpdateFolderColor, payload);
};

export const deleteAssetColor = async (type: LibraryType, colorId: string) => {
  const payload = { input: { id: colorId } };

  return type === LibraryType.Workbench
    ? await urqlClient.mutation(DeleteWorkbenchColor, payload)
    : await urqlClient.mutation(DeleteFolderColor, payload);
};
