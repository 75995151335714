import { useDrag } from '@use-gesture/react';
import {
  MouseEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { boundNumber } from '@vizcom/shared/js-utils';

import { Text } from './Text/Text';
import { CarretDownIcon } from './icons';

const DRAG_RANGE_PX = 200; // Dragging 200px up or down covers the whole NumberInput range

export const NumberInput = forwardRef<
  HTMLDivElement,
  {
    value: number;
    step?: number;
    arrowStep?: number;
    setValue: (v: number) => void;
    onChange?: (v: number) => void;
    min?: number;
    max?: number;
    unit?: string;
    dragArrows?: boolean;
    disabled?: boolean;
    enableGestureSlider?: boolean;
    className?: string;
    style?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
    unitStyle?: React.CSSProperties;
    hoverColor?: string;
    onDragStateChange?: (isDragging: boolean) => void;
  }
>(
  (
    {
      value,
      step = 1,
      setValue,
      onChange,
      min,
      max,
      unit = '%',
      dragArrows = true,
      disabled = false,
      enableGestureSlider = false,
      arrowStep = 10,
      className,
      style,
      valueStyle,
      unitStyle,
      hoverColor,
      onDragStateChange,
    },
    ref
  ) => {
    const [dragOffset, setDragOffset] = useState(0); // Offset while dragging arrows
    const [isEditing, setIsEditing] = useState(false); // Whether the input is being edited
    const [tempInputValue, setTempInputValue] = useState<number | null>(null); // Temporary value while editing
    const [sliderStartValue, setSliderStartValue] = useState(value);
    const theme = useTheme();

    const inputRef = useRef<HTMLInputElement>(null);

    // Handler for dragging arrows
    const arrowDragBind = useDrag(
      ({ event, first, last, delta }) => {
        event.preventDefault();
        event.stopPropagation();

        if (first) {
          onDragStateChange?.(true);
        }

        const valueDiff =
          -1 * (delta[1] / DRAG_RANGE_PX) * ((max ?? 100) - (min ?? 0));
        let offset = dragOffset + valueDiff;
        if (min !== undefined && value + offset < min) {
          offset = min - value;
        }
        if (max !== undefined && value + offset > max) {
          offset = max - value;
        }
        if (last) {
          setValue(Math.round(value + offset));
          onDragStateChange?.(false);
        } else {
          setDragOffset(offset);
          onChange?.(value + offset);
        }
      },
      {
        threshold: 3,
        filterTaps: true,
      }
    );

    // Handler for dragging slider
    const sliderDragBind = useDrag(
      ({ event, first, movement: [mx], last }) => {
        event.preventDefault();
        event.stopPropagation();

        if (first) {
          setSliderStartValue(value);
          onDragStateChange?.(true);
        }

        const range = (max ?? 100) - (min ?? 0);
        const valueDiff = (mx / DRAG_RANGE_PX) * range;
        const newValue = boundNumber(
          min ?? -Infinity,
          sliderStartValue + valueDiff,
          max ?? Infinity
        );

        if (last) {
          setValue(Math.round(newValue / step) * step);
          onDragStateChange?.(false);
        } else {
          onChange?.(Math.round(newValue / step) * step);
        }
      },
      {
        axis: 'x',
        filterTaps: true,
      }
    );

    useEffect(() => {
      setDragOffset(0);
    }, [value]);

    useEffect(() => {
      if (isEditing && inputRef.current) inputRef.current.select();
    }, [isEditing]);

    // Handler for increasing value
    const handleIncrease: MouseEventHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setValue(Math.min(value + arrowStep, max ?? Infinity));
    };

    // Handler for decreasing value
    const handleDecrease: MouseEventHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setValue(Math.max(value - arrowStep, min ?? -Infinity));
    };

    if (disabled) {
      return (
        <Container>
          <Value
            type="b2"
            color="bodyDisabled"
            style={{
              cursor: 'not-allowed',
              pointerEvents: 'none',
              color: theme.text.bodyDisabled,
            }}
          >
            {value.toFixed(0)}
            <span style={unitStyle}>{unit}</span>
          </Value>
        </Container>
      );
    }

    return (
      <Container ref={ref} className={className} style={style}>
        <Value
          type="b2"
          color="body"
          onClick={() => setIsEditing(true)}
          style={valueStyle}
          $hoverColor={hoverColor}
        >
          {enableGestureSlider && <SliderContainer {...sliderDragBind()} />}
          {isEditing && (
            <ValueInput
              ref={inputRef}
              value={tempInputValue !== null ? tempInputValue : value}
              onKeyDown={(e) => {
                e.stopPropagation();

                if (e.key === 'Enter') {
                  setIsEditing(false);

                  const boundValue = boundNumber(
                    min ?? -Infinity,
                    tempInputValue || 0,
                    max ?? Infinity
                  );
                  setValue(Math.round(boundValue));
                  setTempInputValue(null);
                }
              }}
              onChange={(e) => setTempInputValue(+e.target.value)}
              onBlur={() => {
                setIsEditing(false);

                const boundValue = boundNumber(
                  min ?? -Infinity,
                  tempInputValue || 0,
                  max ?? Infinity
                );
                setValue(Math.round(boundValue));
                setTempInputValue(null);
              }}
              onFocus={() => setTempInputValue(value)}
              type="number"
              min={min}
              max={max}
              autoFocus
            />
          )}
          <span style={{ opacity: isEditing ? 0 : 1 }}>
            {(Math.round(value / step) * step).toFixed(0)}
            <span style={unitStyle}>{unit}</span>
          </span>
        </Value>
        {dragArrows && (
          <CarretsContainer {...arrowDragBind()}>
            <CarretUp onClick={handleIncrease} />
            <CarretDown onClick={handleDecrease} />
          </CarretsContainer>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CarretsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CarretDown = styled(CarretDownIcon)`
  cursor: ns-resize;
`;
const CarretUp = styled(CarretDown)`
  transform: rotate(180deg);
`;

const Value = styled(Text)<{ $hoverColor?: string }>`
  position: relative;
  min-width: 30px;
  text-align: right;
  cursor: text;
  user-select: none;
  touch-action: none;
  border: 1px solid transparent;
  line-height: normal;
  padding: 5px 7px;

  transition: border 0.1s ease-in-out;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  :hover {
    border: 1px solid
      ${({ theme, $hoverColor }) => $hoverColor || theme.text.bodyDisabled};
  }
`;

const ValueInput = styled.input`
  position: absolute;
  // offset to account for border
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;

  text-align: right;
  background: none;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text.body};
  z-index: 2;

  border: 1px solid ${({ theme }) => theme.border.action};
  border-radius: ${({ theme }) => theme.borderRadius.m};

  padding: 6px 10px;

  appearance: none;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  cursor: ew-resize;
  touch-action: none;
`;
