import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '../gql';

export const ChangelogDataFragment = graphql(/* GraphQL */ `
  fragment ChangelogData on Changelog {
    id
    title
    content
    publishedAt
  }
`);

export type ChangelogData = ResultOf<typeof ChangelogDataFragment>;
