import { OperationResult, OperationResultSource } from 'urql';
import { JobResult, JobType } from '@vizcom/job-scheduler/jobTypes';
import {
  ArrayBufferToBase64Object,
  assertUnreachable,
} from '@vizcom/shared/js-utils';

import { graphql } from '../../gql';
import { EphemeralJobMailboxSubscription } from '../../gql/graphql';
import { urqlClient } from '../../lib/graphql';
import { ephemeralJobMailboxId } from './ephemeralJobMailboxId';

export const ephemeralJobMailboxSubscription = graphql(/* GraphQL */ `
  subscription ephemeralJobMailbox($input: EphemeralJobMailboxInput!) {
    ephemeralJobMailbox(input: $input) {
      __typename
      ... on EphemeralJobMailboxResult {
        jobId
        data
      }
      ... on EphemeralJobMailboxError {
        jobId
        error
      }
    }
  }
`);

let subscription: OperationResultSource<
  OperationResult<EphemeralJobMailboxSubscription>
> | null = null;

export const waitForEphemeralJobResult = <T extends JobType>(
  jobId: string,
  timeout?: number
) => {
  return new Promise<
    ArrayBufferToBase64Object<Extract<JobResult, { type: T }>>
  >((resolve, reject) => {
    if (!subscription) {
      subscription = urqlClient.subscription(ephemeralJobMailboxSubscription, {
        input: {
          mailboxId: ephemeralJobMailboxId,
        },
      });
    }

    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (timeout) {
      timeoutId = setTimeout(() => {
        unsubscribe();
        reject(new Error('Timeout'));
      }, timeout);
    }

    const { unsubscribe } = subscription.subscribe((value) => {
      if (value.data?.ephemeralJobMailbox.jobId === jobId) {
        unsubscribe();
        clearTimeout(timeoutId);
        if (
          value.data?.ephemeralJobMailbox.__typename ===
          'EphemeralJobMailboxError'
        ) {
          reject(new Error(value.data?.ephemeralJobMailbox.error));
        } else if (
          value.data?.ephemeralJobMailbox.__typename ===
          'EphemeralJobMailboxResult'
        ) {
          resolve(value.data?.ephemeralJobMailbox.data);
        } else {
          assertUnreachable(value.data.ephemeralJobMailbox);
        }
      }
    });
  });
};
