import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  DeleteLayerMutation,
  MutationCreateLayerArgs,
  CreateLayerMutation,
} from '../gql/graphql';
import { drawingById } from '../queries/drawings';

type MutationCacheEffectors = {
  [key: string]: UpdateResolver;
};

export const layerMutationCacheEffectors: MutationCacheEffectors = {
  createLayer(res: CreateLayerMutation, args, cache, _info) {
    const drawingId = (args as MutationCreateLayerArgs)?.input?.layer
      ?.drawingId;

    const newLayer = res.createLayer?.layer;
    if (!newLayer) {
      // error in the createLayer mutation, nothing to do
      return;
    }

    cache.updateQuery(
      {
        query: drawingById,
        variables: {
          id: drawingId,
        },
      },
      (data) => {
        if (!data?.drawing) {
          return data;
        }

        if (!data.drawing.layers.nodes.find((l) => l.id === newLayer.id)) {
          data.drawing.layers.nodes.push(newLayer);
        }

        return data;
      }
    );
  },
  deleteLayer(res: DeleteLayerMutation, args, cache, _info) {
    const drawingId = res.deleteLayer?.drawing?.id;
    if (!drawingId) {
      return;
    }

    cache
      .inspectFields('Query')
      .filter((field) => field.fieldName === 'drawing')
      .forEach(() => {
        cache.updateQuery(
          {
            query: drawingById,
            variables: {
              id: drawingId,
            },
          },
          (data) => {
            if (!data?.drawing) {
              return data;
            }

            data.drawing.layers.nodes = data.drawing.layers.nodes.filter(
              (l) => l.id !== res.deleteLayer?.layer?.id
            );

            return data;
          }
        );
      });
  },
};
