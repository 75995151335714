import { useMutation } from 'urql';

import { graphql } from '../gql';

const CancelPrompt = graphql(/* GraphQL */ `
  mutation CancelPrompt($input: CancelPromptInput!) {
    cancelPrompt(input: $input) {
      prompt {
        ...PromptData
      }
    }
  }
`);

export const useCancelPrompt = () => useMutation(CancelPrompt);
