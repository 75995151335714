import styled, { css } from 'styled-components';

import { FloatingPanel } from '../FloatingPanel/FloatingPanel';

const toolbarTypes = {
  default: '',
  'top-left': css`
    top: 1rem;
    left: 14px;
  `,
  'top-center': css`
    top: 1rem;
    left: max(50%, 480px);
    translate: -50% 0;
  `,
  'top-right': css`
    top: 1rem;
    right: 14px;
  `,
  'bottom-left': css`
    bottom: 1rem;
    left: 14px;
  `,
  'bottom-right': css`
    bottom: 1rem;
    right: 14px;
  `,
  // bottom-centered toolbars should not be positioned absolutely
  // to avoid overlapping with app toasts, and should be placed in
  // <BottomScreenTunnel /> component
  'bottom-center': css`
    position: relative;
    bottom: -1rem;
    align-self: center;
  `,
  'centered-above': css`
    transform: translate(-50%, calc(-42px - 100%));
  `,
  'centered-below': css`
    transform: translate(-50%, 42px);
  `,
};

export const Toolbar = styled(FloatingPanel)<{
  position?: keyof typeof toolbarTypes;
}>`
  position: absolute;
  z-index: 10000000;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  height: 48px;
  padding: 8px;

  pointer-events: all;

  ${({ position }) => toolbarTypes[position || 'default']}
`;
