import { ReactNode } from 'react';

import { Button } from '../Button/Button';
import { triggerDirectModal } from './DirectModal';

type ConfirmModalOptions =
  | {
      body: (
        onConfirm: (value: unknown) => void,
        onCancel: (reason?: any) => void
      ) => ReactNode | ReactNode[];
    }
  | {
      title: string;
      content?: ReactNode | ReactNode[];
      confirmAction: ReactNode | ReactNode[];
      cancelAction?: ReactNode | ReactNode[];
    };

export const triggerConfirmModal = (options: ConfirmModalOptions) => {
  return new Promise((resolve, reject) => {
    const bodyMode = 'body' in options;

    if (bodyMode && options.body) {
      return triggerDirectModal({
        body: options.body(resolve, reject),
      });
    }

    if (!bodyMode) {
      triggerDirectModal({
        title: options.title,
        content: options.content,
        action: (
          <>
            {options.confirmAction ? (
              <div
                style={{ display: 'flex' }}
                onClick={resolve}
                data-close-modal
              >
                {options.confirmAction}
              </div>
            ) : (
              <Button
                type="button"
                variant="primary"
                onClick={resolve}
                data-autofocus="true"
              >
                Confirm
              </Button>
            )}
            {options.cancelAction ? (
              <div
                onClick={() => reject(new Error('Canceled'))}
                data-close-modal
              >
                {options.cancelAction}
              </div>
            ) : (
              <Button
                type="button"
                variant="secondary"
                onClick={reject}
                data-close-modal
              >
                Cancel
              </Button>
            )}
          </>
        ),
      });
    }
  });
};
