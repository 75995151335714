import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

const FullPageContainer = styled.div`
  // Dot background pattern
  background: radial-gradient(
    ${(p) => p.theme.surface.pagePattern} 2px,
    transparent 1px
  );
  background-size: 35px 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  max-width: 430px;
  padding: 30px 40px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background-color: ${(p) => p.theme.surface.primary};
  text-wrap: pretty;
  text-align: center;
`;

const FILE_LOGO_SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="48"
    viewBox="0 0 30 38"
    fill="none"
  >
    <path
      d="M4.68649 0C2.10941 0 0 2.11121 0 4.68829V32.8144C0 35.3915 2.10946 37.5027 4.68649 37.5027H25.3135C27.8906 37.5027 30 35.3915 30 32.8144V10.3135C30 8.86499 29.4252 7.47453 28.4003 6.4497L23.5523 1.60166C22.5275 0.576833 21.1369 0.000225315 19.6885 0.000225315L4.68649 0ZM20.6252 2.00398C21.2246 2.1657 21.7784 2.48036 22.2249 2.92687L27.0747 7.77492C27.5213 8.22317 27.8342 8.77693 27.9976 9.37635H23.4376C21.8959 9.37635 20.625 8.10541 20.625 6.56374L20.6252 2.00398ZM4.68649 3.7513H12.1874C12.4352 3.7513 12.6743 3.84975 12.8501 4.02554C13.026 4.20133 13.1244 4.4404 13.1244 4.68828C13.1244 4.93616 13.0259 5.1752 12.8501 5.35102C12.6743 5.52684 12.4353 5.62525 12.1874 5.62525H4.68649C4.16969 5.62525 3.74955 5.20687 3.74955 4.68831C3.74955 4.16975 4.16968 3.7513 4.68649 3.7513ZM4.68649 7.50085H12.1874C12.4352 7.50085 12.6743 7.5993 12.8501 7.77509C13.026 7.95088 13.1244 8.18995 13.1244 8.43783C13.1244 8.68745 13.0259 8.92475 12.8501 9.10057C12.6743 9.27635 12.4353 9.37656 12.1874 9.37656H4.68649C4.16969 9.37656 3.74955 8.95642 3.74955 8.43786C3.74955 7.92106 4.16968 7.50085 4.68649 7.50085ZM15 13.1261C15.2479 13.1261 15.4869 13.2245 15.6627 13.4003C15.8386 13.5761 15.937 13.8152 15.937 14.0631V23.4396C15.937 23.6875 15.8385 23.9265 15.6627 24.1024C15.4869 24.2782 15.2479 24.3766 15 24.3766C14.4814 24.3766 14.0631 23.9565 14.0631 23.4397V14.0631C14.0631 13.5463 14.4814 13.1261 15 13.1261ZM15 26.2522C16.0248 26.2522 16.8757 27.103 16.8757 28.1261C16.8757 29.1509 16.0249 30.0018 15 30.0018C13.9751 30.0018 13.1243 29.151 13.1243 28.1261C13.1243 27.1031 13.9751 26.2522 15 26.2522Z"
      fill="currentColor"
    />
  </svg>
);

interface NotFoundPageProps {
  targetType: string;
}

export const NotFoundPage = (props: NotFoundPageProps) => {
  const theme = useTheme();
  return (
    <FullPageContainer>
      <ModalContainer>
        <div style={{ color: theme.icon.action }}>
          <FILE_LOGO_SVG />
        </div>
        <Text block type="sh2">
          We couldn't find the {props.targetType} you were looking for
        </Text>
        <Text block color="subtext">
          The {props.targetType} may no longer exist or you may not have access.
          Make sure you are logged into the correct account and using the
          correct link.
        </Text>
        <Button variant="primary" as={Link} to={'/'}>
          Go back home
        </Button>
      </ModalContainer>
    </FullPageContainer>
  );
};
