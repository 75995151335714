import { assertExists } from '@vizcom/shared/js-utils';

import { graphql } from '../../gql';
import { urqlClient } from '../../lib/graphql';
import { waitForEphemeralJobResult } from '../../subscriptions';
import { ephemeralJobMailboxId } from '../../subscriptions/ephemeralJobs/ephemeralJobMailboxId';

export const RemoveBackgroundMutation = graphql(/* GraphQL */ `
  mutation RemoveBackground($input: RemoveBackgroundInput!) {
    removeBackground(input: $input) {
      jobId
    }
  }
`);

export const removeBackground = async (
  drawingId: string,
  image: Blob | string
) => {
  const res = await urqlClient.mutation(RemoveBackgroundMutation, {
    input: {
      id: drawingId,
      image,
      mailboxId: ephemeralJobMailboxId,
    },
  });

  assertExists(res.data?.removeBackground.jobId);

  return waitForEphemeralJobResult<'backgroundRemover'>(
    res.data.removeBackground.jobId,
    30000
  );
};
