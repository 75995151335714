import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { CheckIcon } from '@vizcom/shared-ui-components';

const CheckboxContainer = styled.div<{ $checked: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: 1px solid
    ${({ theme, $disabled }) =>
      $disabled ? theme.surface.secondary : theme.surface.tertiary};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? 'transparent' : theme.surface.secondary};
  color: ${({ $disabled = false, theme }) =>
    $disabled ? theme.surface.tertiary : theme.text.body};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  font-size: 10px;
`;

export type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const Checkbox = ({
  children = <CheckIcon />,
  checked,
  disabled = false,
  onClick,
}: React.PropsWithChildren<CheckboxProps>) => {
  return (
    <CheckboxContainer
      $checked={checked}
      $disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {checked && children}
    </CheckboxContainer>
  );
};
