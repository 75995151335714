import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import { isString, remove } from 'lodash';

import {
  CreateWorkbenchMutation,
  DuplicateWorkbenchMutation,
  MutationDeleteWorkbenchesArgs,
  RecentlyViewedWorkbenchesQuery,
  UpdateWorkbenchMutation,
  UpdateWorkbenchesAndFoldersMutation,
  WorkbenchesByFolderIdQuery,
} from '../../gql/graphql';
import { workbenchesByFolderId } from '../../queries/folder';
import { recentlyViewedWorkbenches } from '../../queries/workbench';

type MutationCacheEffectors = {
  [key: string]: UpdateResolver;
};

type FieldArgs = {
  condition: { folderId: string };
  after: string | null;
  first: number;
};

const updateCachedWorkbenches = (
  cache: Cache,
  action: (data: WorkbenchesByFolderIdQuery, fieldArgs: FieldArgs) => void
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'workbenches')
    .forEach((field) => {
      const fieldArgs = field.arguments as FieldArgs;

      cache.updateQuery(
        {
          query: workbenchesByFolderId,
          variables: {
            id: fieldArgs.condition.folderId,
            cursor: fieldArgs.after,
            count: fieldArgs.first,
          },
        },
        (data) => {
          if (!data) {
            return data;
          }

          action(data, fieldArgs);

          return data;
        }
      );
    });
};

const updateCachedRecentlyViewedWorkbenches = (
  cache: Cache,
  action: (data: RecentlyViewedWorkbenchesQuery) => void
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'organization')
    .forEach((field) => {
      const orgID = field.arguments?.id;

      if (!isString(orgID)) return;

      cache.updateQuery(
        {
          query: recentlyViewedWorkbenches,
          variables: {
            organizationId: orgID,
          },
        },
        (data) => {
          if (!data) return data;

          action(data);

          return data;
        }
      );
    });
};

export const workbenchMutationCacheEffectors: MutationCacheEffectors = {
  deleteWorkbenches(res, args: MutationDeleteWorkbenchesArgs, cache) {
    const workbenchIds = args.input.ids;

    updateCachedRecentlyViewedWorkbenches(cache, (data) => {
      if (!data?.organization) return data;

      const nodes = data.organization.recentlyViewedWorkbenches.nodes;

      remove(nodes, ({ id }) => workbenchIds.includes(id));
    });

    updateCachedWorkbenches(cache, (data) => {
      if (!data?.workbenches) {
        return data;
      }

      remove(data.workbenches.nodes, ({ id }) => workbenchIds.includes(id));
    });
  },
  createWorkbench(res: CreateWorkbenchMutation, args, cache) {
    updateCachedWorkbenches(cache, (data, fieldArgs) => {
      if (!data?.workbenches) {
        return data;
      }

      if (
        fieldArgs.condition.folderId !==
          res.createWorkbench?.workbench?.folderId ||
        !!fieldArgs.after
      ) {
        return;
      }

      data.workbenches.nodes = [
        res.createWorkbench?.workbench,
        ...data.workbenches.nodes,
      ];
    });
  },
  duplicateWorkbench(res: DuplicateWorkbenchMutation, args, cache) {
    updateCachedWorkbenches(cache, (data, fieldArgs) => {
      if (!data?.workbenches) {
        return data;
      }

      const folderId = fieldArgs.condition.folderId;

      if (
        folderId !== res.duplicateWorkbench?.workbench?.folderId ||
        !!fieldArgs.after
      ) {
        return;
      }

      data.workbenches.nodes = [
        res.duplicateWorkbench?.workbench,
        ...data.workbenches.nodes,
      ];
    });
  },
  updateWorkbench(res: UpdateWorkbenchMutation, args, cache) {
    updateCachedWorkbenches(cache, (data, fieldArgs) => {
      if (!data?.workbenches) {
        return data;
      }

      const folderId = fieldArgs.condition.folderId;
      const updatedWorkbench = res.updateWorkbench?.workbench;

      if (folderId === res.updateWorkbench?.workbench?.folderId) {
        if (
          data.workbenches.nodes.some((w) => w.id === updatedWorkbench?.id) ||
          !!fieldArgs.after
        ) {
          return;
        }

        data.workbenches.nodes = [
          res.updateWorkbench?.workbench,
          ...data.workbenches.nodes,
        ];
      } else {
        data.workbenches.nodes = data.workbenches.nodes.filter(
          (workbench) => workbench.id !== updatedWorkbench?.id
        );
      }
    });
  },
  updateWorkbenches(res: UpdateWorkbenchesAndFoldersMutation, args, cache) {
    updateCachedWorkbenches(cache, (data, fieldArgs) => {
      if (!data?.workbenches) {
        return data;
      }

      const { folderId } = fieldArgs.condition;
      res.updateWorkbenches?.updatedWorkbenches?.forEach((updatedWorkbench) => {
        if (folderId === updatedWorkbench.folderId) {
          if (
            data.workbenches?.nodes.some(
              (w) => w.id === updatedWorkbench?.id
            ) ||
            !!fieldArgs.after
          ) {
            return;
          }

          data.workbenches!.nodes = [
            updatedWorkbench,
            ...data.workbenches!.nodes,
          ];
        } else {
          data.workbenches!.nodes = data.workbenches!.nodes.filter(
            (workbench) => workbench.id !== updatedWorkbench?.id
          );
        }
      });
    });
  },
};
