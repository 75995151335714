import styled from 'styled-components';
import { Button, CloseIcon, Text } from '@vizcom/shared-ui-components';

export const FullscreenModal = ({
  title,
  description,
  isOpen,
  onClose,
  children,
}: React.PropsWithChildren<{
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
}>) => {
  return (
    <Modal isOpen={isOpen}>
      <Title type="h1">{title}</Title>
      <Description type="h3">{description}</Description>
      {children}
      <CloseButton variant="tertiary" size="icon" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </Modal>
  );
};

const Modal = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 88px;

  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;

  background: rgba(15, 15, 15, 0.95);
  backdrop-filter: blur(12px);

  color: ${({ theme }) => theme.text.body};
`;

const Title = styled(Text)`
  margin-bottom: 16px;

  background: linear-gradient(90deg, #fff 0.18%, #858585 114.85%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 54px;
  font-weight: 600;
  line-height: 1;
`;

const Description = styled(Text)`
  margin-bottom: 70px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 39px;
  right: 55px;
`;
