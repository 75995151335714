/* eslint-disable import/order */
import styled from 'styled-components';
import { defaultTheme } from '@vizcom/web/common/theme';
import { FunctionComponent, HTMLProps, SVGProps } from 'react';

import AccessOffSvg from './access_off.svg?react';
import AddAsLayerSvg from './add_as_layer.svg?react';
import AddFolderSvg from './add_folder.svg?react';
import AddToSelectionSvg from './add_to_selection.svg?react';
import AlignCenterSvg from './align_center.svg?react';
import AlignLeftSvg from './align_left.svg?react';
import AlignRightSvg from './align_right.svg?react';
import AmexSvg from './credit-cards/amex.svg?react';
import AnimateToolbarSvg from './animate_toolbar_icon.svg?react';
import ArchiveSvg from './archive.svg?react';
import ArrangeSvg from './arrange.svg?react';
import AutoSelectionSvg from './auto_selection.svg?react';
import BezierCurvesSvg from './bezier_curves.svg?react';
import BlendSvg from './blend.svg?react';
import BoldSvg from './bold.svg?react';
import BrushFillSvg from './brush_fill.svg?react';
import BrushSelectionSvg from './brush_selection.svg?react';
import RectangleSelectionSvg from './rectangle_selection.svg?react';
import BrushSvg from './brush.svg?react';
import CarrotSvg from './carrot.svg?react';
import FilledCaretSvg from './filled_caret.svg?react';
import WideCarretDownIconSvg from './wide_carret_down.svg?react';
import CheckLineSvg from './check-line.svg?react';
import CheckSvg from './check.svg?react';
import ChevronDownDoubleSvg from './chevron_down_double.svg?react';
import CircleSvg from './circle.svg?react';
import CloseSvg from './close.svg?react';
import CollapseSvg from './collapse.svg?react';
import ColorRingSvg from './color_ring.svg?react';
import ColorWheelSvg from './color_wheel.svg?react';
import ContentPasteSvg from './content_paste.svg?react';
import Convert2dTo3dSvg from './convert_2d_to_3d.svg?react';
import CopyContentSvg from './copy_content.svg?react';
import CopyPromptSvg from './copy_prompt.svg?react';
import CubeToolbarSvg from './3d_cube.svg?react';
import CustomSeatsSvg from './custom_seats.svg?react';
import DinersSvg from './credit-cards/diners.svg?react';
import DiscoverSvg from './credit-cards/discover.svg?react';
import DownloadSvg from './download.svg?react';
import DragHandleSvg from './drag_handle.svg?react';
import DuplicateSvg from './duplicate.svg?react';
import DuplicateToWorkbenchSvg from './duplicate_to_workbench.svg?react';
import EditSvg from './edit.svg?react';
import EmailSvg from './email.svg?react';
import EraserSvg from './eraser.svg?react';
import ErrorSvg from './error.svg?react';
import ExpandSvg from './expand.svg?react';
import ExportSvg from './export.svg?react';
import EyeDropperSvg from './eye_dropper.svg?react';
import FileSvg from './file.svg?react';
import FitToScreenSvg from './fit_to_screen.svg?react';
import FolderSvg from './folder.svg?react';
import FullscreenExitSvg from './fullscreen_exit.svg?react';
import FullscreenSvg from './fullscreen.svg?react';
import GeneralSelectSvg from './general_select.svg?react';
import GenericSvg from './credit-cards/generic.svg?react';
import HelpSvg from './help.svg?react';
import HideSvg from './hide.svg?react';
import HistorySvg from './history.svg?react';
import HomeSvg from './home.svg?react';
import ImageSvg from './image.svg?react';
import ImageMetadataSvg from './image_metadata.svg?react';
import Import3DModelSvg from './import_3d_model.svg?react';
import ImportImageSvg from './import_image.svg?react';
import InfoSvg from './info.svg?react';
import InvertSelectionSvg from './invert_selection.svg?react';
import JCBSvg from './credit-cards/jcb.svg?react';
import LassoSvg from './lasso.svg?react';
import Layer3dDropShadowSvg from './layer3d_dropshadow.svg?react';
import Layer3dLightSvg from './layer3d_light.svg?react';
import LineSvg from './line.svg?react';
import LinkSvg from './link.svg?react';
import LockSvg from './lock.svg?react';
import LoopSvg from './loop.svg?react';
import MaestroSvg from './credit-cards/maestro.svg?react';
import MagicEraserSvg from './magic_eraser.svg?react';
import MasterCardSvg from './credit-cards/mastercard.svg?react';
import MeatballSvg from './meatball.svg?react';
import MergeSvg from './merge.svg?react';
import MeshSphereSvg from './mesh_sphere.svg?react';
import MinusSvg from './minus.svg?react';
import MixToolbarSvg from './mix.svg?react';
import MobileUploadSvg from './mobile_upload.svg?react';
import Model3dSvg from './model_3d.svg?react';
import MoreToolsSvg from './more_tools.svg?react';
import MoveSvg from './move.svg?react';
import MoveToBottomSvg from './move_to_bottom.svg?react';
import MoveToTopSvg from './move_to_top.svg?react';
import NarrowCarrotSvg from './narrow_carrot.svg?react';
import OpacitySvg from './opacity.svg?react';
import OpenSvg from './open.svg?react';
import PaintBucketSvg from './paint_bucket.svg?react';
import PaletteSvg from './palette.svg?react';
import PaletteToolbarSvg from './palette_toolbar_icon.svg?react';
import PauseSvg from './pause.svg?react';
import PaypalSvg from './credit-cards/paypal.svg?react';
import PlaySvg from './play.svg?react';
import PlusSvg from './plus.svg?react';
import PromptToolbarSvg from './prompt.svg?react';
import PublishSvg from './publish.svg?react';
import RecentsSvg from './recents.svg?react';
import RectangleSvg from './rectangle.svg?react';
import RedoSvg from './redo.svg?react';
import RegenerateSvg from './regenerate.svg?react';
import RemoveSvg from './remove.svg?react';
import RenameSvg from './rename.svg?react';
import ScanImageSvg from './scan_image.svg?react';
import SearchSvg from './search.svg?react';
import SectionSvg from './section.svg?react';
import SelectSvg from './select.svg?react';
import SettingsSvg from './settings.svg?react';
import SketchToolbarSvg from './sketch.svg?react';
import SparklesSvg from './sparkles.svg?react';
import StrikethroughSvg from './strikethrough.svg?react';
import SubtractFromSelectionSvg from './subtract_from_selection.svg?react';
import SymmetrySvg from './symmetry.svg?react';
import Team from './team.svg?react';
import TextSvg from './text.svg?react';
import TextureSphereSvg from './texture_sphere.svg?react';
import ThumbsDownSvg from './thumbs_down.svg?react';
import ThumbsUpSvg from './thumbs_up.svg?react';
import TrashSvg from './trash.svg?react';
import UnderlineSvg from './underline.svg?react';
import UndoSvg from './undo.svg?react';
import UnhideSvg from './unhide.svg?react';
import UnionpaySvg from './credit-cards/unionpay.svg?react';
import UpgradeSvg from './upgrade';
import UserSvg from './user.svg?react';
import UserCircleSvg from './user_circle.svg?react';
import VideoPlaySvg from './video_play.svg?react';
import VideoPauseSvg from './video_pause.svg?react';
import VisaSvg from './credit-cards/visa.svg?react';
import VizcomLogoSvg from './vizcom_logo.svg?react';
import WarningSvg from './warning.svg?react';
import WarpSvg from './warp.svg?react';
import WorkbenchSvg from './workbench.svg?react';
import WorldSvg from './world.svg?react';
import ImageEditSvg from './image_edit.svg?react';
import IconViewSvg from './icon_view.svg?react';
import ListViewSvg from './list_view.svg?react';
import AssetLibrarySvg from './asset_library.svg?react';

const SIZES = {
  S: '12px',
  M: '16px',
  L: '24px',
  XL: '32px',
  XXL: '54px',
} as const;
export const svgIconFactory = (
  component: FunctionComponent<SVGProps<SVGSVGElement>>,
  rotate?: number
) => styled(component)<{
  $size?: keyof typeof SIZES;
  $color?: keyof typeof defaultTheme.icon;
}>`
  ${({ $size }) =>
    $size ? `width: ${SIZES[$size]}; height: ${SIZES[$size]};` : ''}
  ${({ $color, theme }) => ($color ? `color: ${theme.icon[$color]};` : '')}
  ${rotate ? `transform: rotate(${rotate}deg);` : ''}
`;

interface PNGIconProps extends Omit<HTMLProps<HTMLImageElement>, 'size'> {
  size?: keyof typeof SIZES;
}

export const pngIconFactory =
  (url: string) =>
  ({ size = 'M' }: PNGIconProps) =>
    <img src={url} style={{ width: SIZES[size], height: SIZES[size] }} />;

export const AccessOffIcon = svgIconFactory(AccessOffSvg);
export const AddAsLayerIcon = svgIconFactory(AddAsLayerSvg);
export const AddFolderIcon = svgIconFactory(AddFolderSvg);
export const AddToSelectionIcon = svgIconFactory(AddToSelectionSvg);
export const AlignCenterIcon = svgIconFactory(AlignCenterSvg);
export const AlignLeftIcon = svgIconFactory(AlignLeftSvg);
export const AlignRightIcon = svgIconFactory(AlignRightSvg);
export const AmexIcon = svgIconFactory(AmexSvg);
export const AnimateToolbarIcon = svgIconFactory(AnimateToolbarSvg);
export const ArchiveIcon = svgIconFactory(ArchiveSvg);
export const ArrangeIcon = svgIconFactory(ArrangeSvg);
export const AssetLibraryIcon = svgIconFactory(AssetLibrarySvg);
export const AutoSelectionIcon = svgIconFactory(AutoSelectionSvg);
export const BezierCurvesIcon = svgIconFactory(BezierCurvesSvg);
export const BlendIcon = svgIconFactory(BlendSvg);
export const BoldIcon = svgIconFactory(BoldSvg);
export const BrushFillIcon = svgIconFactory(BrushFillSvg);
export const BrushIcon = svgIconFactory(BrushSvg);
export const BrushSelectionIcon = svgIconFactory(BrushSelectionSvg);
export const RectangleSelectionIcon = svgIconFactory(RectangleSelectionSvg);
export const CarretUpIcon = svgIconFactory(CarrotSvg, 180);
export const CarretDownIcon = svgIconFactory(CarrotSvg);
export const CarretLeftIcon = svgIconFactory(CarrotSvg, 90);
export const CarretRightIcon = svgIconFactory(CarrotSvg, -90);
export const FilledCaretDownIcon = svgIconFactory(FilledCaretSvg);
export const WideCarretDownIcon = svgIconFactory(WideCarretDownIconSvg);
export const CheckIcon = svgIconFactory(CheckSvg);
export const CheckLineIcon = svgIconFactory(CheckLineSvg);
export const ChevronDownDoubleIcon = svgIconFactory(ChevronDownDoubleSvg);
export const CloseIcon = svgIconFactory(CloseSvg);
export const CollapseIcon = svgIconFactory(CollapseSvg);
export const ColorRingIcon = svgIconFactory(ColorRingSvg);
export const ColorWheelIcon = svgIconFactory(ColorWheelSvg);
export const ContentPasteIcon = svgIconFactory(ContentPasteSvg);
export const Convert2dTo3dIcon = svgIconFactory(Convert2dTo3dSvg);
export const CopyContentIcon = svgIconFactory(CopyContentSvg);
export const CopyPromptIcon = svgIconFactory(CopyPromptSvg);
export const CubeToolbarIcon = svgIconFactory(CubeToolbarSvg);
export const CustomSeatsIcon = svgIconFactory(CustomSeatsSvg);
export const DinersIcon = svgIconFactory(DinersSvg);
export const DiscoverIcon = svgIconFactory(DiscoverSvg);
export const DownloadIcon = svgIconFactory(DownloadSvg);
export const DragHandleIcon = svgIconFactory(DragHandleSvg);
export const DuplicateIcon = svgIconFactory(DuplicateSvg);
export const DuplicateToWorkbenchIcon = svgIconFactory(DuplicateToWorkbenchSvg);
export const EditIcon = svgIconFactory(EditSvg);
export const EllipseIcon = svgIconFactory(CircleSvg);
export const EraserIcon = svgIconFactory(EraserSvg);
export const ErrorIcon = svgIconFactory(ErrorSvg);
export const EmailIcon = svgIconFactory(EmailSvg);
export const ExpandIcon = svgIconFactory(ExpandSvg);
export const ExportIcon = svgIconFactory(ExportSvg);
export const EyeDropperIcon = svgIconFactory(EyeDropperSvg);
export const FileIcon = svgIconFactory(FileSvg);
export const FitToScreenIcon = svgIconFactory(FitToScreenSvg);
export const FolderIcon = svgIconFactory(FolderSvg);
export const FullscreenExitIcon = svgIconFactory(FullscreenExitSvg);
export const FullscreenIcon = svgIconFactory(FullscreenSvg);
export const GeneralSelectIcon = svgIconFactory(GeneralSelectSvg);
export const GenericIcon = svgIconFactory(GenericSvg);
export const HelpIcon = svgIconFactory(HelpSvg);
export const HideIcon = svgIconFactory(HideSvg);
export const HistoryIcon = svgIconFactory(HistorySvg);
export const HomeIcon = svgIconFactory(HomeSvg);
export const ImageIcon = svgIconFactory(ImageSvg);
export const ImageMetadataIcon = svgIconFactory(ImageMetadataSvg);
export const Import3DModelIcon = svgIconFactory(Import3DModelSvg);
export const ImportImageIcon = svgIconFactory(ImportImageSvg);
export const InfoIcon = svgIconFactory(InfoSvg);
export const InsertIcon = svgIconFactory(PlusSvg);
export const InvertSelectionIcon = svgIconFactory(InvertSelectionSvg);
export const JCBIcon = svgIconFactory(JCBSvg);
export const LassoIcon = svgIconFactory(LassoSvg);
export const Layer3dDropShadowIcon = svgIconFactory(Layer3dDropShadowSvg);
export const Layer3dLightIcon = svgIconFactory(Layer3dLightSvg);
export const LineIcon = svgIconFactory(LineSvg);
export const LinkIcon = svgIconFactory(LinkSvg);
export const LockIcon = svgIconFactory(LockSvg);
export const LoopIcon = svgIconFactory(LoopSvg);
export const MaestroIcon = svgIconFactory(MaestroSvg);
export const MagicEraserIcon = svgIconFactory(MagicEraserSvg);
export const MasterCardIcon = svgIconFactory(MasterCardSvg);
export const MeatballIcon = svgIconFactory(MeatballSvg);
export const MenuHorizontalIcon = svgIconFactory(MeatballSvg);
export const MergeIcon = svgIconFactory(MergeSvg);
export const MeshSphereIcon = svgIconFactory(MeshSphereSvg);
export const MinusIcon = svgIconFactory(MinusSvg);
export const MixToolbarIcon = svgIconFactory(MixToolbarSvg);
export const MobileUploadIcon = svgIconFactory(MobileUploadSvg);
export const Model3dIcon = svgIconFactory(Model3dSvg);
export const MoreToolsIcon = svgIconFactory(MoreToolsSvg);
export const MoveIcon = svgIconFactory(MoveSvg);
export const MoveToBottomIcon = svgIconFactory(MoveToBottomSvg);
export const MoveToTopIcon = svgIconFactory(MoveToTopSvg);
export const NarrowCarretIcon = svgIconFactory(NarrowCarrotSvg);
export const OpacityIcon = svgIconFactory(OpacitySvg);
export const OpenIcon = svgIconFactory(OpenSvg);
export const PaintBucketIcon = svgIconFactory(PaintBucketSvg);
export const PaletteIcon = svgIconFactory(PaletteSvg);
export const PaletteToolbarIcon = svgIconFactory(PaletteToolbarSvg);
export const PauseIcon = svgIconFactory(PauseSvg);
export const PaypalIcon = svgIconFactory(PaypalSvg);
export const PlayIcon = svgIconFactory(PlaySvg);
export const PlusIcon = svgIconFactory(PlusSvg);
export const PromptToolbarIcon = svgIconFactory(PromptToolbarSvg);
export const PublishIcon = svgIconFactory(PublishSvg);
export const RecentsIcon = svgIconFactory(RecentsSvg);
export const RectangleIcon = svgIconFactory(RectangleSvg);
export const RedoIcon = svgIconFactory(RedoSvg);
export const RegenerateIcon = svgIconFactory(RegenerateSvg);
export const RemoveIcon = svgIconFactory(RemoveSvg);
export const RenameIcon = svgIconFactory(RenameSvg);
export const ScanImageIcon = svgIconFactory(ScanImageSvg);
export const SearchIcon = svgIconFactory(SearchSvg);
export const SectionIcon = svgIconFactory(SectionSvg);
export const SelectIcon = svgIconFactory(SelectSvg);
export const SettingsIcon = svgIconFactory(SettingsSvg);
export const SketchToolbarIcon = svgIconFactory(SketchToolbarSvg);
export const SparklesIcon = svgIconFactory(SparklesSvg);
export const StrikethroughIcon = svgIconFactory(StrikethroughSvg);
export const SubtractFromSelectionIcon = svgIconFactory(
  SubtractFromSelectionSvg
);
export const SymmetryIcon = svgIconFactory(SymmetrySvg);
export const TeamIcon = svgIconFactory(Team);
export const TextIcon = svgIconFactory(TextSvg);
export const TextureSphereIcon = svgIconFactory(TextureSphereSvg);
export const ThumbsDownIcon = svgIconFactory(ThumbsDownSvg);
export const ThumbsUpIcon = svgIconFactory(ThumbsUpSvg);
export const TrashIcon = svgIconFactory(TrashSvg);
export const UnderlineIcon = svgIconFactory(UnderlineSvg);
export const UndoIcon = svgIconFactory(UndoSvg);
export const UnhideIcon = svgIconFactory(UnhideSvg);
export const UnionpayIcon = svgIconFactory(UnionpaySvg);
export const UpgradeIcon = svgIconFactory(UpgradeSvg);
export const UserIcon = svgIconFactory(UserSvg);
export const UserCircleIcon = svgIconFactory(UserCircleSvg);
export const VideoPlayIcon = svgIconFactory(VideoPlaySvg);
export const VideoPauseIcon = svgIconFactory(VideoPauseSvg);
export const VisaIcon = svgIconFactory(VisaSvg);
export const VizcomLogoIcon = svgIconFactory(VizcomLogoSvg);
export const WarningIcon = svgIconFactory(WarningSvg);
export const WarpIcon = svgIconFactory(WarpSvg);
export const WorkbenchIcon = svgIconFactory(WorkbenchSvg);
export const WorldIcon = svgIconFactory(WorldSvg);
export const ZoomInIcon = svgIconFactory(PlusSvg);
export const ZoomOutIcon = svgIconFactory(MinusSvg);
export const ImageEditIcon = svgIconFactory(ImageEditSvg);
export const IconViewIcon = svgIconFactory(IconViewSvg);
export const ListViewIcon = svgIconFactory(ListViewSvg);
// Old icons below, do not add new icons here
import LoaderSvg from './loader.svg?react';

/**
 * @deprecated
 */
export const ArrowIcon = svgIconFactory(CarrotSvg);
/**
 * @deprecated
 */
export const ArrowLeftIcon = svgIconFactory(CarrotSvg, 90);
/**
 * @deprecated
 */
export const ArrowRightIcon = svgIconFactory(CarrotSvg, -90);
/**
 * @deprecated
 */
export const DropdownIcon = svgIconFactory(CarrotSvg);
/**
 * @deprecated
 */
export const LoaderIcon = svgIconFactory(LoaderSvg);

// Raw icons below
import DropdownIconUrl from './dropdown.svg?url';
import Model3dIconUrl from './model_3d.svg?url';

import ThumbnailIconRaw from './thumbnail.svg?raw';
import PlayCircleIconRaw from './play_circle.svg?raw';
import CheckCircleIconRaw from './check-circle.svg?raw';
import CloseIconOrangeRaw from './close-orange.svg?raw';
import RetryIconRaw from './retry.svg?raw';
import PublishIconRaw from './publish.svg?raw';
import WideCarretDownIconRaw from './wide_carret_down.svg?raw';
import ErrorIconRaw from './error.svg?raw';
import CursorIconRaw from './cursor.svg?raw';

// Styled icons
export const CaretDownIconRotatable = styled(CarrotSvg)<{
  $angle?: number;
}>`
  transform: ${(props) =>
    props.$angle !== undefined ? `rotate(${props.$angle}deg)` : 'none'};
  transition: transform 0.2s;
`;

export {
  CheckCircleIconRaw,
  CloseIconOrangeRaw,
  CursorIconRaw,
  ErrorIconRaw,
  PlayCircleIconRaw,
  PublishIconRaw,
  RetryIconRaw,
  WideCarretDownIconRaw,
  Model3dIconUrl,
  DropdownIconUrl,
  ThumbnailIconRaw,
};
