import React from 'react';
import { useTheme } from 'styled-components';
import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  TooltipOptions,
} from '@vizcom/shared-ui-components';
import { styled } from '@vizcom/web/common/theme';

export enum ToolbarButtonState {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  SECONDARY_ACTIVE = 'secondary-active',
  BACKGROUND = 'background', // used mainly for the selection button when selected mask is moved
  INACTIVE = 'inactive',
}

export interface ToolbarButtonProps {
  icon: React.ReactNode;
  tooltip: React.ReactNode;
  tooltipOptions?: TooltipOptions;
  state?: ToolbarButtonState;
  onClick?: React.MouseEventHandler;
  buttonProps?: React.ComponentProps<typeof Button>;
}

export const ToolbarButton = React.forwardRef<
  HTMLElement,
  React.PropsWithChildren<ToolbarButtonProps>
>(
  (
    {
      children,
      icon,
      tooltip,
      tooltipOptions,
      state = ToolbarButtonState.INACTIVE,
      onClick,
      buttonProps,
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <RichTooltip trigger="hover" {...theme.tooltip} {...tooltipOptions}>
        <RichTooltipTrigger ref={ref}>
          <ButtonWithIcons
            onClick={onClick}
            variant="secondary"
            disabled={state === ToolbarButtonState.DISABLED}
            size="icon"
            $toolState={state}
            {...buttonProps}
          >
            {icon}

            {children}
          </ButtonWithIcons>
        </RichTooltipTrigger>
        <RichTooltipContent>{tooltip}</RichTooltipContent>
      </RichTooltip>
    );
  }
);

// TODO: use Clickable instead of Button
const ButtonWithIcons = styled(Button)<{
  $toolState: ToolbarButtonState;
}>`
  background-color: transparent;

  :disabled {
    background-color: transparent;
  }

  :focus {
    outline: none;
  }

  :not(:disabled) {
    ${({ $toolState, theme }) =>
      $toolState === ToolbarButtonState.ACTIVE
        ? `background-color: ${theme.deprecated.primary.default} !important;`
        : $toolState === ToolbarButtonState.SECONDARY_ACTIVE
        ? `
          background-color: ${theme.deprecated.primary.disabled} !important;
          border: 1px solid ${theme.deprecated.primary.default};
          margin: -1px; // compensate for border
          `
        : $toolState === ToolbarButtonState.BACKGROUND
        ? `background-color: ${theme.deprecated.primary.disabled} !important;`
        : ''}
  }
`;
