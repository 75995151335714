import { useQuery } from 'urql';

import { graphql } from '../gql';

const webConfig = graphql(/* GraphQL */ `
  query webConfig {
    webConfig {
      loginWithEmail
      loginWithGoogle
      loginWithAzure
      whiteLabelName
      whiteLabelIconUrl
    }
  }
`);

export const useWebConfig = () => {
  const [res] = useQuery({
    query: webConfig,
  });

  return {
    ...res,
    data: res.data?.webConfig,
  };
};
