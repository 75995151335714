// sourced from https://codesandbox.io/p/sandbox/admiring-lamport-5wt3yg?file=%2Fsrc%2FDropdownMenu.tsx%3A156%2C32

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  UseDismissProps,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import {
  createContext,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { assertExists } from '@vizcom/shared/js-utils';

import { FloatingPanel } from '../FloatingPanel/FloatingPanel';
import { CarretRightIcon } from '../icons';

const ARROW_SIZE = 10;

const RichTooltipArrow = styled(FloatingArrow)`
  fill: ${(p) => p.theme.surface.primary};
`;

interface MenuContextValue {
  getItemProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  isOpen: boolean;
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((open: boolean) => void);
  parentContext: MenuContextValue | null;
}

export const MenuContext = createContext<MenuContextValue | null>(null);

interface MenuProps {
  label?: React.ReactNode;
  prependLabel?: React.ReactNode;
  appendLabel?: React.ReactNode;
  disabled?: boolean;

  // if renderLabel is provided, label/appendLabel/disabled are ignored
  renderLabel?: (
    state: { isOpen: boolean },
    interactionProps: Record<string, unknown>
  ) => React.ReactNode;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  closeOnClick?: boolean;
  closeParentOnClick?: boolean;
  placement?: Placement;
  open?: boolean;
  setIsOpen?:
    | React.Dispatch<SetStateAction<boolean>>
    | ((open: boolean) => void);
  offset?: number;
  dismissProps?: UseDismissProps;
  disableFocusManager?: boolean;
  hideArrow?: boolean;
  floatingStyleOverrides?: React.CSSProperties;
}

export const MenuComponent = ({
  children,
  label,
  prependLabel,
  appendLabel,
  disabled,
  renderLabel,
  placement,
  open: controlledOpen,
  setIsOpen: controlledSetIsOpen,
  offset: offsetValue = 20,
  dismissProps = {},
  disableFocusManager,
  hideArrow,
  floatingStyleOverrides,
}: MenuProps) => {
  const [localIsOpen, localSetIsOpen] = useState(false);
  const isOpen = controlledOpen ?? localIsOpen;
  const setIsOpen = controlledSetIsOpen ?? localSetIsOpen;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const arrowRef = useRef(null);
  const parentContext = useContext(MenuContext);
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null;

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement || (isNested ? 'right-start' : 'bottom-start'),
    middleware: [
      offset(offsetValue),
      flip(),
      shift(),
      arrow({
        element: isNested ? null : arrowRef,
        padding: { top: 30 },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({
      blockPointerEvents: true,
      requireIntent: false,
    }),
  });
  const click = useClick(context, {
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, {
    bubbles: true,
    ...dismissProps,
  });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNavigation]
  );

  const triggerButtonProps = {
    ref: useMergeRefs([refs.setReference, item.ref]),
    tabIndex: !parentContext
      ? undefined
      : parentContext.activeIndex === item.index
      ? 0
      : -1,
    ...getReferenceProps(parentContext?.getItemProps()),
  };

  const triggerButton = renderLabel ? (
    renderLabel({ isOpen }, triggerButtonProps)
  ) : (
    <MenuItemButton
      type="button"
      $open={isOpen}
      disabled={disabled}
      {...triggerButtonProps}
    >
      {prependLabel}
      <span style={{ flex: 1 }}>{label}</span>
      {appendLabel}
      {isNested && <CarretRightIcon />}
    </MenuItemButton>
  );

  return (
    <FloatingNode id={nodeId}>
      {triggerButton}
      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          isOpen,
          setIsOpen,
          parentContext,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={isNested ? -1 : 0}
                returnFocus={!isNested}
                disabled={disableFocusManager}
              >
                <FloatingMenuContainer
                  ref={refs.setFloating}
                  style={{
                    ...floatingStyles,
                    ...floatingStyleOverrides,
                  }}
                  {...getFloatingProps()}
                >
                  {children}
                  {!hideArrow && (
                    <RichTooltipArrow
                      ref={arrowRef}
                      context={context}
                      width={ARROW_SIZE * 1.5}
                      height={ARROW_SIZE}
                    />
                  )}
                </FloatingMenuContainer>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
};

const enterKeyframes = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-5px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const FloatingMenuContainer = styled(FloatingPanel)`
  z-index: 1000000;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 180px;
  padding: 8px;
  gap: 4px;
  outline: none;

  animation: ${enterKeyframes} 0.1s ease;
  animation-composition: add;
`;

const MenuItemButton = styled.button<{
  $open: boolean;
}>`
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  background-color: ${({ $open, theme }) =>
    $open ? theme.surface.tertiary : 'transparent'};
  transition: background-color 0.1s ease;
  color: ${({ theme }) => theme.text.body};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  text-align: left;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.surface.tertiary};
  }

  &:disabled {
    color: ${({ theme }) => theme.text.bodyDisabled};
    cursor: auto;
  }
`;

export const MenuItem = ({
  closeOnClick = true,
  closeParentOnClick = true,
  ...props
}: MenuProps) => {
  const menu = useContext(MenuContext);
  const item = useListItem();

  assertExists(menu, 'Menu context is missing');
  const isActive = item.index === menu.activeIndex;

  if (props.children) {
    return <MenuComponent {...props} />;
  }

  const closeMenus = () => {
    let targetMenuToClose: MenuContextValue | null = menu;

    while (targetMenuToClose) {
      targetMenuToClose.setIsOpen(false);
      targetMenuToClose = closeParentOnClick
        ? targetMenuToClose.parentContext
        : null;
    }
  };

  return (
    <MenuItemButton
      $open={false}
      {...props}
      ref={item.ref}
      type="button"
      role="menuitem"
      tabIndex={isActive ? 0 : -1}
      disabled={props.disabled}
      {...menu.getItemProps({
        onClick(event: React.MouseEvent<HTMLButtonElement>) {
          if (closeOnClick) {
            closeMenus();
          }
          props.onClick?.(event);
        },
      })}
    >
      {props.prependLabel}
      <span style={{ flex: 1 }}>{props.label}</span>
      {props.appendLabel}
    </MenuItemButton>
  );
};

export const Menu = (props: MenuProps) => {
  return (
    <FloatingTree>
      <MenuComponent {...props} />
    </FloatingTree>
  );
};

export const MenuDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.surface.tertiary};
`;
