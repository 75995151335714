import { useMutation } from 'urql';

import { graphql } from '../gql';

const CreateUserProfile = graphql(/* GraphQL */ `
  mutation CreateUserProfile($input: CreateUserProfileInput!) {
    createUserProfile(input: $input) {
      user {
        id
        isProfileCompleted
      }
    }
  }
`);

export const useCreateUserProfile = () => {
  return useMutation(CreateUserProfile);
};
