import styled, { useTheme } from 'styled-components';

type ProgressBarContainerProps = {
  value: number;
  max: number;
  reverse?: boolean;
};

export const ProgressBar = ({
  value,
  max,
  reverse = false,
}: ProgressBarContainerProps) => {
  const theme = useTheme();

  const percentage = (value / max) * 100;

  const firstTierColor = !reverse
    ? theme.surface.danger
    : theme.deprecated.primary.default;
  const secondTierColor = theme.surface.warning;
  const thirdTierColor = !reverse
    ? theme.deprecated.primary.default
    : theme.surface.danger;

  const color =
    percentage <= 34
      ? firstTierColor
      : percentage <= 67
      ? secondTierColor
      : thirdTierColor;

  return <ProgressBarContainer color={color} value={value} max={max} />;
};

const ProgressBarContainer = styled.progress`
  width: 100%;
  height: 8px;
  background-color: transparent;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-webkit-progress-bar {
    background-color: ${(p) => p.theme.surface.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius.m};
  }

  &::-webkit-progress-value {
    border-radius: ${({ theme }) => theme.borderRadius.m};
    background-color: ${(p) => p.color};
  }

  &::-moz-progress-bar {
    border-radius: ${({ theme }) => theme.borderRadius.m};
    background-color: ${(p) => p.color};
  }

  &::-ms-fill {
    border-radius: ${({ theme }) => theme.borderRadius.m};
    background-color: ${(p) => p.color};
  }
`;
