import { TrackingEvent } from '@vizcom/shared/data-shape';

import { graphql } from '../gql';
import { urqlClient } from '../lib/graphql';

export const PublishTrackingEventMutation = graphql(/* GraphQL */ `
  mutation PublishTrackingEvent($input: PublishTrackingEventInput!) {
    publishTrackingEvent(input: $input) {
      success
    }
  }
`);

export const publishTrackingEvent = async (payload: TrackingEvent) => {
  await urqlClient.mutation(PublishTrackingEventMutation, {
    input: payload,
  });
};
