import styled from 'styled-components';
import {
  BlockStack,
  InlineFlex,
  Text,
  UpgradeIcon,
} from '@vizcom/shared-ui-components';

export const BenefitItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <InlineFlex $gap={12} $alignItems="flex-start">
      <StyledUpgradeIcon $size="L" />
      <BlockStack $gap={12}>
        <Text type="h3">{title}</Text>
        <Text type="b1" color="subtext" style={{ fontSize: '16px' }}>
          {description}
        </Text>
      </BlockStack>
    </InlineFlex>
  );
};

const StyledUpgradeIcon = styled(UpgradeIcon)`
  height: 27px;
  margin-top: 3px;
`;
