import { useMutation } from 'urql';

import { graphql } from '../gql';

const SetUserFeatureFlag = graphql(/* GraphQL */ `
  mutation setUserFeatureFlag($input: SetUserFeatureFlagInput!) {
    setUserFeatureFlag(input: $input) {
      user {
        id
        featureFlags
      }
    }
  }
`);

export const useSetUserFeatureFlag = () => useMutation(SetUserFeatureFlag);

export const SetUsersFeatureFlag = graphql(/* GraphQL */ `
  mutation setUsersFeatureFlag($input: SetUsersFeatureFlagInput!) {
    setUsersFeatureFlag(input: $input) {
      boolean
    }
  }
`);

export const useSetUsersFeatureFlag = () => useMutation(SetUsersFeatureFlag);
