import { useMutation } from 'urql';

import { graphql } from '../gql';

export const DeleteStyleReferenceMutation = graphql(/* GraphQL */ `
  mutation DeleteStyleReference($id: UUID!) {
    deleteStyleReference(input: { id: $id }) {
      workbench {
        ...WorkbenchStyleReferences
      }
    }
  }
`);

export const useDeleteStyleReference = () =>
  useMutation(DeleteStyleReferenceMutation);
