import { useQuery } from 'urql';
import { PUBLIC_RESOURCES_TEAM_ID } from '@vizcom/shared/data-shape';

import { graphql } from '../gql';

const meshesForDrawingId = graphql(/* GraphQL */ `
  query meshesForDrawing($drawingId: UUID!) {
    drawing(id: $drawingId) {
      id
      workbench {
        id
        folder {
          id
          rootFolder {
            id
            meshes(orderBy: CREATED_AT_DESC) {
              nodes {
                ...MeshData
              }
            }
          }
        }
      }
    }
  }
`);

export const useMeshForDrawingId = (drawingId: string | undefined) => {
  const [res] = useQuery({
    query: meshesForDrawingId,
    variables: {
      drawingId: drawingId!,
    },
    pause: !drawingId,
  });

  return {
    ...res,
    data: res.data?.drawing?.workbench?.folder?.rootFolder?.meshes?.nodes || [],
  };
};

const meshesForTeamId = graphql(/* GraphQL */ `
  query meshesForTeamId($teamId: UUID!) {
    team(id: $teamId) {
      id
      rootFolder {
        id
        meshes(orderBy: CREATED_AT_DESC) {
          nodes {
            ...MeshData
          }
        }
      }
    }
  }
`);

export const usePublicResourcesMesh = () => {
  const [res] = useQuery({
    query: meshesForTeamId,
    variables: {
      teamId: PUBLIC_RESOURCES_TEAM_ID,
    },
  });

  return {
    ...res,
    data: res.data?.team?.rootFolder?.meshes?.nodes || [],
  };
};

export const meshByIdQuery = graphql(/* GraphQL */ `
  query mesh($id: UUID!) {
    mesh(id: $id) {
      ...MeshData
    }
  }
`);

export const useMesh = (id: string | undefined) => {
  const [res] = useQuery({
    query: meshByIdQuery,
    variables: {
      id: id!,
    },
    pause: !id,
  });

  return {
    ...res,
    data: res.data?.mesh,
  };
};

export const meshesByIdsQuery = graphql(/* GraphQL */ `
  query meshesByIds($ids: [UUID!]!) {
    meshes(filter: { id: { in: $ids } }) {
      nodes {
        ...MeshData
      }
    }
  }
`);
