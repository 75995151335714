import styled from 'styled-components';

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;

  accent-color: ${({ theme }) => theme.button.primary};
  :hover {
    accent-color: ${({ theme }) => theme.button.primaryHover};
  }
`;
