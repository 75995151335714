import { graphql } from '../../gql';

export const CreateWorkbenchElementImg2ImgMutation = graphql(/* GraphQL */ `
  mutation CreateWorkbenchElementImg2Img(
    $input: CreateWorkbenchElementImg2ImgInput!
  ) {
    createWorkbenchElementImg2Img(input: $input) {
      workbenchElementImg2Img {
        ...WorkbenchElementImg2ImgData
      }
    }
  }
`);

export const UpdateWorkbenchElementImg2ImgMutation = graphql(/* GraphQL */ `
  mutation UpdateWorkbenchElementImg2Img(
    $input: UpdateWorkbenchElementImg2ImgInput!
  ) {
    updateWorkbenchElementImg2Img(input: $input) {
      workbenchElementImg2Img {
        ...WorkbenchElementImg2ImgData
      }
    }
  }
`);

export const DeleteWorkbenchElementImg2ImgMutation = graphql(/* GraphQL */ `
  mutation DeleteWorkbenchElementImg2Img(
    $input: DeleteWorkbenchElementImg2ImgInput!
  ) {
    deleteWorkbenchElementImg2Img(input: $input) {
      deletedWorkbenchElementImg2ImgNodeId
    }
  }
`);

export const TriggerWorkbenchElementImg2ImgMutation = graphql(/* GraphQL */ `
  mutation TriggerWorkbenchElementImg2Img(
    $input: TriggerWorkbenchElementImg2ImgInput!
  ) {
    triggerWorkbenchElementImg2Img(input: $input) {
      placeholders {
        ...WorkbenchElementPlaceholderData
      }
    }
  }
`);
